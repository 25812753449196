import * as React from 'react';
import { PrimaryButton } from 'office-ui-fabric-react';
import { BulkJobsExplorer } from './explorer';
import { Log } from "@voxfp/opal_ui_common";
// import Can from "@voxfp/opal_ui_common/dist/components/permissions/can";

export interface AppProps {
    history: any;
    title: any;
    location: any;
    match?: any;
}

export interface AppState {

}

export class BulkJobs extends React.Component<AppProps, AppState> {

    handleCreateNewClick() {
        this.props.history.push("/bulk-job/create");
    }
    
    render() {
        Log.debug('Rendering Bulk Jobs main', this.state);
        return (
            <div>
                <div className="ms-Grid-row mb05">
                    <div className="ms-Grid-col ms-sm6">
                        <h2 className="mt0 mb05">{this.props.title}</h2>
                    </div>
                    <div className="ms-Grid-col ms-sm6 right">
                        {/* <Can do="create" on="document"> */}
                            <PrimaryButton text="New Bulk Job" onClick={this.handleCreateNewClick.bind(this)} />
                        {/* </Can> */}
                    </div>
                </div>

                <BulkJobsExplorer history={this.props.history} match={this.props.location} />

            </div>
        );
    }
}

export default BulkJobs;
