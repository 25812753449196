import * as React from 'react';
import {TemplateFamilyService} from '../../services/templateFamilyService';
import {IDropdownOption, SearchBox, MessageBar, Dropdown, PrimaryButton, Label, DefaultButton, MessageBarType, IContextualMenuItem} from 'office-ui-fabric-react';
import OpalStateManager, {OpalEntity} from "../../state/stateManager";
import {Log, Util, Toast, OpalTable} from "@voxfp/opal_ui_common";
import {Utils} from "../../services/utils";
import {TemplateTokens} from '../tokens/TemplateTokens';
import {DownloadService} from '../../services/downloadService';

export interface AppProps {
    path: string;
    mode: string;
    history: any;
    permissions: any;
}

export interface AppState {
    listItems: Array<any>;
    columns: Array<any>;
    isDataLoaded: boolean;
    displayNoItems: boolean;
    name_contains: any;
    template_id: any;
    template_family_id: any;
    templateFamilies: Array<any>;
    description_contains: any;
    token_label: any;
    showTemplateTokens: boolean;
    tokenTemplateID: number;
    currentPage: number;
    pageSize: number;
    totalPages: number;
}

export class TemplateSearch extends React.Component<AppProps, AppState> {

    templateFamilyService: TemplateFamilyService = new TemplateFamilyService();
    downloadService: DownloadService = new DownloadService();

    constructor(props, state) {
        super(props, state);
        this.state = this.reset();   
        
        this.closeTemplateTokens = this.closeTemplateTokens.bind(this);
    }

    reset() {
        return (
            {
                name_contains: '',
                template_id: '',
                template_family_id: 0,
                templateFamilies: [],
                description_contains: '',
                token_label: '',
                listItems: [],
                columns: [],
                isDataLoaded: false,
                displayNoItems: false,
                showTemplateTokens: false,
                tokenTemplateID: null,
                currentPage: 1,
                pageSize: 10000,
                totalPages: 1
            }
        );
    }

    componentDidMount() {
        this.setState({
            listItems: [],
            columns: [],
            isDataLoaded: false
        });
        this.fetchTemplateFamilies();
    }

    fetchListItems(data) {
        let items = [];
        if (data) {
            this.setState({
                currentPage: data.page
            });
        }
        data = Utils.toUrlParams(data);
        Log.debug('Fetching List Items', data);
        Util.startSpinning();
        OpalStateManager.fetchEntityList(OpalEntity.TemplateSearch, data, null, true).then((templates: any) => {
            Log.info('Fetched List Items', templates);
            if (templates.length > 0) {
                templates.forEach(item => {
                    items.push(item);
                    if (items.length === templates.length) {                        
                        this.setState({
                            listItems: items,
                            isDataLoaded: true,
                            displayNoItems: false
                        });
                        this.setColumns();                    
                    }                
                });
            }
            else {
                this.setState({
                    listItems: [],
                    isDataLoaded: true,
                    displayNoItems: true,
                    totalPages: 1,
                    currentPage: 1
                });
            }
            Util.stopSpinning();
        }).catch(error => {
            Util.stopSpinning();
                Util.showToast(new Toast('Error fetching templates.', MessageBarType.error));
            Log.error('Error fetching templates', error);
        });
    }

    downloadTemplateHeaderFile(name, id, fileType) {
        this.downloadService.headerDownload(name, id, fileType).then()
        .catch((error) => {
            Util.showToast(new Toast('Error downloading file. ' + error.join(" "), MessageBarType.error));
        });
    }

    continueInterview(document) {
        let self = this;
        Log.debug('Continue Interview', document);
        Util.startSpinning();
        return OpalStateManager.fetchEntity(OpalEntity.DocumentSearch, document.id, null, true)
        .then((data) => {
            Log.info('Continue Interview', data);            
            let template_id = data.data.relationships.template.data.id;
            self.props.history.push('/guided-interview-edit/-' + template_id + '/' + document.id);
            Util.stopSpinning();             
        }).catch(error => {
            Util.stopSpinning();
            Util.showToast(new Toast('Error fetching Guided Interview.', MessageBarType.error));
            Log.error('Error fetching Guided Interview', error);
        });
    }

    showTemplateTokens(data) {
        this.setState({
            showTemplateTokens: true, 
            tokenTemplateID: data.id
        }); 
    }

    closeTemplateTokens() {
        this.setState({ 
            showTemplateTokens: false, 
            tokenTemplateID: null
        });
    }

    setColumns() {
        const templateAbility = this.props.permissions.can("manage", "template");
        const listColumns = [
            {
                field: "name",
                headerText: "Template Name",
                width: "15"
            },
            {
                field: "templateFamilyName",
                headerText: "Template Family",
                width: "15"
            },
            {
                field: "author.name",
                headerText: "Author",
                width: "15",
                filter: "Checkbox"
            },
            {
                field: "currentRevision.updated_at",
                headerText: "Last Edit",
                width: "15",
                type: "date",
                format: "yyyy-MM-dd"
            },
            {
                field: "currentRevision.documents_count",
                headerText: "Documents",
                width: "15",
                itemType: "Link",
                customAttributes: {
                    urlTemplate: "#/explorer/{templateFamilyName}-{templateFamilyId}-templates/{name}-{currentRevision.id}-documents"
                }
            },
            {
                field: "options",
                headerText: "Options",
                width: "15",
                disableSorting: true,
                disableFiltering: true,
                itemType: "Dropdown",
                customAttributes: {
                    buttonText: "Options",
                    dropdownTemplate: [{
                        sectionProps: [
                            {
                                title: 'View',
                                items: [
                                    {
                                        text: 'Template Tokens',
                                        onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, i?: IContextualMenuItem) => {
                                            this.showTemplateTokens(i.item);
                                        }
                                    }
                                ]
                            },
                            {
                                title: templateAbility ? 'Download' : '',
                                items: [
                                    {
                                        text: 'Header File',
                                        className: templateAbility ? "" : "hidden",
                                        disabled: !templateAbility,
                                        onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, i?: IContextualMenuItem) => {
                                            this.downloadTemplateHeaderFile(i.item.name, i.item.currentRevision.id, "csv");
                                        }
                                    }
                                ]
                            }
                        ]
                    }]
                }  
            }
        ];
        this.setState({
            columns: listColumns
        });
    }

    fetchTemplateFamilies() {
        Log.debug('Fetching Template Families');
        this.templateFamilyService.fetchTemplateFamilies()
            .then((data) => {
                if (data !== undefined) {
                    Log.info('Fetched Template Families', data);
                    this.setState({ templateFamilies: this.templateFamilyService.templateFamilyListOptions });
                    this.setState(prevState => ({
                        templateFamilies: [ {key: 0, text: "All"}, ...prevState.templateFamilies]
                    }));
                }
                else {
                    Util.showToast(new Toast('Error fetching Template Families.', MessageBarType.error));
                }
            }).catch(error => {
                Log.error('Error fetching Template Families', error);
            });
    }

    search(value) {
        if (value !== "") {
            let search = {
                name_contains: this.state.name_contains || '',
                template_family_id: this.state.template_family_id > 0 ? this.state.template_family_id : '',
                description_contains: this.state.description_contains || '',
                token_label: this.state.token_label || '',
                id: this.state.template_id || '',
                page: 1,
                page_size: this.state.pageSize
            };
            this.setState({
                displayNoItems: false,
                currentPage: 1,
                totalPages: 1
            });
            this.fetchListItems(search);
        }
    }

    resetFilters() {
        this.setState({
            name_contains: '',
            template_family_id: 0,
            template_id: '',
            description_contains: '',
            token_label: '',
            listItems: [],
            displayNoItems: false,
            currentPage: 1,
            totalPages: 1
        });
    }

    render() {
        return (
            <div>
                <div className="mt1">
                    <SearchBox 
                        value={this.state && this.state.name_contains} 
                        placeholder="Search by template name" 
                        ariaLabel="Search by Template Name" 
                        onSearch={ (value) => {
                            this.search(value);
                        }}  
                        onChange={(_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, search?: string) => {                     
                            this.setState({
                                name_contains: search
                            });
                        }}
                        onClear={() => {
                            this.setState({
                                displayNoItems: false
                            });
                        }}
                   />

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-lg6 ms-xl2 mt05">
                            <Label>Template ID</Label>
                            <SearchBox 
                                value={this.state && this.state.template_id} 
                                placeholder="Type Template ID" 
                                ariaLabel="Search by Template ID" 
                                onSearch={ (value) => {
                                    this.search(value);
                                }}  
                                onChange={(_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, search?: string) => {                     
                                    this.setState({
                                        template_id: search
                                    });
                                }}
                                onClear={() => {
                                    this.setState({
                                        displayNoItems: false
                                    });
                                }}
                           />
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-lg6 ms-xl2 mt05">
                            <Dropdown 
                                responsiveMode={2}
                                notifyOnReselect={true}
                                defaultSelectedKey={(this.state && this.state.template_family_id) || 0}
                                placeholder='Select a Template Family'
                                label='Template Family'
                                id='TemplateFamily'
                                ariaLabel='Template Family'
                                options={ this.state ? this.state.templateFamilies : [] }
                                onChange={
                                    (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
                                        this.setState({template_family_id: item.key});
                                    }
                                }
                           />
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-lg6 ms-xl5 mt05">
                            <Label>Description</Label>
                            <SearchBox 
                                value={this.state && this.state.description_contains} 
                                placeholder="Type Description" 
                                ariaLabel="Search by Description" 
                                onSearch={ (value) => {
                                    this.search(value);
                                }}  
                                onChange={(_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, search?: string) => {                     
                                    this.setState({
                                        description_contains: search
                                    });
                                }}
                                onClear={() => {
                                    this.setState({
                                        displayNoItems: false
                                    });
                                }}
                           />
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3 mt05">
                            <Label>Smart Token</Label>
                            <SearchBox 
                                value={this.state && this.state.token_label} 
                                placeholder="Type Smart Token Name" 
                                ariaLabel="Search by Smart Token" 
                                onSearch={ (value) => {
                                    this.search(value);
                                }}  
                                onChange={(_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, search?: string) => {                     
                                    this.setState({
                                        token_label: search
                                    });
                                }}
                                onClear={() => {
                                    this.setState({
                                        displayNoItems: false
                                    });
                                }}
                           />
                        </div>


                    </div>

                    <br/>

                    <div className="buttonRow right mb1">
                        <DefaultButton text="Reset Filters" onClick={this.resetFilters.bind(this)} disabled={(this.state && this.state.displayNoItems) || (this.state && this.state.listItems.length) > 0 ? false : true} />
                        <PrimaryButton iconProps={{ iconName: 'Search' }} text="Search" disabled={(this.state && this.state.name_contains) || (this.state && this.state.template_id) || (this.state && this.state.description_contains) || (this.state && this.state.token_label) ? false : true} onClick={this.search.bind(this)} />
                    </div>
                </div>

                <TemplateTokens show={this.state.showTemplateTokens} templateID={this.state.tokenTemplateID} onClose={this.closeTemplateTokens}/>
                {this.state && this.state.listItems.length > 0 &&
                    <OpalTable
                        listData={this.state && this.state.listItems}
                        listColumns={this.state && this.state.columns}
                        allowPaging={true}
                        pageSettings={{
                            pageCount: 5,
                            pageSizes: true,
                            pageSize: 10
                        }}
                    />
                }
                <br/>
                {this.state && this.state.displayNoItems && 
                    <MessageBar>No results found</MessageBar>
                }
                 
            </div>
        );
    }
}

export default TemplateSearch;
