import * as React from 'react';
import {withRouter} from 'react-router-dom';
import {Nav} from 'office-ui-fabric-react';

const Menu = withRouter(({ history }) => (
    <div className="opal-side-nav p1">
        <Nav
            onLinkClick={(event, element) => {
                if (event && element) {
                    event.preventDefault();
                    history.push(element.url);
                }
            }}
            selectedKey={history.location.pathname.replace("-", "/").split("/")[1]}
            groups={[
                {
                    links: [
                        {
                            key: 'explorer',
                            name: 'Explorer',
                            url: '/explorer',
                            icon: 'DocLibrary'
                        },
                        {
                            key: 'search',
                            name: 'Search',
                            url: '/search',
                            icon: 'Search'
                        },
                        {
                            key: 'guided',
                            name: 'Guided Interview',
                            url: '/guided-interview',
                            icon: 'Family'
                        },
                        {
                            key: 'bulk',
                            name: 'Bulk Jobs',
                            url: '/bulk-jobs',
                            icon: 'Family'
                        }
                    ]
                }
            ]}
       />
    </div>
));

export default Menu;
