import {Model} from "@voxfp/opal_ui_common";

export class DocumentModel extends Model<DocumentModel> {

    name?: string;
    author?: string;
    updatedAt?: any;
    documentFlowId?: number;
    templateName?: string;
    templateFamilyName?: string;
    workflowState?: string;
    workflowEvents?: string;

    constructor(name?: string, author?: string, updatedAt?: any, documentFlowId?: number, templateName?: string, templateFamilyName?: string, workflowState?: string, workflowEvents?: string) {
        super(DocumentModel);
        this.name = name;
        this.author = author;
        this.updatedAt = updatedAt;
        this.documentFlowId = documentFlowId;
        this.templateName = templateName;
        this.templateFamilyName = templateFamilyName;
        this.workflowState = workflowState;
        this.workflowEvents = workflowEvents;
    }

    
}
