import {Model} from "@voxfp/opal_ui_common";

export class BulkJob extends Model<BulkJob> {
    
    userId?: number;
    bulkJobTypeId?: number;
    bulkJobStatusId?: number;
    name?: string;
    originalFilename?: string;
    jobSettings?: any;
    uploadData?: any;
    duration?: number;
    id?: number;    
    jobType?: string;
    workflowState?: string;
    createdAt?: Date;

    constructor(
        userId?: number,
        bulkJobTypeId?: number,
        bulkJobStatusId?: number,
        name?: string,
        originalFilename?: string,
        jobSettings?: any,
        uploadData?: any,
        duration?: number,
        id?: number,        
        jobType?: string,
        workflowState?: string,
        createdAt?: Date
    ) {
        super(BulkJob);
        this.id = id;
        this.name = name;
        this.duration = duration;
        this.jobType = jobType;
        this.workflowState = workflowState;
        this.createdAt = createdAt;
        this.userId = userId;
        this.bulkJobTypeId = bulkJobTypeId;
        this.bulkJobStatusId = bulkJobStatusId;
        this.originalFilename = originalFilename;
        this.jobSettings = jobSettings;
        this.uploadData = uploadData;

    }
}
