import * as React from 'react';
import {Breadcrumb} from 'office-ui-fabric-react';
import {ExplorerGrid} from './ExplorerGrid';

export interface AppProps {
    title: string;
    history: any;
    location?: any;
    permissions: any;
  }
  
  export interface AppState {
      breadcrumbs: Array<any>;
      path: any;
      id: number;
  }
export class DocumentExplorer extends React.Component<AppProps, AppState> {

    constructor(props, state) {
        super(props, state);
        this.state = {
            path: this.props.location.pathname,
            id: null,
            breadcrumbs: null
        };
    }

    componentDidMount() {
        this.pathToBreadCrumbs(this.state.path);
    }

    componentDidUpdate(newProps) {
        let newPath = newProps.history.location.pathname;
        if (newPath !== this.state.path) {
            this.setState({
                path: newPath
            });
            this.pathToBreadCrumbs(newPath);
        } 
    }

    pathToBreadCrumbs(path) {
        let self = this;
        path = path.split('explorer').pop().replace(/^\//, '').replace(/\/$/, '');
        let levels = path.split('/');
        let breadcrumbs = [
            {text: 'Template Families', key: '/templatefamilies', onClick: () => {
                let breadcrumbs = self.state.breadcrumbs;
                if (breadcrumbs.length > 1) {
                    breadcrumbs = breadcrumbs.slice(0, 1);
                    self.setState({
                        breadcrumbs: breadcrumbs
                    });
                    self.props.history.push("/explorer");
                }
            }, type: 'templatefamilies'}
        ];
        if (levels[0] !== '') {
            for (let iLevel = 0; iLevel < levels.length; iLevel++) {
                let handle = levels[iLevel].split('~');
                let breadcrumbtype = handle[2];
                let redirect = null;
                if (breadcrumbtype === "templates") {
                    redirect = "/explorer/" + levels[0];
                    handle[0] = handle[0] + " - Templates";
                }
                else if (breadcrumbtype === "documents") {
                    redirect = "/explorer/" + levels[0] + "/" + levels[1];
                    handle[0] = handle[0] + " - Documents";
                }
                else if (breadcrumbtype === "revisions") {
                    redirect = "/explorer/" + levels[0] + "/" + levels[1];
                    handle[0] = handle[0] + " - Version History";
                }
                else if (breadcrumbtype === "revisiondocuments") {
                    redirect = "/explorer/" + levels[0] + "/" + levels[1];
                    handle[0] = "Version " + handle[3] + " - Documents";
                }
                breadcrumbs.push(this.createBreadCrumb(handle, redirect));
            }
        }
        this.setState({
            breadcrumbs: breadcrumbs
        });
    }

    createBreadCrumb(handle, redirect) {
        
        let self = this;
        let id = handle[1];
        let breadcrumbtype = handle[2];
        let url = '';
        if (breadcrumbtype === "templates") {
            url = '/template_families/' + id;
        }
        else if (breadcrumbtype === "documents") {
            url = '/templates/' + id + '/documents';
        }
        else if (breadcrumbtype === "revisions") {
            url = '/templates/' + id + '/template_revisions';
        }
        else if (breadcrumbtype === "revisiondocuments") {
            url = '/templates/' + id + '/revision_documents';
        }
        let name = handle[0];
        name = name.replace(/~.~/g, '-');
        let data = {
            text: decodeURIComponent(name), 
            key: url,
            onClick: () => {
                
                let breadcrumbs = self.state.breadcrumbs;
                if (breadcrumbs[breadcrumbs.length - 1] === data) {
                    //return false;
                }
                for (let iBreadCrumb = 0; iBreadCrumb < breadcrumbs.length; iBreadCrumb++) {
                    if (breadcrumbs[iBreadCrumb] === data) {
                        breadcrumbs = breadcrumbs.slice(0, iBreadCrumb + 1);
                        self.setState({breadcrumbs: breadcrumbs});
                        self.props.history.push(redirect);
                    }
                }
            },  
            type: breadcrumbtype
        };
        return data;
    }


    render() {
        let path = this.props.location.pathname;
        let breadcrumbs = this.state.breadcrumbs;
        //let self = this;
        let url = this.state && this.state.breadcrumbs && breadcrumbs[breadcrumbs.length - 1].key;
        let type = this.state && this.state.breadcrumbs && breadcrumbs[breadcrumbs.length - 1].type;
        return (
            
            <div>
                <h2 className="mt0 mb05">{this.props.title}</h2>
                {this.state.breadcrumbs && this.state.breadcrumbs.length > 1 &&
                    <Breadcrumb items={this.state.breadcrumbs} maxDisplayedItems={5} />
                }
                {url && type &&
                    <ExplorerGrid path={path} url={url} type={type} history={this.props.history} permissions={this.props.permissions} />
                }
            </div>
        );
    }
}

export default DocumentExplorer;
