import * as React from 'react';
import {Pivot, PivotItem} from 'office-ui-fabric-react';
import {DocumentSearch} from './DocumentSearch';
import {TemplateSearch} from './TemplateSearch';

export interface AppProps {
    title: string;
    history: any;
    permissions: any;
}

export interface AppState {
}

export class DocumentSearchScreen extends React.Component<AppProps, AppState> {

  render() {

    return (
        <div>

            <h2 className="mt0 mb05">{this.props.title}</h2>
            <Pivot>
                <PivotItem headerText='Find Document' itemKey='0'>
                    <DocumentSearch path={''} mode={''} history={this.props.history} permissions={this.props.permissions} />					
                </PivotItem>
                <PivotItem headerText='Find Template' itemKey='1'>
                    <TemplateSearch path={''} mode={''} history={this.props.history} permissions={this.props.permissions} />	
                </PivotItem>
                {/* <PivotItem headerText='Saved Searches' itemKey='2'>
                    
                </PivotItem> */}
            </Pivot>
                
        </div>
    );
  }
}

export default DocumentSearchScreen;
