import {Model} from "@voxfp/opal_ui_common";
import { Content } from "./content";

export class DocumentContent extends Model<DocumentContent> {

    label?: string;
    friendlyName?: string;
    content?: Content;

    constructor(label?: string, friendlyName?: string, content?: any) {
        super(DocumentContent);
        this.label = label;
        this.friendlyName = friendlyName;
        this.content = content;
    }

    
}
