import {Model} from "@voxfp/opal_ui_common";
import { DocumentFlowSection } from "./documentFlowSection";
import { Template } from "./template";

export class DocumentFlow extends Model<DocumentFlow> {

    name?: string;
    updatedAt?: any;
    documentFlowSections?: Array<DocumentFlowSection>;
    template?: Template;

    constructor(name?: string, updatedAt?: any, documentFlowSections?: Array<DocumentFlowSection>, template?: Template) {
        super(DocumentFlow);
        this.name = name;
        this.updatedAt = updatedAt;
        this.documentFlowSections = documentFlowSections;
        this.template = template;
    }
}
