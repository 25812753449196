import {Log, Util} from "@voxfp/opal_ui_common";
import {BulkJob} from "../model/bulkJob";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import {CommonUtils} from "../utils/commonUtils";

export class BulkJobsService {

    constructor () {
        OpalStateManager.observeList(OpalEntity.BulkJobs, this.fetchBulkJobs);
    }

    fetchBulkJobs() {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.BulkJobs, null, null, true)
            .then((data: Array<BulkJob>) => {
                resolve(data);
            }).catch(error => {      
                Log.error("BulkJobService:fetchBulkJobs", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    fetchBulkJobRequests(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.BulkJobRequests, id, null, true)
            .then((data: any) => {
                resolve(data);
            }).catch(error => {      
                Log.error("BulkJobService:fetchBulkJobRequests", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    fetchBulkJobRequestItems(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.BulkJobRequestItems, id, null, true)
            .then((data: any) => {
                resolve(data);
            }).catch(error => {      
                Log.error("BulkJobService:fetchBulkJobRequestItems", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    getBulkJobRequestItemDetails(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntity(OpalEntity.BulkJobRequestItemDetails, id, null, true)
            .then((data: any) => {
                resolve(data);
            }).catch(error => {      
                Log.error("BulkJobService:getBulkJobRequestItemDetails", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    getBulkJob(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntity(OpalEntity.BulkJobs, id, null, true)
            .then((data: BulkJob) => {
                resolve(data);
            }).catch(error => {      
                Log.error("BulkJobService:getBulkJob", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    fetchBulkJobDocuments(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.BulkJobDocuments, id, null, true)
            .then((data: any) => {
                resolve(data);
            }).catch(error => {      
                Log.error("BulkJobService:fetchBulkJobDocuments", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    fetchBulkJobErrors(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.BulkJobErrors, id, null, true)
            .then((data: any) => {
                resolve(data);
            }).catch(error => {      
                Log.error("BulkJobService:fetchBulkJobErrors", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    fetchTemplateHeaders(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.TemplateRevisionData, id, null, true)
            .then((data: any) => {
                resolve(data);
            }).catch(error => {      
                Log.error("BulkJobService:fetchTemplateHeaders", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    createBulkJob(bulkJob) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.createEntity(OpalEntity.BulkJobCreate, bulkJob)
            .then((data: BulkJob) => {
                resolve(data);
            }).catch(error => {      
                Log.error("BulkJobService:createBulkJob", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

}
