import * as React from 'react';
import {DocumentService} from '../../services/documentService';
import {Breadcrumb, IBreadcrumbItem, Label, MessageBarType, DefaultButton, MessageBar} from "office-ui-fabric-react";
import {DownloadService} from '../../services/downloadService';
import { Toast, Util } from '@voxfp/opal_ui_common';
import DocumentFlowForm from './documentFlow';
import WorkflowButton from '../workflowButtons/workflowButton';
export interface AppProps {
    match?: any;
    title: string;
}

export interface AppState {
    documentName: string;
    templateName: string;
    templateFamily: string;
    progress: Array<any>;
    progressPercentage: number;
    workflowEvents: Array<any>;
    documentId: number;
    documentFlowId: number;
    error: boolean;
}
export class DocumentInterview extends React.Component<AppProps, AppState> {

    documentService: DocumentService = new DocumentService();
    downloadService: DownloadService = new DownloadService();
    public breadcrumbList: Array<IBreadcrumbItem> = [];
    
    constructor(props, state) {
        super(props, state);
        this.state = this.reset();
    }

    componentDidMount() {
        this.setState(
            this.reset()
        );
        this.getView();
    }

    reset() {
        return {
            documentName: '',
            templateName: '',
            templateFamily: '',
            progress: [],
            progressPercentage: 0,
            workflowEvents: [],
            documentId: 0,
            documentFlowId: 0,
            error: false
        };
    }
    getView() {
        let id = null;
        if (this.props.match.params.id) {
            const query = new URLSearchParams(this.props.match.params);
            id = Number(query.get('id'));
        }
        this.getDocument(id);
    }

    getDocument(id: number) {
        this.documentService.getDocument(id).then((document: any) => {
            this.setState({
                documentId: id,
                documentFlowId: document.documentFlowId,
                documentName: document.name,
                templateName: document.templateName,
                templateFamily: document.templateFamilyName,
                workflowEvents: document.workflowEvents
            });
        }).catch((error) => {
            Util.showToast(new Toast('Error fetching items. ' + error.join(" "), MessageBarType.error));
            this.setState({ error: true });
        });
    }

    updateProgress(e) {
        let progress = this.state.progress;
        let objIndex = progress.findIndex((item => item.name === e[0].name));
        if (objIndex !== -1) {
            progress[objIndex] = e[0];
        }
        else {
            progress.push(e[0]);
        }
        progress.sort((a, b) => Number(a.sequence) - Number(b.sequence));
        let sectionsLength = 0;
        let itemsCompleted = 0;
        progress.map(
            (item, _i) => {
                sectionsLength = sectionsLength + item.sectionContent.length;
                item.sectionContent.map(
                    (item, _i) => {
                        if (item.complete === true) {
                            itemsCompleted = itemsCompleted + 1;                            
                        }
                        return true;
                    }
                );
                return true;
            }
        );
        let percentage = Math.round((itemsCompleted / sectionsLength) * 100);
        this.setState({
            progressPercentage: percentage,
            progress: progress
        });
    }

    fileDownload(type, id, fileType) {
        this.downloadService.fileDownload(type, id, fileType).then()
        .catch((error) => {
            Util.showToast(new Toast('Error downloading file. ' + error.join(" "), MessageBarType.error));
        });
    }   
    
    updateDocumentState(id, state) {
        this.documentService.updateDocumentState(id, state).then((_data) => {
            Util.showToast(new Toast('Document workflow set to ' + state + '.', MessageBarType.success));
            this.getDocument(this.state.documentId);
            if (state === "Approve") {
                window.history.back();
            }
        })
        .catch((error) => {
            Util.showToast(new Toast('Error setting workflow. ' + error.join(" "), MessageBarType.error));
        });
    }
    
    render() {

        this.breadcrumbList = [
            {
                text: 'Guided Interviews',
                key: 'GI List',
                onClick: (_ev?: React.MouseEvent<HTMLElement, MouseEvent>, _item?: IBreadcrumbItem) => {
                    window.location.assign("#/guided-interview");
                }
            },
            { 
                text: this.state && !this.state.error ? this.state && this.state.documentName : "Error - not found", 
                key: 'f2', 
                isCurrentItem: true,
                onClick: (_ev?: React.MouseEvent<HTMLElement, MouseEvent>, _item?: IBreadcrumbItem) => {
                }
            }
        ];
            
        return (
            <div className='gi-wrapper'>
                <div className="gi-header">
                    <h2 className="mt0 mb05">{this.props.title}</h2>
                    <Breadcrumb items={this.breadcrumbList}></Breadcrumb>
                </div>
                <div className="gi-body-container">
                    {this.state && this.state.progress.length > 0 &&
                    <div className="gi-nav">
                        <div className="docDetails">
                            <h3 className="mb05">{this.state && this.state.documentName}</h3>
                            <small>{this.state && this.state.templateName}, </small> 
                            <small>{this.state && this.state.templateFamily}</small>
                        </div>
                        <div className="ms-Grid-row mb05">
                            <div className="ms-Grid-col ms-sm6">
                                <Label className="pb0">Progress</Label>
                            </div>
                            <div className="ms-Grid-col ms-sm6 right">
                                <Label className="pb0">{this.state && this.state.progressPercentage}%</Label>
                            </div>
                        </div>

                        <div className="progressbar mb05">
                            <div style={{width: this.state && this.state.progressPercentage + "%" }}></div>
                        </div>

                        {this.state && this.state.progress.map(
                            (item, i) => {
                                let count = 0;
                                let sectionLength = item.sectionContent.length;
                                let str = item.name;
                                if (str !== null) {
                                    str = str.replace(/[^\w]|_/g, "").replace(/\s+/g, " ");
                                }
                                return (
                                    <a key={i} className="progressNav" onClick={() => {
                                        const section = document.querySelector("#" + str);
                                        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
                                    }}>
                                        <Label>{item.name}</Label>
                                        <div className="progressCounterWrapper">
                                            {item.sectionContent.map(
                                                (item, _i) => {
                                                    if (item.complete === true) {
                                                        count = count + 1;
                                                    }
                                                    return true;
                                                }
                                            )}
                                            <div className={"progressCounter " + (count === sectionLength ? "complete" : "")}>{count}/{sectionLength}</div>
                                        </div>
                                    </a>
                                );
                            })
                        }
                        {this.state && this.state.progressPercentage === 100 &&
                            <div className="mt2 mb2">
                                
                                <DefaultButton
                                    iconProps={{ iconName: "Download" }}
                                    className="mb1"
                                    style={{ width: "100%" }}
                                    title={"Download document"}
                                    text="Download document (PDF)"
                                    onClick={() => {this.downloadService.fileDownload("documents", this.state.documentId, "pdf"); }}
                                    
                                />

                                {this.state && this.state.workflowEvents.map(
                                    (item, i) => {
                                        return (
                                            <WorkflowButton
                                                key={i}
                                                workflowItem={item}
                                                onHandleClick={(id, name) => this.updateDocumentState(id, name)}
                                                id={this.state.documentId}
                                            />
                                        );
                                    })
                                }

                            </div>
                        }

                    </div>
                    }
                    {this.state.documentFlowId > 0 &&
                        <div className="gi-main">
                            <DocumentFlowForm documentFlowId={this.state.documentFlowId} documentId={this.state.documentId} updateProgress={(e) => {this.updateProgress(e); }} />    
                        </div>
                    }
                    {this.state.error &&
                    <div className="p1" style={{width: "100%"}}>
                        <MessageBar messageBarType={MessageBarType.info}>No interview found.</MessageBar>
                    </div>
                    }
                </div>
            </div>
        );
    }
}

export default DocumentInterview;
