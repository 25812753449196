import {Log, Util} from "@voxfp/opal_ui_common";
import { DocumentDataModel } from "../model/documentDataModel";
import {DocumentFlow} from "../model/documentFlow";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import {CommonUtils} from "../utils/commonUtils";
import {DocumentAvailableTokens} from "../model/documentAvailableTokens";

export class DocumentFlowService {

    getDocumentFlow(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntity(OpalEntity.DocumentFlow, id, null, true)
            .then((documentFlow: DocumentFlow) => {
                resolve(documentFlow);
            }).catch(error => {
                Log.error("DocumentFlowService:getDocumentFlow with id=" +  id, error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    getDocumentFlowSection(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntity(OpalEntity.DocumentFlowSection, id, null, true)
            .then((documentFlow: DocumentFlow) => {
                resolve(documentFlow);
            }).catch(error => {
                Log.error("DocumentFlowService:getDocumentFlow with id=" +  id, error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    getDocumentDataModel(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.DocumentDataModel, id, null, true)
            .then((documentDataModel: DocumentDataModel) => {
                resolve(documentDataModel);
            }).catch(error => {
                Log.error("DocumentFlowService:getDocumentDataModel with id=" +  id, error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    getAvailableTokens(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.DocumentAvailableTokens, id, null, true)
                .then((documentAvailableTokens: DocumentAvailableTokens) => {
                    resolve(documentAvailableTokens);
                }).catch(error => {
                    Log.error("DocumentAvailableTokens:getAvailableTokens with id=" +  id, error);
                    reject(CommonUtils.FormatError(error));
                }).finally(() => {
                    Util.stopSpinning();
                });
        });
    }

}
