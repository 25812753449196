import {Model} from "@voxfp/opal_ui_common";
import { DocumentFlowSectionContent } from "./documentFlowSectionContent";

export class DocumentFlowSection extends Model<DocumentFlowSection> {

    name?: string;
    description?: string;
    updatedAt?: any;
    sectionContent?: Array<DocumentFlowSectionContent>;

    constructor(name?: string, description?: string, updatedAt?: any, sectionContent?: Array<DocumentFlowSectionContent>) {
        super(DocumentFlowSection);
        this.name = name;
        this.description = description;
        this.updatedAt = updatedAt;
        this.sectionContent = sectionContent;
    }

    
}
