import {Model} from "@voxfp/opal_ui_common";
import {SmartRule} from "./smartRule";

export class SmartToken extends Model<SmartToken> {

    label: string;
    friendlyName: string;
    playbookName: string;
    smartRule: SmartRule;
    ooxml?: string;

    constructor(label?: string, friendlyName?: string, playbookName?: string, ooxml?: string, smartRule?: SmartRule) {
        super(SmartToken);
        this.label = label;
        this.friendlyName = friendlyName;
        this.playbookName = playbookName;
        this.ooxml = ooxml;
        this.smartRule = smartRule;
    }

    
}
