
import * as React from 'react';
import {MessageBar, MessageBarType, TextField, Label, Link, IContextualMenuItem} from 'office-ui-fabric-react';
import {DetailsListUtils, Log, Util, Toast, OpalTable} from "@voxfp/opal_ui_common";
import {BulkJobsService} from '../../services/bulkJobsService';
import {DownloadService} from '../../services/downloadService';
import {BulkJobErrors} from './bulkJobErrors';
import {DocumentTokens} from '../tokens/DocumentTokens';

export interface AppProps {
    path: any;
    url: any;
    type: any;
    history: any;
}

export interface AppState {
    listItems: Array<any>;
    itemDetails: any;
    columns: Array<any>;
    isDataLoaded: boolean;
    url: any;
    type: any;
    displayNoItems: boolean;
    showTemplateTokens: boolean;
    showDocumentTokens: boolean;
    tokenTemplateID: number;
    tokenDocumentID: number;
    bulkJobId: number;
    showBulkJobErrors: boolean;
}

export let instance = null;

export class BulkJobsList extends React.Component<AppProps, AppState>  {

    bulkJobsService: BulkJobsService = new BulkJobsService();
    downloadService: DownloadService = new DownloadService();
    public self = this; 

    constructor(props, state) {
        super(props, state);
        this.state = this.reset();   
        instance = this;
    }

    componentDidMount() {
        if (this.props.type === "bulk-job-request-items-details") {
            this.fetchBulkJobRequestItemsDetails(this.props.url);
        }
        else {
            this.fetchListItems(this.props.url, this.props.type);
            this.setColumns(this.props.type);
        }
    }

    reset() {
        return (
            {
                listItems: [],
                itemDetails: null,
                columns: [],
                url: "",
                type: "",
                isDataLoaded: false,
                displayNoItems: true,
                showTemplateTokens: false,
                showDocumentTokens: false,
                tokenTemplateID: null,
                tokenDocumentID: null,
                bulkJobId: null,
                showBulkJobErrors: false
            }
        );
    }

    componentDidUpdate(prevProps) {
        let prevUrl = prevProps.url;
        if (prevUrl !== this.props.url) {
            this.setState({
                listItems: [],
                columns: [],
                itemDetails: null,
                url: this.props.url,
                type: this.props.type,
                isDataLoaded: false,
                displayNoItems: true   
            });
            if (this.props.type === "bulk-job-request-items-details") {
                this.fetchBulkJobRequestItemsDetails(this.props.url);
            }
            else {
                this.fetchListItems(this.props.url, this.props.type);
                this.setColumns(this.props.type);
            }
        }
    }

    fetchListItems(url, type) {
        this.setState({
            url: url
        });
        Log.debug('Fetching List Items', url + ";" + type);
        if (type === "bulk-jobs") {
            Log.debug("Fetching Bulk Jobs", this.state);            
            this.bulkJobsService.fetchBulkJobs().then((data) => { 
                this.populateListItems(data);                         
            }).catch((error) => {
                Util.showToast(new Toast('Error fetching Bulk Jobs. ' + error.join(" "), MessageBarType.error));
                this.setState({
                    displayNoItems: true,
                    isDataLoaded: true
                });
            });
        }
        else if (type === "bulk-job-requests") {
            Log.debug("Fetching Bulk Job Requests", this.state);
            let levels = url.split('/');
            let id = levels[2];
            this.bulkJobsService.fetchBulkJobRequests(id).then((data) => { 
                this.populateListItems(data);        
            }).catch((error) => {
                Util.showToast(new Toast('Error fetching Bulk Job requests. ' + error.join(" "), MessageBarType.error));
                this.setState({
                    displayNoItems: true,
                    isDataLoaded: true
                });
            });
        }
        else if (type === "bulk-job-request-items") {
            Log.debug("Fetching Bulk Job Request Items", this.state);
            let levels = url.split('/');
            let id = levels[2];
            this.bulkJobsService.fetchBulkJobRequestItems(id).then((data) => {  
                this.populateListItems(data);            
            }).catch((error) => {
                Util.showToast(new Toast('Error fetching Bulk Job request items. ' + error.join(" "), MessageBarType.error));
                this.setState({
                    displayNoItems: true,
                    isDataLoaded: true
                });
            });
        }
        else if (type === "bulk-job-documents") {
            Log.debug("Fetching Bulk Job Documents", this.state);
            let levels = url.split('/');
            let id = levels[2];
            this.bulkJobsService.fetchBulkJobDocuments(id).then((data) => {  
                this.populateListItems(data);            
            }).catch((error) => {
                Util.showToast(new Toast('Error fetching Bulk Job Documents. ' + error.join(" "), MessageBarType.error));
                this.setState({
                    displayNoItems: true,
                    isDataLoaded: true
                });
            });
        }
    }

    populateListItems(data) {
        if (data.length > 0) {        
            let items = [];    
            data.forEach(item => {
                items.push(item);
                if (items.length === data.length) {
                    DetailsListUtils.sortItems(items, "id", true);
                    this.setState({
                        listItems: items,
                        displayNoItems: false,
                        isDataLoaded: true
                    });
                }
            }); 
        }
        else {
            this.setState({
                displayNoItems: true,
                isDataLoaded: true
            });
        }
    }

    handleViewDocuments(item) {
        this.props.history.push(this.props.path + "/" + item.name + "~documents~" + item.id);
    }

    setColumns(type) {
        if (type === "bulk-jobs") {
            const listColumns = [
                {
                    field: "name",
                    headerText: "Name",
                    width: "15",
                    itemType: "Link",
                    customAttributes: {
                        urlTemplate: "#" + this.props.path + "/{name}~{id}"
                    }
                },
                {
                    field: "jobType",
                    headerText: "Type",
                    width: "15"
                },
                {
                    field: "workflowState",
                    headerText: "Status",
                    width: "15"
                },
                {
                    field: "createdAt",
                    headerText: "Date Created",
                    width: "15",
                    type: "date",
                    format: "yyyy-MM-dd"
                },
                {
                    field: "options",
                    headerText: "Options",
                    width: "15",
                    disableSorting: true,
                    disableFiltering: true,
                    itemType: "Dropdown",
                    customAttributes: {
                        buttonText: "Options",
                        dropdownTemplate: [{
                            sectionProps: [{
                                title: 'View',
                                items: [
                                    {
                                        onRender: (i: any, dismissMenu: (ev?: any, dismissAll?: boolean) => void) => {
                                            return (
                                                i.item.jobType === "Create Documents" && 
                                                <Link onClick={() => {
                                                    this.handleViewDocuments(i.item);
                                                    dismissMenu();
                                                }} className="opal-ContextualMenu-link">
                                                    <div className="opal-ContextualMenu-linkContent">
                                                        <span className="opal-ContextualMenu-itemText">
                                                            Documents
                                                        </span>
                                                    </div>
                                                </Link>
                                            );
                                        }
                                    }
                                ]
                            }]
                        }]
                    }
                }
            ];
            this.setState({
                columns: listColumns
            });
        }
        else if (type === "bulk-job-requests") {
            const listColumns = [
                {
                    field: "originalUploadFilename",
                    headerText: "File Name",
                    width: "15",
                    itemType: "Link",
                    customAttributes: {
                        urlTemplate: "#" + this.props.path + "/{originalUploadFilename}~{id}"
                    }

                },
                {
                    field: "workflowState",
                    headerText: "Status",
                    width: "15"
                },
                {
                    field: "duration",
                    headerText: "Duration (ms)",
                    width: "15"
                }
            ];
            this.setState({
                columns: listColumns
            });
        }
        else if (type === "bulk-job-request-items") {
            const listColumns = [
                {
                    field: "requestSequenceNum",
                    headerText: "Line Number",
                    width: "15",                    
                    customAttributes: {
                        urlTemplate: "#" + this.props.path + "/{requestSequenceNum}~{id}"
                    }
                },
                {
                    field: "workflowState",
                    headerText: "Status",
                    width: "15"
                },
                {
                    field: "msgCount",
                    headerText: "Errors",
                    width: "15",
                    customAttributes: {
                        class: "link"
                    }
                }
            ];
            this.setState({
                columns: listColumns
            });
        }
        else if (type === "bulk-job-documents") {
            const listColumns = [
                {
                    field: "name",
                    headerText: "Name",
                    width: "15"

                },
                {
                    field: "author",
                    headerText: "Author",
                    width: "15",
                    filter: "Checkbox"
                },
                {
                    field: "updatedAt",
                    headerText: "Last Edit",
                    width: "15",
                    type: "date",
                    format: "yyyy-MM-dd"
                },
                {
                    field: "options",
                    headerText: "Options",
                    width: "15",
                    disableSorting: true,
                    disableFiltering: true,
                    itemType: "Dropdown",
                    customAttributes: {
                        buttonText: "Options",
                        dropdownTemplate: [{
                            sectionProps: [{
                                title: 'View',
                                items: [
                                    {
                                        text: 'Document Quickview',
                                        onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, i?: IContextualMenuItem) => {
                                            this.openTokenDocumentData(i.item);
                                        }
                                    }
                                ]
                            },
                            {
                                title: 'Download',
                                items: [
                                    {
                                        text: 'Document Data',
                                        onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, i?: IContextualMenuItem) => {
                                            this.downloadDocumentDataFile(i.item.name, i.item.id, 'csv');
                                        }
                                    },
                                    {
                                        text: 'Document as Word',
                                        onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, i?: IContextualMenuItem) => {
                                            this.fileDownload("documents", i.item.id, "DOCX");
                                        }
                                    },
                                    {
                                        text: 'Document as PDF',
                                        onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, i?: IContextualMenuItem) => {
                                            this.fileDownload("documents", i.item.id, "PDF");
                                        }
                                    }
                                ]
                            }]
                        }]
                    }
                        
                }
            ];
            this.setState({
                columns: listColumns
            });
        }
    }

    fetchBulkJobRequestItemsDetails(url) {
        Log.debug("Fetching Bulk Job Request Items", this.state);
        let levels = url.split('~');
        let idLevels = levels[1].split('/');
        let itemsId = idLevels[2];
        this.bulkJobsService.getBulkJobRequestItemDetails(itemsId).then((data: any) => {  
            if (data === undefined || data.detail.document === undefined) {
                this.setState({
                    displayNoItems: true,
                    isDataLoaded: true
                });
                Util.showToast(new Toast('Error fetching Bulk Jobs Request Items.', MessageBarType.error));
            }   
            else {        
                Log.info('Fetched Bulk Job Request Items', data);
                let itemDetails = data.detail.document["content-control-data"];
                this.setState({
                    itemDetails: itemDetails,
                    isDataLoaded: true,
                    displayNoItems: itemDetails.length === 0 ? true : false
                });                        
            }                
                    
        }).catch((error) => {
            Log.error("Fetching Bulk Job Request Items", error);            
        });
        
    }

    openTokenDocumentData(data) {
        this.setState({
            showDocumentTokens: true, 
            tokenDocumentID: data.id
        });
    }

    closeDocumentTokens() {
        this.setState({ 
            showDocumentTokens: false, 
            tokenDocumentID: null
        });
    }

    closeBulkJobErrors() {
        this.setState({ 
            showBulkJobErrors: false, 
            bulkJobId: null
        });
    }

    downloadDocumentDataFile(name, id, fileType) {
        this.downloadService.documentDataDownload(name, id, fileType).then()
            .catch((error) => {
                Util.showToast(new Toast('Error downloading file. ' + error.join(" "), MessageBarType.error));
            });
    }

    fileDownload(type, id, fileType) {
        this.downloadService.fileDownload(type, id, fileType).then()
        .catch((error) => {
            Util.showToast(new Toast('Error downloading file. ' + error.join(" "), MessageBarType.error));
        });
    }

    onCellClick(row) {
        if (row && row.column.field === "msgCount" && row.rowData.msgCount > 0) {
            this.setState({
                showBulkJobErrors: true,
                bulkJobId: row.rowData.id
            });
        }
        if (row && row.column.field === "requestSequenceNum") {
            this.props.history.push(this.props.path + "/" + row.rowData.requestSequenceNum + "~" + row.rowData.id);
        }
    }

    render() {
        Log.debug('Rendering Details', this.state);

        let onCellClickProps;

        if (this.props.type === "bulk-job-request-items") {
            onCellClickProps = {
                onCellClick: (row) => {this.onCellClick(row); }
            };
        }

        return (
            <div>
                <BulkJobErrors show={this.state.showBulkJobErrors} bulkJobId={this.state.bulkJobId} onClose={() => {this.closeBulkJobErrors(); }}/>
                <DocumentTokens show={this.state.showDocumentTokens} documentID={this.state.tokenDocumentID} onClose={() => {this.closeDocumentTokens(); }}/>
                {this.state && this.state.listItems.length > 0 &&
                   <OpalTable
                        {...onCellClickProps}
                        listData={this.state && this.state.listItems}
                        listColumns={this.state && this.state.columns}
                        allowPaging={this.state.listItems.length > 10 ? true : false}
                        pageSettings={{
                            pageCount: 5,
                            pageSizes: true,
                            pageSize: 10
                        }}
                    />
                }

                {this.state && this.state.itemDetails !== null &&
                    <div className="opal-page py1">                
                        <div className="ms-Grid-row">                    
                            <div className="ms-Grid-col ms-sm12 ms-xxl8">  
                                {this.state && this.state.itemDetails.map((item, i) => {
                                        return item.value ?
                                        <div className="ms-Grid-row mb1">
                                            <div className="ms-Grid-col ms-sm12 ms-xl4 ms-xxl4">
                                                <Label key={"label_" + i}>
                                                        {item.label}
                                                </Label>
                                            </div>
                                            <div className="ms-Grid-col ms-sm12 ms-xl8 ms-xxl8">
                                                <TextField
                                                    key={i}
                                                    readOnly
                                                    value={item.value}
                                                    className="mb05"
                                             />
                                            </div>
                                        </div>
                                        :
                                        <div className="ms-Grid-row mb1">
                                            <div className="ms-Grid-col ms-sm12 ms-xl4 ms-xxl4">
                                                <Label key={"label_" + i}>
                                                    {item.label}
                                                </Label>
                                            </div>
                                            <div className="ms-Grid-col ms-sm12 ms-xl8 ms-xxl8">
                                                {item["repeating-content"].map((repeatingItem, i) => {
                                                    return <div key={"row_" + i} className="ms-Grid-row">                    
                                                        <div className="ms-Grid-col ms-sm12">
                                                            <TextField
                                                                key={i}
                                                                readOnly
                                                                label={repeatingItem.label}
                                                                value={repeatingItem.value}
                                                                className="mb05"
                                                            />
                                                        </div>
                                                    </div>;
                                                })}
                                            </div>
                                        </div>;
                                })}
                            </div>
                        </div>
                    </div>
                }
                
                {this.state && this.state.displayNoItems && this.state.isDataLoaded &&
                    <div className="mt1">
                        <MessageBar>No items to show</MessageBar>
                    </div>
                }
            </div>
        );
    }
}

export default BulkJobsList;
