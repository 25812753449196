import * as React from 'react';
import {DefaultButton, MessageBar, MessageBarType, PrimaryButton} from 'office-ui-fabric-react';
import {DetailsListUtils, Log, Util, Toast, OpalTable} from "@voxfp/opal_ui_common";
import {DocumentService} from '../../services/documentService';
import {WorkflowEventService} from '../../services/workflowEventService';
import Can from "@voxfp/opal_ui_common/dist/components/permissions/can";

export interface AppProps {
    permissions?: any;
    title: string;
}

export interface AppState {
    listItems: Array<any>;
    columns: Array<any>;
    isDataLoaded: boolean;
    isError: boolean;
}

export class DocumentList extends React.Component<AppProps, AppState>  {

    documentService: DocumentService = new DocumentService();
    workflowEventService: WorkflowEventService = new WorkflowEventService();

    constructor(props, state) {
        super(props, state);
        this.state = this.reset();
    }

    reset() {
        return ({
            listItems: [],
            columns: [],
            isDataLoaded: false,
            isError: false
        });
    }

    componentDidMount() {
        this.fetchDocuments();
        this.setDocumentColumns();
    }

    fetchDocuments() {
        Log.debug("Fetching Documents", this.state);
        this.documentService.fetchDocumentsWithQuery("workflow_state=Draft").then((items: any) => {
            DetailsListUtils.sortItems(items, "updatedAt", true);
            this.setState({
                listItems: items,
                isDataLoaded: true,
                isError: false
            });         
        }).catch(error => {
            this.setState({
                isDataLoaded: true,
                isError: true
            });
            Util.showToast(new Toast('Error fetching documents. ' + error.join(" "), MessageBarType.error));          
        });
    }

    setDocumentColumns() {
        const conditionListColumns = [
            {
                field: "name",
                headerText: "Name",
                width: "15",
                itemType: "Link",
                customAttributes: {
                    urlTemplate: "#/guided-interview/{id}"
                }
            },
            {
                field: "templateName",
                headerText: "Template",
                width: "15",
            },
            {
                field: "templateFamilyName",
                headerText: "Template Family",
                width: "15",
            },
            {
                field: "author",
                headerText: "Author",
                width: "15",
            },
            {
                field: "updatedAt",
                headerText: "Last Edit",
                width: "15",
                type: "date",
                format: "yyyy-MM-dd"
            }
        ];
        this.setState({
            columns: conditionListColumns
        });
    }

    handleCreateNewClick() {
        window.location.assign('#/guided-interview-new-document');
    }

    handleCopyClick() {
        window.location.assign('#/guided-interview-copy-document');
    }

    render() {

        Log.debug('Rendering Documents List', this.state);

        return (
            <div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm6">
                        <h2 className="mt0 mb05">{this.props.title}</h2>
                    </div>
                    <div className="ms-Grid-col ms-sm6 right">
                        <Can do="create" on="document">
                            <DefaultButton text="Copy Document" onClick={() => {
                                this.handleCopyClick();
                            }} />
                            &nbsp;
                            <PrimaryButton text="New Document" onClick={() => {
                                this.handleCreateNewClick();
                            }} />
                        </Can>
                    </div>
                </div>
                <div className="mt1">
                    {this.state && this.state.listItems.length > 0 && this.state.columns !== [] &&
                        <OpalTable
                            listData={this.state && this.state.listItems}
                            listColumns={this.state && this.state.columns}
                            allowPaging={true}
                            pageSettings={{
                                pageCount: 5,
                                pageSizes: true,
                                pageSize: 10
                            }}
                        />
                    }
                    {((this.state && this.state.isError) || (this.state && this.state.listItems.length === 0)) && this.state.isDataLoaded &&
                        <MessageBar>There are no items to show.</MessageBar>
                    }
                </div>
            </div>
        );
    }
}

export default DocumentList;




