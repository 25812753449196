import * as React from 'react';
import {IContextualMenuItem, MessageBar, MessageBarType, Link} from 'office-ui-fabric-react';
import {Log, Util, Toast, OpalTable} from "@voxfp/opal_ui_common";
import OpalStateManager, {OpalEntity} from "../../state/stateManager";
import {TemplateTokens} from '../tokens/TemplateTokens';
import {DocumentTokens} from '../tokens/DocumentTokens';
import {TemplateRevisionService} from '../../services/templateRevisions';
import {DownloadService} from '../../services/downloadService';
import { TemplateFamily } from '../../model/templateFamily';
import {WorkflowEventService} from '../../services/workflowEventService';

export interface AppProps {
    path: any;
    url: any;
    type: any;
    history: any;
    permissions: any;
}

export interface AppState {
    listItems: Array<any>;
    columns: Array<any>;
    isDataLoaded: boolean;
    url: any;
    type: any;
    displayNoItems: boolean;
    showTemplateTokens: boolean;
    showDocumentTokens: boolean;
    tokenTemplateID: number;
    tokenDocumentID: number;
}

export class ExplorerGrid extends React.Component<AppProps, AppState>  {

    templateRevisionService: TemplateRevisionService = new TemplateRevisionService();
    downloadService: DownloadService = new DownloadService();
    workflowEventService: WorkflowEventService = new WorkflowEventService();

    constructor(props, state) {
        super(props, state);
        this.state = this.reset();   
        
        this.closeTemplateTokens = this.closeTemplateTokens.bind(this);
        this.closeDocumentTokens = this.closeDocumentTokens.bind(this);
    }

    componentDidMount() {
        this.setList();
    }

    setList() {
        this.fetchListItems(this.props.url, this.props.type);
        this.setColumns(this.props.type);
    }

    reset() {
        return (
            {
                listItems: [],
                columns: [],
                url: this.props.url,
                type: this.props.type,
                isDataLoaded: false,
                displayNoItems: false,
                showTemplateTokens: false,
                showDocumentTokens: false,
                tokenTemplateID: null,
                tokenDocumentID: null
            }
        );
    }

    componentDidUpdate(prevProps) {
        let prevUrl = prevProps.url;
        if (prevUrl !== this.props.url) {
            this.setState({
                listItems: [],
                columns: [],
                url: this.props.url,
                type: this.props.type,
                isDataLoaded: false,
                displayNoItems: false
            });
            this.fetchListItems(this.props.url, this.props.type);
            this.setColumns(this.props.type);
        }
    }

    fetchListItems(url, type) {
        this.setState({
            url: url
        });
        
        Log.debug('Fetching List Items', url + ";" + type);
        if (type === "templatefamilies") {
            Util.startSpinning();
            OpalStateManager.fetchEntityList(OpalEntity.TemplateFamily).then((templateFamilies: TemplateFamily) => {
                let listGroup = templateFamilies;
                this.getListItems(listGroup);      
                Util.stopSpinning();         
            }).catch(error => {
                Util.stopSpinning(); 
                this.setState({
                    displayNoItems: true
                });
                Util.showToast(new Toast('Error fetching Template Families.', MessageBarType.error));
                Log.error('Error fetching Template Families', error);
            });
        }
        if (type === "templates") {
            Util.startSpinning();
            let levels = url.split('/');
            let id = levels[2];
            OpalStateManager.fetchEntityList(OpalEntity.TemplateFamilyTemplates, {templateFamilyId: id}, null, true).then((templates: any) => {
                let listGroup = templates;
                this.getListItems(listGroup);
                Util.stopSpinning();            
            }).catch(error => {
                Util.stopSpinning(); 
                this.setState({
                    displayNoItems: true
                });  
                Util.showToast(new Toast('Error fetching Templates.', MessageBarType.error));
                Log.error('Error fetching Templates', error);
            });
        }
        if (type === "documents" || type === "revisiondocuments") {
            Util.startSpinning();
            let levels = url.split('/');
                
                let id = levels[2];
            OpalStateManager.fetchEntityList(OpalEntity.TemplateDocuments, {templateId: id}, null, true).then((documents: any) => {
                let listGroup = documents;
                this.getListItems(listGroup);
                Util.stopSpinning();                
            }).catch(error => {
                Util.stopSpinning();  
                this.setState({
                    displayNoItems: true
                });              
                Util.showToast(new Toast('Error fetching Documents.', MessageBarType.error));
                Log.error('Error fetching Documents', error);
            });
        }
        if (type === "revisions") {
            Util.startSpinning();
            let levels = url.split('/');
            let id = levels[2];
            OpalStateManager.fetchEntityList(OpalEntity.TemplateRevisions, id, null, true).then((revisions: any) => {
                let listGroup = revisions;
                this.getListItems(listGroup);
                Util.stopSpinning();                
            }).catch(error => {
                Util.stopSpinning();  
                this.setState({
                    displayNoItems: true
                });              
                Util.showToast(new Toast('Error fetching revisions.', MessageBarType.error));
                Log.error('Error fetching revisions', error);
            });
        }
    }

    getListItems(listGroup) {
        Util.startSpinning();
        let items = [];
        if (listGroup.length > 0) {
            listGroup.forEach(family => {
                items.push(family);
                if (items.length === listGroup.length) { 
                    Util.stopSpinning();  
                    this.setState({
                        listItems: items,
                        isDataLoaded: true, 
                        displayNoItems: false
                    });                            
                }
            });
        }
        else {
            Util.stopSpinning();  
            this.setState({
                listItems: [],
                isDataLoaded: true, 
                displayNoItems: true
            });
        }
    }

    continueInterview(document) {
        let self = this;
        Log.debug('Continue Interview', document);
        Util.startSpinning();
        return OpalStateManager.fetchEntity(OpalEntity.Documents, document.id, null, true)
        .then((data) => {
            Log.info('Continue Interview', data);
            let template_id = data.data.relationships.template.data.id;
            self.props.history.push('/guided-interview-edit/-' + template_id + '/' + document.id);
            Util.stopSpinning();           
        }).catch(error => {
            Util.stopSpinning();
            Util.showToast(new Toast('Error fetching Guided Interview.', MessageBarType.error));
            Log.error('Error fetching Guided Interview', error);
        });
    }

    openTokenDocumentData(data) {
        this.setState({
            showDocumentTokens: true, 
            tokenDocumentID: data.id
        });
    }

    closeDocumentTokens() {
        this.setState({ 
            showDocumentTokens: false, 
            tokenDocumentID: null
        });
    }

    openTemplateData(data) {
        this.setState({
            showTemplateTokens: true, 
            tokenTemplateID: data.id
        }); 
    }

    closeTemplateTokens() {
        this.setState({ 
            showTemplateTokens: false, 
            tokenTemplateID: null
        });
    }

    updateRevisionState(id, state) {
        this.templateRevisionService.updateTemplateRevisionState(id, state).then((_data) => {
            Util.showToast(new Toast('Template workflow set to ' + state + '.', MessageBarType.success));
            this.setList();
        })
        .catch((error) => {
            Util.showToast(new Toast('Error setting workflow. ' + error.join(" "), MessageBarType.error));
        });
    }

    deleteRevision(id) {
        this.templateRevisionService.deleteTemplateRevision(id).then((_data) => {
            Util.showToast(new Toast('Revision deleted.', MessageBarType.success));
            this.setList();
        })
        .catch((error) => {
            Util.showToast(new Toast('Error deleting revision. ' + error.join(" "), MessageBarType.error));
        });
    }

    createNewRevision(item) {
        this.templateRevisionService.createTemplateRevision(item.id).then((data) => {
            let workflowState = data["workflowState"];
            Util.showToast(new Toast('A new revision was created, and is in ' + workflowState + ' status. Use the Word Add In for editing.', MessageBarType.success));
            this.setList();
        })
        .catch((error) => {
            Util.showToast(new Toast('Error creating revision. ' + error.join(" "), MessageBarType.error));
        });
    }

    downloadTemplateHeaderFile(name, id, fileType) {
        this.downloadService.headerDownload(name, id, fileType).then()
        .catch((error) => {
            Util.showToast(new Toast('Error downloading file. ' + error.join(" "), MessageBarType.error));
        });
    }

    downloadDocumentDataFile(name, id, fileType) {
        this.downloadService.documentDataDownload(name, id, fileType).then()
        .catch((error) => {
            Util.showToast(new Toast('Error downloading file. ' + error.join(" "), MessageBarType.error));
        });
    }

    fileDownload(type, id, fileType) {
        this.downloadService.fileDownload(type, id, fileType).then()
        .catch((error) => {
            Util.showToast(new Toast('Error downloading file. ' + error.join(" "), MessageBarType.error));
        });
    }

    handleVersionHistoryClick(item) {
        let name = encodeURIComponent(item.name);
        this.props.history.push(this.props.path + "/" + name + "~" + item.id + "~revisions");
    }

    handleWorkflowState(id, workflowEvent) {
        this.setItemState(id, workflowEvent);
    }

    setItemState(id, state) {
        this.workflowEventService.setWorkflowEvent(id, state).then((_data) => {
            Util.showToast(new Toast('Template workflow set to ' + state + '.', MessageBarType.success));
            this.setList();
        })
        .catch((error) => {
            Util.showToast(new Toast('Error setting workflow. ' + error.join(" "), MessageBarType.error));
        });
    }

    setColumns(type) {
        const templateAbility = this.props.permissions.can("manage", "template");
        if (type === "templatefamilies") {
            const listColumns = [
                {
                    field: "name",
                    headerText: "Template Family",
                    width: "15"
                },
                {
                    field: "description",
                    headerText: "Description",
                    width: "15"
                },
                {
                    field: "templatesCount",
                    headerText: "Templates",
                    width: "15",
                    itemType: "Link",
                    customAttributes: {
                        urlTemplate: "#" + this.props.path + "/{name}~{id}~templates"
                    }
                } 
            ];
            this.setState({
                columns: listColumns
            });
        }
        if (type === "templates") {
            const listColumns = [
                {
                    field: "name",
                    headerText: "Template Name",
                    width: "15"
                },
                {
                    field: "currentRevision.updated_at",
                    headerText: "Last Edit",
                    width: "15",
                    type: "date",
                    format: "yyyy-MM-dd"
                },
                {
                    field: "currentRevision.documents_count",
                    headerText: "Documents",
                    width: "15",
                    itemType: "Link",
                    customAttributes: {
                        urlTemplate: "#" + this.props.path + "/{name}~{currentRevision.id}~documents"
                    }
                },
                {
                    field: "version",
                    headerText: "Version",
                    filter: "Checkbox",
                    width: "15"
                },
                {
                    field: "currentRevision.workflow_state",
                    headerText: "State",
                    filter: "Checkbox",
                    width: "15"
                },
                {
                    field: "options",
                    headerText: "Options",
                    width: "15",
                    disableSorting: true,
                    disableFiltering: true,
                    itemType: "Dropdown",
                    customAttributes: {
                        buttonText: "Options",
                        dropdownTemplate: [{
                            sectionProps: [
                                {
                                    title: 'View',
                                    items: [
                                        {
                                            text: 'Version History',
                                            onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, i?: IContextualMenuItem) => {
                                                this.handleVersionHistoryClick(i.item);
                                            }
                                        },
                                        {
                                            text: 'Template Tokens',
                                            onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, i?: IContextualMenuItem) => {
                                                this.openTemplateData(i.item);
                                            }
                                        }
                                    ]
                                },
                                {
                                    title: templateAbility ? 'Manage' : '',
                                    items: [
                                        {
                                            text: 'New Revision',
                                            className: templateAbility ? "" : "hidden",
                                            disabled: !templateAbility,
                                            onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, i?: IContextualMenuItem) => {
                                                this.createNewRevision(i.item);
                                            }
                                        },
                                        {
                                            text: 'Edit Template',
                                            className: templateAbility ? "" : "hidden",
                                            disabled: !templateAbility,
                                            onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, i?: IContextualMenuItem) => {
                                                this.fileDownload("templates", i.item.id, "DOCX");
                                            }
                                        }
                                    ]
                                },
                                {
                                    title: templateAbility ? 'Download' : '',
                                    items: [
                                        {
                                            text: 'Header File',
                                            className: templateAbility ? "" : "hidden",
                                            disabled: !templateAbility,
                                            onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, i?: IContextualMenuItem) => {
                                                this.downloadTemplateHeaderFile(i.item.name, i.item.currentRevision.id, "csv");
                                            }
                                        }
                                    ]
                                }
                            ]
                        }]
                    }    
                }
            ];
            this.setState({
                columns: listColumns
            });
        }
        if (type === "documents") {
            const listColumns = [
                {
                    field: "name",
                    headerText: "Document Name",
                    width: "15"
                },
                {
                    field: "author",
                    headerText: "Author",
                    filter: "Checkbox",
                    width: "15"
                },
                {
                    field: "updatedAt",
                    headerText: "Last Edit",
                    width: "15",
                    type: "date",
                    format: "yyyy-MM-dd"
                },
                {
                    field: "options",
                    headerText: "Options",
                    width: "15",
                    disableSorting: true,
                    disableFiltering: true,
                    itemType: "Dropdown",
                    customAttributes: {
                        buttonText: "Options",
                        dropdownTemplate: [{
                            sectionProps: [{
                                title: 'View',
                                items: [
                                    {
                                        text: 'Document Quickview',
                                        onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, i?: IContextualMenuItem) => {
                                            this.openTokenDocumentData(i.item);
                                        }
                                    }
                                ]
                            },
                            {
                                title: 'Download',
                                items: [
                                    {
                                        text: 'Document Data',
                                        onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, i?: IContextualMenuItem) => {
                                            this.downloadDocumentDataFile(i.item.name, i.item.id, 'csv');
                                        }
                                    },
                                    {
                                        text: 'Document as Word',
                                        onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, i?: IContextualMenuItem) => {
                                            this.fileDownload("documents", i.item.id, "DOCX");
                                        }
                                    },
                                    {
                                        text: 'Document as PDF',
                                        onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, i?: IContextualMenuItem) => {
                                            this.fileDownload("documents", i.item.id, "PDF");
                                        }
                                    }
                                ]
                            }]
                        }]
                    }  
                }
            ];
            this.setState({
                columns: listColumns
            });
        }
        if (type === "revisions") {
            const listColumns = [
                {
                    field: "version",
                    headerText: "Version",
                    width: "15"
                },
                {
                    field: "author",
                    headerText: "Author",
                    filter: "Checkbox",
                    width: "15"
                },
                {
                    field: "updatedAt",
                    headerText: "Last Edit",
                    width: "15",
                    type: "date",
                    format: "yyyy-MM-dd"
                },
                {
                    field: "documentsCount",
                    headerText: "Documents",
                    width: "15",
                    itemType: "Link",
                    customAttributes: {
                        urlTemplate: "#" + this.props.path + "/{template.name}~{id}~revisiondocuments~{version}"
                    }
                },
                {
                    field: "workflowState",
                    headerText: "State",
                    filter: "Checkbox",
                    width: "15"
                },
                {
                    field: "options",
                    headerText: templateAbility ? 'Options' : '',
                    width: templateAbility ? "15" : "0",
                    disableSorting: true,
                    disableFiltering: true,
                    itemType: "Dropdown",
                    buttonText: "Options",
                    customAttributes: {
                        buttonText: "Options",
                        dropdownTemplate: [{
                            sectionProps: [{
                                title: 'Actions',
                                items: [
                                    {
                                        onRender: (i: any, dismissMenu: (ev?: any, dismissAll?: boolean) => void) => {
                                            return (
                                                i.item.workflowEvents.map(
                                                    (workflowItem, _key) => {
                                                    return (
                                                            <Link onClick={() => {
                                                                this.handleWorkflowState(i.item.id, workflowItem.name);
                                                                dismissMenu();
                                                            }} className="opal-ContextualMenu-link">
                                                                <div className="opal-ContextualMenu-linkContent">
                                                                    <span className="opal-ContextualMenu-itemText">
                                                                        {workflowItem.description}
                                                                    </span>
                                                                </div>
                                                            </Link>
                                                        );
                                                    }
                                                )
                                            );
                                        }
                                    }
                                ]
                            }]
                        }]
                    }    
                }
            ];
            this.setState({
                columns: listColumns
            });
        }
        if (type === "revisiondocuments") {
            const listColumns = [
                {
                    field: "name",
                    headerText: "Document Name",
                    width: "15"
                },
                {
                    field: "author",
                    headerText: "Author",
                    filter: "Checkbox",
                    width: "15"
                },
                {
                    field: "updatedAt",
                    headerText: "Last Edit",
                    width: "15",
                    type: "date",
                    format: "yyyy-MM-dd"
                },
                {
                    field: "options",
                    headerText: "Options",
                    width: "15",
                    disableSorting: true,
                    disableFiltering: true,
                    itemType: "Dropdown",
                    customAttributes: {
                        buttonText: "Options",
                        dropdownTemplate: [{
                            sectionProps: [{
                                title: 'View',
                                items: [
                                    {
                                        text: 'Document Quickview',
                                        onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, i?: IContextualMenuItem) => {
                                            this.openTokenDocumentData(i.item);
                                        }
                                    }
                                ]
                            },
                            {
                                title: 'Download',
                                items: [
                                    {
                                        text: 'Document Data',
                                        onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, i?: IContextualMenuItem) => {
                                            this.downloadDocumentDataFile(i.item.name, i.item.id, 'csv');
                                        }
                                    },
                                    {
                                        text: 'Document as Word',
                                        onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, i?: IContextualMenuItem) => {
                                            this.fileDownload("documents", i.item.id, "DOCX");
                                        }
                                    },
                                    {
                                        text: 'Document as PDF',
                                        onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, i?: IContextualMenuItem) => {
                                            this.fileDownload("documents", i.item.id, "PDF");
                                        }
                                    }
                                ]
                            }]
                        }]
                    } 
                }
            ];
            this.setState({
                columns: listColumns
            });
        }
    }

    render() {
        
        Log.debug('Rendering Details', this.state);
        return (
            <div className="mt1">
                <TemplateTokens show={this.state.showTemplateTokens} templateID={this.state.tokenTemplateID} onClose={this.closeTemplateTokens}/>
                <DocumentTokens show={this.state.showDocumentTokens} documentID={this.state.tokenDocumentID} onClose={this.closeDocumentTokens}/>
                {this.state && this.state.listItems.length > 0 &&
                   <OpalTable
                        listData={this.state && this.state.listItems}
                        listColumns={this.state && this.state.columns}
                        allowPaging={true}
                        pageSettings={{
                            pageCount: 5,
                            pageSizes: true,
                            pageSize: 10
                        }}
                    />
                }
                {this.state && (this.state.displayNoItems || this.state.listItems === []) &&
                    <MessageBar>No items to show</MessageBar>
                }
                
            </div>
        );
    }
}

export default ExplorerGrid;
