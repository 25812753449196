import {Log, Util} from "@voxfp/opal_ui_common";
import {SmartToken} from "../model/smartToken";
import {IDropdownOption} from "office-ui-fabric-react";
import {ServiceObserver} from "./serviceObserver";
import OpalStateManager, {OpalEntity} from "../state/stateManager";

export class SmartTokenService {

    public tokenList: Array<SmartToken> = [];
    public tokenListOptions: Array<IDropdownOption> = [];

    constructor () {
        this.tokenList = [];
        this.tokenListOptions = [];

        OpalStateManager.observeList(OpalEntity.TemplateToken, this.fetchSmartTokens);
    }

    fetchSmartTokens(templateId?) {
        let self = this;
        Log.debug("SmartTokenService:fetchSmartTokens");
        Util.startSpinning();
        return OpalStateManager.fetchEntityList(OpalEntity.TemplateToken, {templateId: templateId}, null, true)
        .then((data: any) => {
            Log.info("SmartTokenService:fetchSmartTokens", data);
            self.tokenList = data;
            self.tokenListOptions = Util.orderOptions(data, 'label', 'friendlyName');
            ServiceObserver.trigger('SmartTokenService:fetchSmartTokens', {list: self.tokenList, options: self.tokenListOptions});
            Util.stopSpinning();
            return data;
        }).catch(error => {            
            Util.stopSpinning();
            Log.error("SmartTokenService:fetchSmartTokens", error);
        });
    }
}
