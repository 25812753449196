import {Model} from "@voxfp/opal_ui_common";

export class BulkJobRequestItems extends Model<BulkJobRequestItems> {
    
    id?: number;
    bulkJobRequestId?: number;
    requestSequenceNum?: any;
    msgCount?: any;
    workflowState?: string;
    uniqueId?: number;
    createdAt?: any;
    updatedAt?: any;
    detail?: JSON;

    constructor(
        id?: number,
        bulkJobRequestId?: number,
        requestSequenceNum?: any,
        msgCount?: any,
        workflowState?: string,
        uniqueId?: number,
        createdAt?: any,
        updatedAt?: any,
        detail?: JSON
    ) {
        super(BulkJobRequestItems);
        this.id = id;
        this.bulkJobRequestId = bulkJobRequestId;
        this.requestSequenceNum = requestSequenceNum || "0";
        this.msgCount = msgCount || 0;
        this.workflowState = workflowState;
        this.uniqueId = uniqueId;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.detail = detail;
    }
}
