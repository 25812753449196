import * as React from 'react';
import {DocumentFlowService} from '../../services/documentFlowService';
import {DocumentContentService} from '../../services/documentContentService';
import {MessageBarType} from "office-ui-fabric-react";
import { Toast, Util } from '@voxfp/opal_ui_common';
import DocumentItem from './documentItem';
import DocumentChartItem from './documentChartItem';
import DocumentRepeatingItem from './documentRepeatingItem';

export interface AppProps {
    documentFlowSectionId: number;
    documentId: number;
    handleProgressUpdate: any;
    sequence: number;
}

export interface AppState {
    sectionName: string;
    sectionDescription: string;
    sectionContent: Array<any>;
    dataModel: Array<any>;
    docContent: Array<any>;
    docModel: Array<any>;
    availableTokens: Array<any>;
}

export class DocumentFlowSection extends React.Component<AppProps, AppState> {

    documentFlowService: DocumentFlowService = new DocumentFlowService();
    documentContentService: DocumentContentService = new DocumentContentService();

    constructor(props, state) {
        super(props, state);
        this.state = {
            sectionName: null,
            sectionDescription: null,
            sectionContent: [],
            dataModel: [],
            docContent: [],
            docModel: [],
            availableTokens: [],
        };
        this.getDocument();
    }


    reset() {
        return {
            sectionName: null,
            sectionDescription: null,
            sectionContent: [],
            dataModel: [],
            docContent: [],
            docModel: [],
            availableTokens: [],
        };
    }

    getDocument() {
        this.fetchDocumentDataModel().then((documentDataModel: any) => {
            this.fetchDocumentContents().then((documentContent: any) => {
                this.fetchCharts().then((documentAvailableTokens: any) => {
                this.fetchDocumentFlowSection(documentDataModel, documentContent, this.props.documentFlowSectionId, documentAvailableTokens);
            })});
        });
    }

    fetchDocumentDataModel = () => {
        return this.documentFlowService.getDocumentDataModel(this.props.documentId).then((documentDataModel: any) => {
            return documentDataModel.data;
        }).catch((error) => {
            Util.showToast(new Toast('Error fetching document data model. ' + error.join(" "), MessageBarType.error));
        });
    }

    fetchDocumentContents = (flowControl?) => {
        let flowControlCheck = flowControl ? flowControl : false;
        return this.documentContentService.fetchDocumentContents(this.props.documentId, flowControlCheck).then((documentContent: any) => {
            return documentContent;
        }).catch((error) => {
            Util.showToast(new Toast('Error fetching document contents. ' + error.join(" "), MessageBarType.error));
        });
    }

    fetchCharts = () => {
        return this.documentFlowService.getAvailableTokens(this.props.documentId).then((availableTokensModel: any) => {
            return availableTokensModel;
        }).catch((error) => {
            Util.showToast(new Toast('Error fetching document data model. ' + error.join(" "), MessageBarType.error));
        });
    }

    fetchDocumentFlowSection(docModel: Array<any>, docContent: Array<any>, documentFlowSectionId: number, documentAvailableTokens: any) {
        this.documentFlowService.getDocumentFlowSection(documentFlowSectionId).then((documentFlowSection: any) => {

            let docModelIndex = docModel.map(docModelItem => docModelItem.label);

            let sectionContent = documentFlowSection.sectionContent.filter((sectionContentItem) => {
                return docModelIndex.indexOf(sectionContentItem.attributes.name) !== -1;
            });

            let sectionContentWithModel = sectionContent.map(sectionContentItem => ({
                ...docModel.find((docModelItem) => (sectionContentItem.attributes.name === docModelItem.label) && docModelItem),
                ...sectionContentItem
            }));

            sectionContentWithModel.map(sectionContentFilteredItem => {
                if (sectionContentFilteredItem.children && sectionContentFilteredItem.attributes.items) {
                    let docModelChildren = sectionContentFilteredItem.children;
                    let sectionRepeatItems = sectionContentFilteredItem.attributes.items;
                    if (sectionContentFilteredItem.type === "document_flow_repeat_group") {
                        let repeatItems = sectionRepeatItems.map(sectionRepeatItem => ({
                            ...docModelChildren.find((docModelChildrenItem) => (sectionRepeatItem.attributes.name === docModelChildrenItem.label) && docModelChildrenItem),
                            ...sectionRepeatItem
                        }));
                        let docModelChildrenIndex = docModelChildren.map(docModelChildrenItem => docModelChildrenItem.label);
                        let repeatItemsFiltered = repeatItems.filter((repeatItem) => {
                            return docModelChildrenIndex.indexOf(repeatItem.attributes.name) !== -1;
                        });
                        sectionContentFilteredItem.attributes.items = repeatItemsFiltered;
                    }
                }
            });

            sectionContentWithModel.sort((a, b) => Number(a.attributes.sequence) - Number(b.attributes.sequence));

            this.setState({
                sectionName: documentFlowSection.name,
                sectionDescription: documentFlowSection.description,
                sectionContent: sectionContentWithModel,
                docContent: docContent,
                docModel: docModel,
                availableTokens: documentAvailableTokens,
            });

        }).catch((error) => {
            console.log('--------------');
            console.log(error);
            console.log('--------------');
            Util.showToast(new Toast('Error fetching document flow. ' + error.join(" "), MessageBarType.error));
        });
    }

    saveDocumentContent(value, contentControl) {
        this.loadingSpinner(contentControl.id);
        let data = {
            document: {
                content_control_data: [
                    {
                        content_control: contentControl.label,
                        value: value
                    }
                ]
            }
        };

        this.documentContentService.saveDocumentContent(data, this.props.documentId, contentControl.flowControl).then(() => {
            this.updateContentValidState(contentControl, value, true);
            if (contentControl.flowControl) {
                this.getDocument();
            }
        }).catch((error) => {
            this.updateContentValidState(contentControl, value, false, error);
        });
    }

    loadingSpinner(id) {
        let sectionContent = this.state.sectionContent;
        let objIndex = sectionContent.findIndex((item => {
            if (!!item.attributes["smart_token"]) {
                return item.attributes["smart_token"]["content_control_revision"].id === id;
            } else if (!!item.attributes["smart_chart"]) {
                return item.attributes["smart_chart"]["content_control_revision"].id === id;
            } else {
                return false;
            }
        }));

        sectionContent[objIndex]["loading"] = true;
        this.setState({
            sectionContent: sectionContent
        });
    }

    updateContentValidState(contentControl, value?, isValid?, error?) {
        let sectionContent = this.state.sectionContent;
        let flowControl = contentControl.flowControl !== undefined ? contentControl.flowControl : false;
        let objIndex = sectionContent.findIndex((item => item.attributes["name"] === contentControl.label));
        if (value) {
            sectionContent[objIndex]["value"] = value;
        }
        sectionContent[objIndex]["validUserInput"] = isValid;
        sectionContent[objIndex]["loading"] = false;
        sectionContent[objIndex]["errorMessage"] = error ? error : "";

        this.fetchDocumentContents(flowControl).then((documentContent: any) => {
            this.setState({
                sectionContent: sectionContent,
                docContent: documentContent
            });
        });

        // this.saveDocumentContent(value, contentControl);
    }

    render() {
        let progress = [];
        if (this.state && this.state.sectionName) {
            progress = [{
                "name": this.state && this.state.sectionName,
                "sequence": this.props.sequence,
                "sectionContent": [],
            }];
        }

        let documentItem = this.state && this.state.sectionContent.map(
            (item, i) => {
                progress[0].sectionContent.push(
                    {
                        "item": item.label,
                        "complete": item["is_valid"] || (item.validUserInput !== undefined ? item.validUserInput : false)
                    }
                );
                let itemDetails = this.state.docContent.filter((docContent) => {
                    return item.attributes.name.indexOf(docContent.label) !== -1;
                });
                if (item.type === "document_flow_repeat_group") {
                    let docContent = itemDetails !== undefined && itemDetails.length > 0 ? itemDetails[0].content : [];
                    return (
                        <DocumentRepeatingItem key={i} item={item} documentId={this.props.documentId} docContent={docContent} onSaveChange={(c, v, l) => { this.updateContentValidState(c, v, l); }} />
                    );
                }
                else {
                    if (item.attributes["smart_token"]) {
                        let itemValue = itemDetails[0] ? itemDetails[0].content.value : "";
                        let itemObject = {
                            id: item.attributes["smart_token"]["content_control_revision"].id,
                            smartRule: item.attributes["smart_token"]["smart_rule"],
                            label: item.attributes.name,
                            description: item.attributes.description,
                            value: itemValue,
                            isValid: item["is_valid"],
                            flowControl: item["flow_control"],
                            validUserInput: item.validUserInput !== undefined ? item.validUserInput : null,
                            loading: item.loading !== undefined ? item.loading : false,
                            errorMessage: item.errorMessage !== undefined ? item.errorMessage : ""
                        };
                        return (
                            <DocumentItem key={i} item={itemObject} onSaveChange={(data, item) => { this.saveDocumentContent(data, item); }} />
                        );
                    } else {
                        let itemValue = itemDetails[0] ? itemDetails[0].content.value : "";
                        let isPieChart = item.attributes["smart_chart"]["smart_chart_type"].name === "PIE"

                        let itemObject = {
                            id: item.attributes["smart_chart"]["content_control_revision"].id,
                            smartChart: item.attributes["smart_chart"]["smart_chart_type"],
                            label: item.attributes.name,
                            description: item.attributes.description,
                            value: itemValue,
                            isValid: item["is_valid"],
                            flowControl: item["flow_control"],
                            validUserInput: item.validUserInput !== undefined ? item.validUserInput : null,
                            loading: item.loading !== undefined ? item.loading : false,
                            errorMessage: item.errorMessage !== undefined ? item.errorMessage : ""
                        };

                        return (
                            <DocumentChartItem isPieChart={isPieChart}  availableTokens={this.state.availableTokens} key={i} item={itemObject} onSaveChange={(data, item) => { this.saveDocumentContent(data, item); }} />
                        );
                    }
                }

            }
        );

        if (progress.length > 0) {
            this.props.handleProgressUpdate(progress);
        }

        let str = this.state && this.state.sectionName;
        if (str !== null) {
            str = str.replace(/[^\w]|_/g, "").replace(/\s+/g, " ");
        }

        return (
            <div>
                <div id={str} style={{position: "absolute", marginTop: "-88px"}}></div>
                <h3>{this.state && this.state.sectionName}</h3>
                {this.state && this.state.sectionDescription}
                <br/>
                {documentItem}
            </div>
        );
    }
}

export default DocumentFlowSection;
