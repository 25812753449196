import * as React from 'react';
import {Util, Toast} from "@voxfp/opal_ui_common";
import {TextField, PrimaryButton, Breadcrumb, IBreadcrumbItem, DefaultButton, MessageBar, MessageBarType, Dialog, DialogFooter, IContextualMenuItem} from 'office-ui-fabric-react';
import {TemplateAndTemplateFamily} from "../template/templateAndTemplateFamily";
import {BulkJobsService} from '../../services/bulkJobsService';
import {DownloadService} from '../../services/downloadService';

const YAML = require('yamljs');

export interface AppProps {
    title?: string;
    config?: object;
    history?: any;
}

export interface AppState {
    hideSavedDialog: boolean;
    hideCancelDialog: boolean;
    saveError: boolean;
    formValid: boolean;

    jobTypes: Array<any>;    
    templateHeader: Array<any>;
    fileHeader: Array<any>;
    fileContent: any;
    fileValid: boolean;

    selectedJobTypeId: number,
    jobName: string,
    selectedTemplateFamilyId: number,
    selectedTemplateRevisionId: number,
    selectedTemplateName: string,
    selectedFileName: string;
    jobSettings: any;

    randomKey: string;

    createdBulkJobId: number;
    errorCollection: string;
}

export class CreateBulkJob extends React.Component<AppProps, AppState>  {

    bulkJobsService: BulkJobsService = new BulkJobsService();
    downloadService: DownloadService = new DownloadService();
    
    private fileUploadRef: React.RefObject<HTMLInputElement>;
    public breadcrumbList: Array<IBreadcrumbItem> = [];


    constructor(props, state) {
        super(props, state);

        this.breadcrumbList = [
            { text: 'Bulk Jobs', key: 'bulkjobs', href: '#/bulk-jobs' },
            { 
                text: 'New', 
                key: 'f1',
                href: '#/bulk-job/create'
            }
        ];

        this.state = this.reset();

        this.fileUploadRef = React.createRef();
    }

    componentDidMount() {
        this.fetchJobTypes();
    }

    fetchJobTypes() {
        this.setState({
            jobTypes: [ {key: 1, text: "Create Documents"}]
        });
        this.setState(prevState => ({
            jobTypes: [ {key: 0, text: "Select a Job Type", disabled: true}, ...prevState.jobTypes]
        }));
    }

    processUploadedFile(e) {
        let file = e.target.files[0]; 
    
        if (file) {
            let reader = new FileReader();
            reader.onload = (e) => {         
                let contents = e.target.result as String; 
                let fileType = file.name.split(".").pop();
                if (fileType === "yaml" || fileType === "yml") {
                    let contentsJSON = YAML.parse(contents.toString());
                    let headersArray = [];
                    let documentsArray = [];
                    if (contentsJSON.documents && contentsJSON.documents.length > 0) {
                        contentsJSON.documents.forEach(item => {
                            documentsArray.push(item.document.content_control_data);
                        });
                    }
                    else if (contentsJSON.documents) {
                        documentsArray.push(contentsJSON.documents.document.content_control_data);
                    }
                    else {
                        documentsArray.push(contentsJSON.document.content_control_data);
                    }
                    documentsArray.forEach(item => {
                        let headers = [];
                        item.forEach(item => {
                            headers.push(item.label);
                        });
                        headers.sort();
                        headersArray.push(headers);
                    });

                    this.setState({
                        fileHeader: headersArray
                    }, () => {
                        this.validateFile();      
                    });
                }
                else {
                    let headersArray = [];                    
                    contents = contents.replace(/\r?\n|\r/g, "<br>"); // Replacing all line break types with an item that can be manipulated
                    let contentStart = contents.indexOf(",<br>") !== -1 ? contents.indexOf(",<br>") : contents.indexOf("<br>");
                    let headersString = contents.substr(0, contentStart);
                    let headers = headersString.split(",");
                    headers.sort();
                    headersArray.push(headers);
                    this.setState({
                        fileHeader: headersArray[0]
                    }, () => {
                        this.validateFile('csv');
                    });
                }
            };
            reader.readAsText(file);
            this.setState({
                selectedFileName: file.name
            });

            let reader64 = new FileReader();
            reader64.onload = (e) => { 
                let contents = e.target.result;
                this.setState({
                    fileContent: contents
                });
            };
            reader64.readAsDataURL(file);
        }
    } 
    
    validateFile(file_type = '') {
        let errorCollection = [];
        let templateHeaders = this.state.templateHeader;
        let fileHeaders = this.state.fileHeader;

        if (fileHeaders.length > 0 && fileHeaders[0][0] && file_type != 'csv') {
            fileHeaders = fileHeaders[0];
        }

        templateHeaders.forEach(header => {
            if (fileHeaders.indexOf(header) === -1) {
                errorCollection.push(header);
            }
        });

        if (errorCollection.length > 0) {
            let missingHeaders = errorCollection.toString();
            this.setState({
                fileValid: false,
                errorCollection: "The following headers are missing: " + missingHeaders.replace(",", ", ")
            });
        }
        else {
            this.setState({
                fileValid: true
            });
        }
    }
    
    clickUpload() {        
        if (this.fileUploadRef.current) {            
            let randomKey = Math.random().toString(36);
            this.setState({
                randomKey: randomKey
            }, () => {
                this.fileUploadRef.current.click();
            });
        }
    }

    handleSelectTemplateFamily(option) {
        this.setState({
            selectedTemplateFamilyId: option,
            selectedTemplateRevisionId: 0,
            selectedTemplateName: '',
            jobSettings: ''
        });
    }

    handleSelectTemplate(option) {
        this.setState({
            selectedTemplateRevisionId: option.key,
            selectedTemplateName: option.text,
            jobSettings: "{template_revision_id: " + option.key + "}"
        });
        this.fetchTemplateHeaders(option.key);
    }

    fetchTemplateHeaders(id) {
        let items = [];
        return this.bulkJobsService.fetchTemplateHeaders(id).then((data: any) => {  
            let templateTokens = data.data.template_tokens.filter(item => item);
            templateTokens.forEach(templateToken => {
                items.push(templateToken.label);
            }); 
            let repeatableTokens = data.data.repeatable_tokens.filter(item => item);
            repeatableTokens.forEach(repeatableToken => {
                items.push(repeatableToken.label);
            }); 

            items.sort();
            this.setState({
                templateHeader: items
            }, () => {
                if (this.state.fileHeader !== null) {
                    this.validateFile();
                }      
            });                
                  
        }).catch((error) => {
            Util.showToast(new Toast('Error fetching Template Headers. ' + error.join(" "), MessageBarType.error));
        });
    }

    createBulkJob() {
        let isValid = this.validateForm();
        if (isValid) {
            let bulkJob = {   
                bulk_job_type_id: 1,
                name: this.state.jobName,
                original_filename: this.state.selectedFileName,
                job_settings: this.state.jobSettings,
                upload_data: this.state.fileContent
            };
            this.bulkJobsService.createBulkJob({bulk_job: bulkJob}).then((job: any) => {
                this.setState({
                    createdBulkJobId: job.id
                });
                this.showSavedDialog();               
            }).catch((error) => {
                Util.showToast(new Toast('Error saving Bulk Job. ' + error.join(" "), MessageBarType.error));
            });            
        }
        else {
            Util.showToast(new Toast('Please ensure all required fields are filled in.', MessageBarType.error));
        }
    }

    cancel() {        
        this.setState({
            hideCancelDialog: false
        });       
    }

    closeDialog() {
        this.setState({
            hideCancelDialog: true
        });
    }

    showSavedDialog() {
        this.setState({
            hideSavedDialog: false
        });
    }

    okContinue() {
        this.setState(
            this.reset()
        );
        this.props.history.push("/bulk-jobs/" + this.state.jobName + "~" + this.state.createdBulkJobId);
    }

    okContinueCancel() {
        this.setState(
            this.reset()
        );
        this.props.history.push("/bulk-jobs");
    }

    handleJobTypeChange(option) {
        this.setState({
            selectedJobTypeId: option.key
        });
    }

    handleNameInput(e) {
        this.setState({
            jobName: e
        });
    }

    downloadTemplateHeaderFile(name, id, fileType) {
        this.downloadService.headerDownload(name, id, fileType).then()
        .catch((error) => {
            Util.showToast(new Toast('Error downloading file. ' + error.join(" "), MessageBarType.error));
        });
    }

    validateForm() {
        if (this.state.jobName.length > 0 && 
            this.state.selectedTemplateFamilyId !== null && 
            this.state.selectedTemplateFamilyId > 0 &&
            this.state.selectedTemplateRevisionId !== null && 
            this.state.selectedTemplateRevisionId > 0 &&
            this.state.selectedFileName.length > 0 &&
            this.state.fileValid === true  ) {
            this.setState({
                formValid: true
            });
            return true;
        }
        else {
            this.setState({
                formValid: false
            });
            return false;
        }
    }

    reset() {
        return {
            hideSavedDialog: true,
            hideCancelDialog: true,
            saveError: false,
            jobTypes: [],
            formValid: null,

            templateHeader: null,
            fileHeader: null,
            fileContent: null,
            fileValid: null,

            selectedJobTypeId: 0,
            jobName: '', 
            selectedTemplateFamilyId: 0,
            selectedTemplateRevisionId: 0,
            selectedTemplateName: '',
            selectedFileName: '',
            jobSettings: {
                template_id: 0
            },
            randomKey: null,
            createdBulkJobId: null,
            errorCollection: ""
        };
    }

    render() {

        return (
            <div>
                <h2 className="mt0 mb05">{this.props.title}</h2>
                <Breadcrumb className="breadcrumbs" items={this.breadcrumbList} maxDisplayedItems={5} />
                <div className="mt1">              
                <div>
                    <div className="ms-Grid-row">                    
                        <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxl4">                            
                            
                            {/* <Dropdown responsiveMode={2}
                                placeholder='Select a Job Type'
                                notifyOnReselect={true}
                                label= 'Job Type'
                                id='jobType'
                                ariaLabel='Job Type'
                                defaultSelectedKey={0}
                                options={this.state && this.state.jobTypes ? this.state.jobTypes : []}
                                onChange={
                                    (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
                                        this.handleJobTypeChange(item);
                                    }
                                }
                                className="mt 1mb05"  
                                errorMessage={this.state && this.state.selectedJobTypeId === 0 && this.state.formValid === false ? "* required" : null} 
                           /> */}
                            
                            <TextField 
                                label="Name" 
                                id="name" 
                                className="mb05"  
                                errorMessage={this.state && this.state.jobName.length === 0 && this.state.formValid === false ? "* required" : null} 
                                value={this.state.jobName} 
                                onChange ={(_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
                                    this.handleNameInput(value);
                                }}
                           />

                            <TemplateAndTemplateFamily 
                                onChangeTemplateFamily={this.handleSelectTemplateFamily.bind(this)}
                                onChangeTemplate={this.handleSelectTemplate.bind(this)}
                                selectedTemplateFamily={this.state && this.state.selectedTemplateFamilyId}
                                selectedTemplate={this.state && this.state.selectedTemplateRevisionId}  
                                formValid={this.state && this.state.formValid}
                           />

                            {this.state && this.state.selectedTemplateRevisionId !== 0 &&
                                <div>                                    
                                    <DefaultButton 
                                        iconProps={{ iconName: "Download" }}
                                        style={{ width: "100%" }}
                                        className={"mb05"}
                                        title={"Download Headers Template"} 
                                        text="Download Headers Template"
                                        menuProps={{
                                            isBeakVisible: false,
                                            items: [
                                                {
                                                    key: 'asCSV',
                                                    text: 'As CSV file',
                                                    iconProps: { iconName: 'FileTemplate' },
                                                    onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, _item?: IContextualMenuItem) => {
                                                        this.downloadTemplateHeaderFile(this.state.selectedTemplateName, this.state.selectedTemplateRevisionId, "csv");
                                                    }
                                                },
                                                {
                                                    key: 'asYAML',
                                                    text: 'As YAML file',
                                                    iconProps: { iconName: 'FileYml' },
                                                    onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, _item?: IContextualMenuItem) => {
                                                        this.downloadTemplateHeaderFile(this.state.selectedTemplateName, this.state.selectedTemplateRevisionId, "yaml");
                                                    }
                                                }
                                            ] 
                                        }}
                                   />
                                    <input type='file'
                                        name='myFile'
                                        ref={this.fileUploadRef}
                                        key={this.state && this.state.randomKey}
                                        accept=".csv, .yaml"     
                                        style={{display: "none"}}                
                                        onChange={this.processUploadedFile.bind(this)}
                                   />
                                    <TextField
                                        readOnly
                                        label="File"
                                        prefix="File:"
                                        placeholder="Click to select a file"
                                        errorMessage={this.state && this.state.selectedFileName.length === 0 && this.state.formValid === false ? "* required" : null} 
                                        className={this.state.fileValid === null ? "mb05" : this.state.fileValid ? "mb05 iconSuccess" : "mb05 iconFailed"}
                                        iconProps={this.state.fileValid === null ? null : this.state.fileValid ? {iconName: "CompletedSolid" } : {iconName: "StatusErrorFull" }}
                                        value={(this.state && this.state.selectedFileName) || ""}
                                        onClick={this.clickUpload.bind(this)}
                                   />
                                    {this.state && this.state.fileValid === false &&
                                        <div className="mb1">
                                            <MessageBar messageBarType={MessageBarType.error}>Headers don't match. {this.state.errorCollection}</MessageBar>
                                        </div>
                                    }
                                </div>
                            }                            

                            <div className="buttonRow right">
                                <DefaultButton text="Cancel" onClick={this.cancel.bind(this)} className="mt1"></DefaultButton>
                                <PrimaryButton iconProps={{ iconName : "CheckMark" }} text="Create" onClick={() => {this.createBulkJob(); }}className="mt1"></PrimaryButton>        
                                
                            </div>
                            {this.state && this.state.saveError === true &&
                                <div>
                                    <br/>
                                    <MessageBar messageBarType={MessageBarType.error}>Error creating Bulk Job. Try again later.</MessageBar>
                                </div>
                            }

                            

                        </div>
                    </div>

                    <br/>
                    
                </div>

                <Dialog
                    hidden={this.state && this.state.hideCancelDialog === null ? true : this.state && this.state.hideCancelDialog}
                    onDismiss={this.closeDialog.bind(this)}
                    dialogContentProps={{
                        title: 'Cancel changes',
                        subText: 'This will remove all changes. Are you sure you want to cancel?'
                    }}
                    modalProps={{
                        isBlocking: true,
                        styles: { main: { maxWidth: 450 } }
                    }}
                    >
                    <DialogFooter>
                        <DefaultButton onClick={this.closeDialog.bind(this)} text="No" />
                        <PrimaryButton onClick={this.okContinueCancel.bind(this)} text="Yes" />
                    </DialogFooter>
                </Dialog>

                <Dialog
                    hidden={this.state && this.state.hideSavedDialog === null ? true : this.state && this.state.hideSavedDialog}
                    dialogContentProps={{
                        title: 'Bulk Job created',
                        subText: 'The Bulk Job was created successfully.'
                    }}
                    modalProps={{
                        isBlocking: true,
                        styles: { main: { maxWidth: 450 } }
                    }}
                    >
                    <DialogFooter>
                        <PrimaryButton onClick={this.okContinue.bind(this)} text="Ok" />
                    </DialogFooter>
                </Dialog>
    
            </div>
            </div> 
        );
    }
}

export default CreateBulkJob;
