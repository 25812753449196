import * as React from 'react';
import { Icon, Label, Spinner, SpinnerSize, TextField } from "office-ui-fabric-react";
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import SpinButton from './SpinButton';

export interface AppProps {
    key?: any;
    item: any;
    onSaveChange?: any;
    acceptAllChanges?: boolean;
}

export interface AppState {
    value: any;
}

export class DocumentItem extends React.Component<AppProps, AppState> {

    constructor(props, state) {
        super(props, state);
        this.state = {
            value: this.props.item.value
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.item.value !== this.props.item.value) {
            this.setState({ value: this.props.item.value });
        }
    }

    render() {
        
        let iconName = "";
        let errorMessage = null;
        let iconColor = "";
        let minVal = -Infinity;
        let maxVal = Infinity;
        let maxLength = Infinity;
        let regexPattern = null;
        let validators = this.props.item.smartRule.validators;

        if (validators.length > 0) {
            let minIndex = validators.findIndex((item => item.name === "MinVal"));
            if (minIndex > -1) {
                minVal = validators[minIndex].value;
            }

            let maxIndex = validators.findIndex((item => item.name === "MaxVal"));
            if (maxIndex > -1) {
                maxVal = validators[maxIndex].value;
            }

            let maxLengthIndex = validators.findIndex((item => item.name === "MaxLength"));
            if (maxLengthIndex > -1) {
                maxLength = validators[maxLengthIndex].value;
            }

            let regexPatternIndex = validators.findIndex((item => item.name === "Pattern"));
            if (regexPatternIndex > -1) {
                regexPattern = validators[regexPatternIndex].value;
            }
        }
    
        if (
            (this.props.item.value === this.state.value && this.props.item.isValid) || 
            (this.props.item.validUserInput && !this.props.item.loading) || 
            (this.props.item.isValid && this.props.item.validUserInput === null && !this.props.item.loading)
        ) {
            iconName = "CompletedSolid";
            iconColor = "iconSuccess";
            errorMessage = null;
        }
        else if (this.props.item.validUserInput === false && !this.props.item.loading) {
            iconName = "AlertSolid";
            iconColor = "iconFailed";
            errorMessage = this.props.item.errorMessage;
        }
        
        if (this.props.item.value !== this.state.value && this.props.item.validUserInput !== false && !this.props.item.loading) {
            iconName = "";
        }

        return (
            <div className="ms-Grid-row mb1">
                <div className="ms-Grid-col ms-sm12 ms-lg4 ms-xxl4">
                    <Label>
                        {this.props.item.label}
                    </Label>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-lg8 ms-xxl8 mb05">
                    {this.props.item.smartRule["token_type_name"] === "String" &&
                    <div>
                        <TextField
                            readOnly={this.props.onSaveChange ? false : true}
                            className={iconColor}
                            maxLength={maxLength}
                            value={this.state && this.state.value ? this.state.value : ""}
                            onBlur={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, _value?: string) => {
                                if (this.props.item.value !== event.target["value"] && !this.props.acceptAllChanges) {
                                    this.props.onSaveChange(event.target["value"], this.props.item);
                                }
                                this.setState({
                                    value: event.target["value"]
                                });
                            }}
                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, _value?: string) => {
                                this.setState({
                                    value: event.target["value"]
                                });
                                if (this.props.acceptAllChanges) {
                                    this.props.onSaveChange(event.target["value"], this.props.item);
                                }
                            }}
                            iconProps={{ iconName: iconName }}
                            errorMessage={errorMessage}
                        />
                        {this.props.item.loading &&
                            <div className="inline-spinner">
                                <Spinner size={SpinnerSize.small} />
                            </div>
                        }
                        <small style={{ opacity: 0.75 }}>{this.props.item.description}</small>
                    </div>
                    }

                    {this.props.item.smartRule["token_type_name"] === "Date" &&
                    <div>
                        <div className="ms-Grid-row">
                            <div className={"ms-Grid-col ms-sm12 ms-xl7 ms-xxl4 datepicker-wrapper " + iconColor + " " + ((this.props.item.value !== this.state.value) && this.props.item.validUserInput === false && !this.props.item.loading ? "e-error" : "")} >
                                <DatePickerComponent
                                    readOnly={this.props.onSaveChange ? false : true}
                                    format='yyyy-MM-dd'
                                    value={this.state && this.state.value ? this.state.value : ""}
                                    onBlur={(e) => {
                                        if (this.props.item.value !== e.target["value"] && !this.props.acceptAllChanges) {
                                            this.props.onSaveChange(e.target["value"], this.props.item);
                                        }
                                    }}
                                    change={(e) => {
                                        this.setState({
                                            value: e.element["value"]
                                        });
                                        if (this.props.acceptAllChanges) {
                                            this.props.onSaveChange(e.element["value"], this.props.item);
                                        }
                                    }}
                                ></DatePickerComponent>
                                {this.props.item.loading &&
                                    <div className="inline-spinner datepicker-spinner">
                                        <Spinner size={SpinnerSize.small} />
                                    </div>
                                }
                                {(this.props.item.validUserInput !== null || this.props.item.isValid) && !this.props.item.loading &&
                                    <div className={"inline-icon datepicker-validation-icon " + iconColor}>
                                        <Icon iconName={iconName}></Icon>
                                    </div>
                                }
                                
                            </div>
                        </div>
                        {errorMessage &&
                            <div className="error-message">{errorMessage}</div>
                        }
                        <small style={{ opacity: 0.75 }}>{this.props.item.description}</small>
                    </div>
                    }

                    {(this.props.item.smartRule["token_type_name"] === "Percentage" || this.props.item.smartRule["token_type_name"] === "Integer" || this.props.item.smartRule["token_type_name"] === "Decimal") &&
                    <div>
                        <div className="ms-Grid-row spinner">
                            <div className={"ms-Grid-col ms-sm12 ms-xl7 ms-xxl4 spinner-wrapper " + ((this.props.item.value !== this.state.value) && this.props.item.validUserInput === false && !this.props.item.loading ? "e-error" : "")} >
                                <SpinButton 
                                    readOnly={this.props.onSaveChange ? false : true}
                                    min={minVal} 
                                    max={maxVal} 
                                    type={this.props.item.smartRule["token_type_name"]}
                                    value={this.props.item.value}
                                    onBlur={(value) => {
                                        if (this.props.item.value !== value && !this.props.acceptAllChanges) {
                                            this.props.onSaveChange(value, this.props.item);
                                        }
                                    }}
                                    onChange={(value) => {
                                        this.setState({
                                            value: value
                                        });
                                        if (this.props.acceptAllChanges) {
                                            this.props.onSaveChange(value, this.props.item);
                                        }
                                    }}
                                />
                            
                                {this.props.item.loading &&
                                    <div className="inline-spinner spin-button-spinner">
                                        <Spinner size={SpinnerSize.small} />
                                    </div>
                                }
                                {(this.props.item.validUserInput !== null || this.props.item.isValid) && !this.props.item.loading &&
                                    <div className={"inline-icon spinner-validation-icon " + iconColor}>
                                        <Icon iconName={iconName}></Icon>
                                    </div>
                                }
                                {this.props.item.smartRule["token_type_name"] === "Percentage" &&
                                    <span className="inline-icon outside">
                                        <Icon iconName={"CalculatorPercentage"}></Icon>
                                    </span>
                                }
                            </div>
                        </div>
                        {errorMessage &&
                            <div className="error-message">{errorMessage}</div>
                        }
                        <small style={{ opacity: 0.75 }}>{this.props.item.description}</small>
                    </div>
                    }
                </div>
            </div>
        );
    }
}

export default DocumentItem;
