import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './assets/styles/main.scss';
import {initializeIcons} from '@uifabric/icons';
import { Route, HashRouter, Switch, Redirect } from 'react-router-dom';
import { DocumentExplorer } from './components/explorer/DocumentExplorer';
import { DocumentSearchScreen } from './components/search/DocumentSearchScreen';
import { BulkJobs } from './components/bulkJobs/bulkJobs';
import { CreateBulkJob } from './components/bulkJobs/create';
import {createTheme, Customizations} from 'office-ui-fabric-react';
import {Header, ToastMessage, buildAbilities, AbilityContext} from '@voxfp/opal_ui_common';
import Menu from './components/menu/menu';
import Cookies from 'universal-cookie';
import DocumentInterview from './components/guidedInterview/document';
import DocumentList from './components/guidedInterview/documentList';
import NewDocument from './components/guidedInterview/newDocument';

const myTheme = createTheme({
    palette: {
        themePrimary: '#45b6b7',
        themeLighterAlt: '#f6fcfc',
        themeLighter: '#dbf3f4',
        themeLight: '#bee9ea',
        themeTertiary: '#85d3d4',
        themeSecondary: '#57bec0',
        themeDarkAlt: '#3fa4a5',
        themeDark: '#358a8c',
        themeDarker: '#276667',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#c0c9c9',
        neutralSecondary: '#859292',
        neutralPrimaryAlt: '#536060',
        neutralPrimary: '#404b4b',
        neutralDark: '#303838',
        black: '#232929',
        white: '#ffffff',
    }
});

initializeIcons();

export interface AppProps {
    history?: any;
}

export interface AppState {
}

const ProtectedRoute = ({ component: Comp, loggedIn, path, title, permissions, ...rest }) => {
    return (
        <Route
            path={path}
            {...rest}
            render={(props) => {
                return loggedIn ? (
                    <Comp title={title} permissions={permissions} {...props} />
                ) : (
                        <Redirect
                            to={'/login'}
                        />
                    );
            }}
        />
    );
};

const cookies = new Cookies();

export class App extends React.Component<AppProps, AppState> {
    private authenticated = false;
    private ability;
    private user;

    onRouteChanged = () => {
        let authToken = cookies.get('authToken');
        let rules = cookies.get('permissions');
        let user = cookies.get('user');
        if (authToken) {
            this.authenticated = true;
            this.ability = buildAbilities(rules);
            this.user = user;
        }
        else {
            this.authenticated = false;
        }
        return true;
    }

    render() {

        Customizations.applySettings({ theme: myTheme });

        return (

            this.onRouteChanged() &&
            <AbilityContext.Provider value={this.ability}>

                <HashRouter>
                    {this.authenticated &&
                        <Header history={this.props.history} user={this.user} />
                    }
                    {this.authenticated &&
                        <Menu />
                    }
                    <div className="opalPage withMenu">
                        <ToastMessage />
                        <div className="opalContent">
                            <Switch>
                                <Route path='/login' component={() => {
                                    window.location.assign(window.ENV.REACT_APP_OPAL_UI_DASHBOARD_URL + '/#/login');
                                    return null;
                                }} />
                                <ProtectedRoute path="/explorer" loggedIn={this.authenticated} component={DocumentExplorer} title="Explorer" permissions={this.ability} />
                                <ProtectedRoute path="/search" loggedIn={this.authenticated} component={DocumentSearchScreen} title="Search" permissions={this.ability}  />
                                <ProtectedRoute exact path="/guided-interview/:id" loggedIn={this.authenticated} component={DocumentInterview} title="Guided Interview" permissions={this.ability} />
                                <ProtectedRoute exact path="/guided-interview" loggedIn={this.authenticated} component={DocumentList} title="Guided Interview" permissions={this.ability}  />
                                <ProtectedRoute path="/guided-interview-new-document" loggedIn={this.authenticated} component={NewDocument} title="Guided Interview" permissions={this.ability}  />
                                <ProtectedRoute path="/guided-interview-copy-document" loggedIn={this.authenticated} component={NewDocument} title="Guided Interview" permissions={this.ability}  />
                                {/* <ProtectedRoute path="/document-copy" loggedIn={this.authenticated} component={CopyDocument} title="Copy Document" permissions={this.ability}  /> */}
                                <ProtectedRoute path="/bulk-jobs" loggedIn={this.authenticated} component={BulkJobs} title="Bulk Jobs" permissions={this.ability}  />
                                <ProtectedRoute path="/bulk-job/create" loggedIn={this.authenticated} component={CreateBulkJob} title="Create Bulk Job" permissions={this.ability}  />
                                <Redirect to="/explorer" />
                            </Switch>
                        </div>
                    </div>
                </HashRouter>
            </AbilityContext.Provider>   

        );
    }
}

ReactDOM.render(<App />, document.getElementById('container'));
