import {Model} from "@voxfp/opal_ui_common";

export class BulkJobRequests extends Model<BulkJobRequests> {
    
    id?: number;
    bulkJobId?: number;
    duration?: number;
    originalUploadFilename?: string;
    workflowState?: any;

    constructor(
        id?: number,
        bulkJobId?: number,
        duration?: number,
        originalUploadFilename?: string,
        workflowState?: any
    ) {
        super(BulkJobRequests);
        this.id = id;
        this.bulkJobId = bulkJobId;
        this.duration = duration;
        this.originalUploadFilename = originalUploadFilename;
        this.workflowState = workflowState;
    }
}
