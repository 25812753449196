import {Log, Util} from "@voxfp/opal_ui_common";
import {Template} from "../model/template";
import {IDropdownOption} from "office-ui-fabric-react";
import {ServiceObserver} from "./serviceObserver";
import OpalStateManager, {OpalEntity} from "../state/stateManager";

export class TemplateService {

    private _templateList: Array<Template> = [];
    private _templateListOptions: Array<IDropdownOption> = [];

    constructor () {
        this._templateList = [];
        this._templateListOptions = [];

        OpalStateManager.observeList(OpalEntity.Template, this.fetchTemplates);
    }

    fetchTemplates() {
        Util.startSpinning();
        return OpalStateManager.fetchEntityList(OpalEntity.Template)
            .then((data: any) => {
                this._templateList = data;
                this._templateListOptions = Util.orderOptions(data, 'id', 'name');
                ServiceObserver.trigger('TemplateService:fetchTemplates', this.templateList);
                Util.stopSpinning();
                return data;
            }).catch(Log.error);
    }

    fetchTemplatesFromFamily(id) {
        Log.debug("TemplateService:fetchTemplatesFromFamily", id);
        Util.startSpinning();
        return OpalStateManager.fetchEntityList(OpalEntity.TemplateFamilyTemplates, {templateFamilyId: id}, null, true)
        .then(async (templates: any) => {
            Log.info("TemplateService:fetchTemplatesFromFamily", templates);
            this._templateList = templates;
            this._templateListOptions = Util.orderOptions(templates, 'currentRevision', 'name');
            ServiceObserver.trigger('TemplateService:fetchTemplatesFromFamily', templates);
            Util.stopSpinning();
            return templates;
        }).catch(error => {
            Log.error("TemplateService:fetchTemplatesFromFamily with ID=" + id, error);
            Util.stopSpinning();
        });
    }

    fetchComposableTemplatesFromFamily(id) {
        Log.debug("TemplateService:fetchComposableTemplatesFromFamily", id);
        Util.startSpinning();
        return OpalStateManager.fetchEntityList(OpalEntity.ComposableTemplateFamilyTemplates, {templateFamilyId: id}, null, true)
        .then(async (templates: any) => {
            Log.info("TemplateService:fetchComposableTemplatesFromFamily", templates);
            this._templateList = templates;
            this._templateListOptions = Util.orderOptions(templates, 'currentRevision', 'name');
            ServiceObserver.trigger('TemplateService:fetchComposableTemplatesFromFamily', templates);
            Util.stopSpinning();
            return templates;
        }).catch(error => {
            Log.error("TemplateService:fetchComposableTemplatesFromFamily with ID=" + id, error);
            Util.stopSpinning();
        });
    }

    getTemplate(id) {
        Log.info('id', id);
        return OpalStateManager.fetchEntity(OpalEntity.Template, id, null, true)
        .then(async (template: Template) => {
            Log.info('template', template);
            ServiceObserver.trigger('TemplateService:getTemplate', template);
            return template;
        }).catch(Log.error);
    }

    saveTemplate(template) {
        if (template.id) {
            return OpalStateManager.updateEntity(OpalEntity.Template, template.id, template);
        }
        return OpalStateManager.createEntity(OpalEntity.Template, template);
    }

    handleTemplateSelectionChange(option) {
        Log.info('this.templateList', this.templateList);
        this.templateList.forEach((template) => {
            if (template.id === option.key) {
                Log.info('template.id === option.key', this);
                this.getTemplate(template.id).then().catch(Log.error);
            }
        });
    }

    get templateList(): any[] {
        return this._templateList;
    }

    get templateListOptions(): any[] {
        return this._templateListOptions;
    }
}
