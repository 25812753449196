import * as React from 'react';
import {Breadcrumb} from 'office-ui-fabric-react';
import {BulkJobsList} from './list';

export interface AppProps {
    history: any;
    location?: any;
    match?: any;
  }
  
  export interface AppState {
      breadcrumbs: Array<any>;
      path: any;
      id: number;
  }
export class BulkJobsExplorer extends React.Component<AppProps, AppState> {

    constructor(props, state) {
        super(props, state);
        this.state = {
            path: this.props.match.pathname,
            id: null,
            breadcrumbs: null
        };
    }

    componentDidMount() {
        this.pathToBreadCrumbs(this.state.path);
    }

    componentDidUpdate(newProps) { 
        let newPath = newProps.history.location.pathname;
        if (newPath !== this.state.path) {
            this.setState({
                path: newPath
            });
            this.pathToBreadCrumbs(newPath);
        } 
    }

    pathToBreadCrumbs(path) {
        let self = this;
        path = path.split('bulk-jobs').pop().replace(/^\//, '').replace(/\/$/, '');
        let levels = path.split('/');
        let breadcrumbs = [
            {text: 'Bulk Jobs', key: '/bulk_jobs', onClick: () => {
                let breadcrumbs = self.state.breadcrumbs;
                if (breadcrumbs.length > 1) {
                    breadcrumbs = breadcrumbs.slice(0, 1);
                    self.setState({
                        breadcrumbs: breadcrumbs
                    });
                    self.props.history.push("/bulk-jobs");
                }
            }, type: 'bulk-jobs'}
        ];
        if (levels[0] !== '') {
            for (let iLevel = 0; iLevel < levels.length; iLevel++) {
                let handle = levels[iLevel].split('~');
                let redirect = null;
                if (handle[1] === "documents") {
                    redirect = "/bulk-jobs/bulk-job/" + levels[1] + '/documents';
                }
                else if (iLevel === 0) {
                    redirect = "/bulk-jobs/" + levels[0];
                }
                else if (iLevel === 1) {
                    redirect = "/bulk-jobs/" + levels[0] + '/' + levels[1];
                }
                else if (iLevel === 2) {
                    redirect = "/bulk-job-requests/" + levels[1] + '/bulk-job-requests-items/' + levels[2];
                }
                breadcrumbs.push(this.createBreadCrumb(handle, iLevel, redirect));
            }
        }
        this.setState({
            breadcrumbs: breadcrumbs
        });
    }

    createBreadCrumb(handle, iLevel, redirect) {
        let childrenType = {};
        childrenType[0] = 'bulk-job-requests';
        childrenType[1] = 'bulk-job-request-items';
        childrenType[2] = 'bulk-job-request-items-details';
        childrenType[3] = 'bulk-job-documents';
        
        let self = this;
        let id = handle.pop();
        let url = '';
        if (handle[1] === "documents") {
            url = "/bulk-jobs/" + id + '/documents';
            iLevel = 3;
        }
        else if (iLevel === 0) {
            url = '/bulk-jobs/' + id;
        }
        else if (iLevel === 1) {
            url = '/bulk-jobs/' + id + '/bulk-job-requests';
        }
        else if (iLevel === 2) {
            let request = redirect.split("/");
            request = request[iLevel].split('-');
            let requestId = request.pop();
            url = '/bulk-job-requests/' + requestId + '/bulk-job-requests-items/' + id;
        }
        else if (iLevel === 3) {
            url = '/bulk-jobs/bulk-job/' + id + '/documents';
        }
        let name = handle.join(' ');
        let data = {text: decodeURIComponent(name), key: url,
        onClick: () => {
            
            let breadcrumbs = self.state.breadcrumbs;
            if (breadcrumbs[breadcrumbs.length - 1] === data) {
                //return false;
            }
            for (let iBreadCrumb = 0; iBreadCrumb < breadcrumbs.length; iBreadCrumb++) {
                if (breadcrumbs[iBreadCrumb] === data) {
                    breadcrumbs = breadcrumbs.slice(0, iBreadCrumb + 1);
                    self.setState({breadcrumbs: breadcrumbs});
                    self.props.history.push(redirect);
                }
            }
        },  type: childrenType[iLevel]};
        return data;
    }


    render() {
        let path = this.props.match.pathname;
        let breadcrumbs = this.state.breadcrumbs;
        let url = this.state && this.state.breadcrumbs && breadcrumbs[breadcrumbs.length - 1].key;
        let type = this.state && this.state.breadcrumbs && breadcrumbs[breadcrumbs.length - 1].type;
        return (
            <div >
                {this.state.breadcrumbs && this.state.breadcrumbs.length > 1 &&
                    <div className="mb1">
                        <Breadcrumb className="breadcrumbs" items={this.state.breadcrumbs} maxDisplayedItems={5} />
                    </div>
                }
                {url && type &&
                    <BulkJobsList path={path} url={url} type={type} history={this.props.history} />
                }
            </div>
        );
    }
}

export default BulkJobsExplorer;
