import {Model} from "@voxfp/opal_ui_common";
import {Template} from "./template";

export class TemplateRevisions extends Model<TemplateRevisions> {

    version?: number;
    documentsCount?: any;
    workflowState?: string;
    workflowEvents?: any;
    updatedAt?: any;
    author?: string;
    template?: Template;

    constructor(version?: number, documentsCount?: any, workflowState?: string, workflowEvents?: any, updatedAt?: any, author?: string, template?: Template) {
        super(TemplateRevisions);
        this.version = version;
        this.documentsCount = documentsCount === undefined ? "0" : documentsCount;
        this.workflowState = workflowState;
        this.workflowEvents = workflowEvents;
        this.updatedAt = updatedAt;
        this.author = author;
        this.template = template;
    }
}
