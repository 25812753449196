import {Log, Util} from "@voxfp/opal_ui_common";
import {DocumentContent} from "../model/documentContent";
import {IDropdownOption} from "office-ui-fabric-react";
import {ServiceObserver} from "./serviceObserver";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import CommonUtils from "../utils/commonUtils";

export class DocumentContentService {

    public documentContentList: Array<DocumentContent> = [];
    public documentContentListOptions: Array<IDropdownOption> = [];

    constructor () {
        this.documentContentList = [];
        this.documentContentListOptions = [];

        OpalStateManager.observeList(OpalEntity.DocumentContent, this.fetchDocumentContents);
    }

    fetchDocumentContents(documentId, flowControl?) {
        return new Promise((resolve, reject) => {
            let self = this;
            if (flowControl) {
                Util.startSpinning();
            }
            return OpalStateManager.fetchEntityList(OpalEntity.DocumentContent, documentId, null, true)
            .then((data: any) => {
                self.documentContentList = data;
                self.documentContentListOptions = Util.orderOptions(data, 'id', 'label');
                ServiceObserver.trigger('DocumentContentService:fetchDocumentContents', {list: self.documentContentList, options: self.documentContentListOptions});
                resolve(data);
            }).catch(error => {      
                Log.error("DocumentContentService:fetchDocumentContents", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                if (flowControl) {
                    Util.stopSpinning();
                }
            });
        });
    }

    saveDocumentContent(data, documentId, flowControl) {
        return new Promise((resolve, reject) => {
            if (flowControl) {
                Util.startSpinning();
            }
            return OpalStateManager.createEntity(OpalEntity.DocumentTokenValue, data, {documentId: documentId})
            .then((data: any) => {
                resolve(data);
            }).catch(error => {      
                Log.error("DocumentContentService:saveDocumentContent", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                if (flowControl) {
                    Util.stopSpinning();
                }
            });
        });
    }
}
