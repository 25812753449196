import {Model} from "@voxfp/opal_ui_common";

export class Download extends Model<Download> {

    documentName?: string;
    documentContents?: any;

    constructor(documentName?: string, documentContents?: any) {
        super(Download);
        this.documentName = documentName;
        this.documentContents = documentContents;
    }
}
