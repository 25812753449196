import * as React from 'react';
import {DocumentService} from '../../services/documentService';
import {Breadcrumb, DefaultButton, Dialog, DialogFooter, Dropdown, IBreadcrumbItem, IDropdownOption, MessageBarType, PrimaryButton, TextField} from "office-ui-fabric-react";
import { TemplateAndTemplateFamily } from '../template/templateAndTemplateFamily';
import { TemplateService } from '../../services/templateService';
import { Toast, Util } from '@voxfp/opal_ui_common';

export interface AppProps {
    title: string;
    history: any;
    location?: any;
}

export interface AppState {
    templateId: number;
    templateFamilyId: number;
    documentName: string;
    hideCancelDialog: boolean;
    docType: string;
    documentOptions: Array<any>;
    documentId: number;
}

export class NewDocument extends React.Component<AppProps, AppState> {

    documentService: DocumentService = new DocumentService();
    templateService: TemplateService = new TemplateService();
    public breadcrumbList: Array<IBreadcrumbItem> = [];
    
    constructor(props, state) {
        super(props, state);
        this.state = this.reset();
    }

    componentDidMount() {
        if (this.props.location.pathname === "/guided-interview-copy-document") {
            this.setState({
                docType: "copy"
            });
        }
        else {
            this.setState({
                docType: "new"
            });
        }
    }

    closeDialog() {
        this.setState({
            hideCancelDialog: true
        });
    }

    okContinueCancel() {
        this.setState(
            this.reset()
        );
        this.props.history.push("/guided-interview");
    }

    createDocument() {
        if (this.state.docType === "new") {
            let data = { "document":
                {
                "name": this.state.documentName,
                "template_revision_id": this.state.templateId
                }
            };
            this.documentService.createDocument(data).then((document: any) => {
                this.props.history.push('/guided-interview/' + document.id); 
            }).catch((error) => {
                Util.showToast(new Toast('Error creating document. ' + error.join(" "), MessageBarType.error));
            });     
        }
        else {
            let data = {
                "document": {
                    "name": this.state.documentName
                }
            };
            this.documentService.copyDocument(this.state.documentId, data).then((document: any) => {
                this.props.history.push('/guided-interview/' + document.id); 
            }).catch((error) => {
                Util.showToast(new Toast('Error creating document. ' + error.join(" "), MessageBarType.error));
            });
        }   
    }

    handleTemplateChange(template) {
        this.setState({ 
            documentId: 0,
            documentOptions: [],
            templateId: template.key, 
            documentName: template.text + " - "
        });
        if (this.state.docType === "copy") {
            this.getDocuments(template.key);
        }
    }

    getDocuments(id) {
        this.documentService.fetchDocumentsFromTemplate(id).then((documents) => {
            let docList = Util.orderOptions(documents, 'id', 'name');
            this.setState({
                documentOptions: docList
            });
            this.setState(prevState => ({
                documentOptions: [ {key: 0, text: "Select a document", disabled: true}, ...prevState.documentOptions]
            }));
        }).catch((error) => {
            Util.showToast(new Toast('Error fetching documents. ' + error.join(" "), MessageBarType.error));
        });
    }

    handleDocumentChange(item) {
        this.setState({
            documentId: item.key,
            documentName: item.text + " - Copy"
        });
    }

    reset() {
        return {
            templateId: 0,
            templateFamilyId: 0,
            documentName: null,
            hideCancelDialog: true,
            docType: '',
            documentOptions: [],
            documentId: 0
        };
    }

    render() {
        
        this.breadcrumbList = [
            {
                text: 'Guided Interviews',
                key: 'GI List',
                onClick: (_ev?: React.MouseEvent<HTMLElement, MouseEvent>, _item?: IBreadcrumbItem) => {
                    window.location.assign("#/guided-interview");
                }
            },
            { 
                text: this.state && this.state.docType === 'new' ? "New Document" : "Copy Document", 
                key: 'f2', 
                isCurrentItem: true,
                onClick: (_ev?: React.MouseEvent<HTMLElement, MouseEvent>, _item?: IBreadcrumbItem) => {
                }
            }
        ];
            
        return (
            <div>
                <h2 className="mt0 mb05">{this.props.title}</h2>
                <Breadcrumb className="breadcrumbs" items={this.breadcrumbList} maxDisplayedItems={5} />
                <div className="mt1">              
                    <div>
                        {this.state && this.state.docType === "new" &&
                            <h3>Select the template you want to create your document from.</h3>
                        }
                        {this.state && this.state.docType === "copy" &&
                            <h3>Select the document you want to copy.</h3>
                        }
                        <div className="ms-Grid-row">                    
                            <div className="ms-Grid-col ms-sm12 ms-xl6 ms-xxl4"> 
                                <TemplateAndTemplateFamily
                                    onChangeTemplateFamily={(id) => { 
                                        this.setState({ 
                                            templateFamilyId: Number(id),
                                            templateId: 0,
                                            documentId: 0,
                                            documentName: '',
                                            documentOptions: []
                                        }); 
                                    }}
                                    onChangeTemplate={(template) => { this.handleTemplateChange(template); }}
                                    selectedTemplateFamily={this.state && this.state.templateFamilyId}
                                    selectedTemplate={this.state && this.state.templateId}
                                />
                                {this.state && this.state.docType === "copy" &&
                                    <Dropdown
                                        disabled={this.state && this.state.templateId < 1 ? true : false}
                                        notifyOnReselect={true}
                                        label= 'Document to Copy'
                                        placeholder="Select a Document"
                                        options={this.state && this.state.documentOptions}
                                        className='mb05'
                                        defaultSelectedKey={0}
                                        onChange={
                                            (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
                                                this.handleDocumentChange(item);
                                            }
                                        }
                                    />
                                }
                                {((this.state && this.state.templateId > 0 && this.state.docType === "new") || (this.state && this.state.documentId > 0 && this.state.docType === "copy")) && 
                                    <div>
                                        <TextField label='New Document Name' value={this.state.documentName}
                                            onChange={(_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, _value?: string) => {
                                                this.setState({ documentName: _value });
                                            }}
                                        />
                                        
                                        <div className="buttons-row mt1 right">
                                            <DefaultButton 
                                                text="Cancel" 
                                                onClick={() => { this.setState({hideCancelDialog: false}); }} 
                                            />
                                            &nbsp;
                                            <PrimaryButton 
                                                text="Create Document" 
                                                disabled={this.state && this.state.documentName === ''}
                                                iconProps={{ iconName: "Save" }}
                                                onClick={() => { this.createDocument(); }}
                                            />
                                         </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <Dialog
                    hidden={this.state && this.state.hideCancelDialog === null ? true : this.state && this.state.hideCancelDialog}
                    onDismiss={this.closeDialog.bind(this)}
                    dialogContentProps={{
                        title: 'Cancel changes',
                        subText: 'This will remove all changes. Are you sure you want to cancel?'
                    }}
                    modalProps={{
                        isBlocking: true,
                        styles: { main: { maxWidth: 450 } }
                    }}
                    >
                    <DialogFooter>
                        <DefaultButton onClick={this.closeDialog.bind(this)} text="No" />
                        <PrimaryButton onClick={this.okContinueCancel.bind(this)} text="Yes" />
                    </DialogFooter>
                </Dialog>
                
            </div>
        );
    }
}

export default NewDocument;
