import * as React from 'react';
import {

    Label,
    Spinner,
    SpinnerSize,
    TextField,
    Dropdown,
    IDropdownOption,
    PrimaryButton
} from "office-ui-fabric-react";


export interface AppProps {
    key?: any;
    item: any;
    onSaveChange?: any;
    acceptAllChanges?: boolean;
    availableTokens?: Array<any>;
    isPieChart: boolean;
}

export interface AppState {
    value: any;
    charts: any;
    availableTokens: Array<any> | { data: Array<any> };
    selectedTokens: Array<any>
    axisX: any;
    dataSource: any;
    dataCharts: Array<Array<any>>;
}

export class DocumentChartItem extends React.Component<AppProps, AppState> {

    constructor(props, state) {
        super(props, state);

        this.state = {
            value: this.props.item.value.title,
            charts: this.props.item.charts,
            availableTokens: this.props.availableTokens,
            selectedTokens: this.props.isPieChart ? this.props.item.value.tokens?.[0] : this.props.item.value.tokens ?? "",
            axisX: this.props.item.value['axis-x'],
            dataSource: this.props.item.value['data-source'] ?? null,
            dataCharts: this.props.item.value['data-charts'] ?? [[null], [null]],
        };
    }

    handleMultiChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        const selectedTokens = [...this.state.selectedTokens];

        if(selectedTokens.includes(option.key)) {
            selectedTokens.splice(selectedTokens.indexOf(option.key), 1);
        } else {
            selectedTokens.push(option.key);
        }

        return this.setState({
            ...this.state, selectedTokens
        }, () => this.handleSave())
    }

    handleChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        return this.setState({
            ...this.state, selectedTokens: [item.key]
        }, () => this.handleSave())
    };

    handleSave = () => {
        const { onSaveChange } = this.props;
        const { axisX, selectedTokens, value, dataSource, dataCharts } = this.state;

        if(!this.props.item.loading) {
            if(dataSource === 'New Data Set' && !!value && !dataCharts.some(item => item.some(value => value === null || value === ''))) {
                return onSaveChange({
                    title: value,
                    dataSource, dataCharts
                }, this.props.item);
            }

            if(dataSource !== 'New Data Set' && !!value && ((Array.isArray(axisX) && !!axisX.length) || (typeof axisX === 'number' && !!axisX))) {
                return onSaveChange({
                    title: value,
                    tokens: selectedTokens,
                    axisX, dataSource
                }, this.props.item);
            }
        }
    }

    handleAddMoreDataColumns = () => {
        const { dataCharts } = this.state;
        const newData = [...dataCharts, new Array(dataCharts[0].length).fill(null)]

        return this.setState({
            ...this.state, dataCharts: newData
        })
    }

    handleAddMoreDataRows = () => {
        const { dataCharts } = this.state;
        const newData = [...dataCharts].map(row => [...row, null])

        return this.setState({
            ...this.state, dataCharts: newData
        })
    }

    render() {
        let iconName = "";
        let errorMessage = null;
        let iconColor = "";

        if (
            (this.props.item.value === this.state.value && this.props.item.isValid) ||
            (this.props.item.validUserInput && !this.props.item.loading) ||
            (this.props.item.isValid && this.props.item.validUserInput === null && !this.props.item.loading)
        ) {
            iconName = "CompletedSolid";
            iconColor = "iconSuccess";
            errorMessage = null;
        }
        else if (this.props.item.validUserInput === false && !this.props.item.loading) {
            iconName = "AlertSolid";
            iconColor = "iconFailed";
            errorMessage = this.props.item.errorMessage;
        }

        if (this.props.item.value !== this.state.value && this.props.item.validUserInput !== false && !this.props.item.loading) {
            iconName = "";
        }

        return <div className="ms-Grid-row mb1">
            <div className="ms-Grid-col ms-sm12 ms-lg4 ms-xxl4">
                <Label>
                    {this.props.item.label}
                </Label>
            </div>
            <div className="ms-Grid-col ms-sm12 ms-lg8 ms-xxl8 mb05">
                <div className="repeat-section">
                    <div className="ms-Grid-row mb1">
                        <div className="ms-Grid-col ms-sm12 ms-lg4 ms-xxl4">
                            <Label>
                                Title
                            </Label>
                        </div>
                        <TextField
                            readOnly={this.props.onSaveChange ? false : true}
                            className={iconColor}
                            value={this.state && this.state.value ? this.state.value : ""}
                            onBlur={() => this.handleSave()}
                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, _value?: string) => {
                                this.setState({
                                    value: event.target["value"]
                                });
                            }}
                            iconProps={{ iconName: iconName }}
                            errorMessage={errorMessage}
                        />
                        {this.props.item.loading &&
                        <div className="inline-spinner">
                            <Spinner size={SpinnerSize.small} />
                        </div>
                        }
                    </div>
                    <div className="ms-Grid-row mb1">
                        <div className="ms-Grid-col ms-sm12 ms-lg4 ms-xxl4">
                            <Label>
                                Data Source
                            </Label>
                        </div>
                        <Dropdown
                            placeholder="Select data source"
                            selectedKey={this.state.dataSource}
                            onChange={(event, option) => this.setState({...this.state, dataSource: option.key}, () => this.handleSave())}
                            options={[...((Array.isArray(this.state.availableTokens) && !!this.state.availableTokens.length) || (!Array.isArray(this.state.availableTokens) && !!this.state.availableTokens.data.length) ? [{key: 'template', text: 'Table from Template'}] : []), {key: 'New Data Set', text: 'New Data Set'}]}
                            errorMessage={errorMessage}
                        />
                    </div>

                    {/* Bar and line chart */ }
                    {!!this.state.dataSource && this.state.dataSource !== 'New Data Set' && !this.props.isPieChart ? <>
                    <div className="ms-Grid-row mb1">
                        <div className="ms-Grid-col ms-sm12 ms-lg4 ms-xxl4">
                            <Label>
                                Axis X
                            </Label>
                        </div>
                        <Dropdown
                            placeholder="Select axis X"
                            selectedKey={this.state.axisX}
                            onChange={(event, option) => this.setState({...this.state, axisX: option.key})}
                            options={Array.isArray(this.state.availableTokens) ? this.state.availableTokens.map( item => ({
                                key: item.id,
                                text: item.name
                            })) : this.state.availableTokens.data.map( item => ({
                                key: item.id,
                                text: item.name
                            }))}
                            errorMessage={errorMessage}
                        />
                    </div>
                    <div className="ms-Grid-row mb1">
                        <div className="ms-Grid-col ms-sm12 ms-lg4 ms-xxl4">
                            <Label>
                                Data tokens
                            </Label>
                        </div>
                        <Dropdown
                            placeholder="Select tokens"
                            selectedKeys={this.state.selectedTokens}
                            onChange={this.handleMultiChange}
                            multiSelect
                            options={Array.isArray(this.state.availableTokens) ? this.state.availableTokens.map( item => ({
                                key: item.id,
                                text: item.name
                            })) : this.state.availableTokens.data.map( item => ({
                                key: item.id,
                                text: item.name
                            }))}
                            errorMessage={errorMessage}
                        />
                    </div>
                    </> : !!this.state.dataSource && !this.props.isPieChart && <>
                        {this.state.dataCharts.map((token, index) => {
                            if(index === 0) {
                                return   <div className="ms-Grid-row mb1">
                                    <div className="ms-Grid-col ms-sm12 ms-lg4 ms-xxl4">
                                        <Label>
                                            Axis X
                                        </Label>
                                    </div>
                                    {token.map((item, rowIndex) =>
                                        <>
                                            {!!rowIndex && <div className="ms-Grid-col ms-sm12 ms-lg4 ms-xxl4" />}
                                            <TextField
                                                className="ms-Grid-row mb1 right"
                                                key={rowIndex}
                                                value={this.state.dataCharts[index][rowIndex] ?? ''}
                                                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, _value?: string) => {
                                                    this.setState({...this.state, dataCharts: [...this.state.dataCharts.slice(0, index), [...this.state.dataCharts[index].slice(0, rowIndex), event.target["value"], ...this.state.dataCharts[index].slice(rowIndex + 1)], ...this.state.dataCharts.slice(index + 1)]});
                                                }}
                                                onBlur={() => this.handleSave()}
                                                placeholder={"Enter value"}
                                                errorMessage={errorMessage}
                                            /></>)}
                                </div>
                            }

                            return <div className="ms-Grid-row mb1">
                                <div className="ms-Grid-col ms-sm12 ms-lg4 ms-xxl4">
                                    <Label>
                                        Data {index}
                                    </Label>
                                </div>
                                {token.map((item, rowIndex) =>
                                    <>
                                        {!!rowIndex && <div className="ms-Grid-col ms-sm12 ms-lg4 ms-xxl4" />}
                                        <TextField
                                            key={rowIndex}
                                            value={this.state.dataCharts[index][rowIndex] ?? ''}
                                            className="ms-Grid-row mb1 right"
                                            onBlur={() => this.handleSave()}
                                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, _value?: string) => {
                                                this.setState({...this.state, dataCharts: [...this.state.dataCharts.slice(0, index), [...this.state.dataCharts[index].slice(0, rowIndex), event.target["value"], ...this.state.dataCharts[index].slice(rowIndex + 1)], ...this.state.dataCharts.slice(index + 1)]});
                                            }}
                                            placeholder={"Enter value"}
                                            errorMessage={errorMessage}
                                        /></>)}
                            </div>})}
                        <div className="right mb1">
                            <PrimaryButton
                                text="Add More Data Columns"
                                onClick={this.handleAddMoreDataColumns}
                            />
                        </div>
                        <div className="right mb1">
                            <PrimaryButton
                                text="Add More Data Rows"
                                onClick={this.handleAddMoreDataRows}
                            />
                        </div>
                    </>
                    }
                    {/* Pie Chart */}
                    {!!this.state.dataSource && this.state.dataSource !== 'New Data Set' && this.props.isPieChart ? <>
                        <div className="ms-Grid-row mb1">
                            <div className="ms-Grid-col ms-sm12 ms-lg4 ms-xxl4">
                                <Label>
                                    Axis X
                                </Label>
                            </div>
                            <Dropdown
                                placeholder="Select axis X"
                                selectedKey={this.state.axisX}
                                onChange={(event, option) => this.setState({...this.state, axisX: option.key}, () => this.handleSave())}
                                options={Array.isArray(this.state.availableTokens) ? this.state.availableTokens.map( item => ({
                                    key: item.id,
                                    text: item.name
                                })) : this.state.availableTokens.data.map( item => ({
                                    key: item.id,
                                    text: item.name
                                }))}
                                errorMessage={errorMessage}
                            />
                        </div>
                        <div className="ms-Grid-row mb1">
                            <div className="ms-Grid-col ms-sm12 ms-lg4 ms-xxl4">
                                <Label>
                                    Data tokens
                                </Label>
                            </div>
                            <Dropdown
                                placeholder="Select token"
                                selectedKey={this.state.selectedTokens}
                                onChange={this.handleChange}
                                options={Array.isArray(this.state.availableTokens) ? this.state.availableTokens.map( item => ({
                                    key: item.id,
                                    text: item.name
                                })) : this.state.availableTokens.data.map( item => ({
                                    key: item.id,
                                    text: item.name
                                }))}
                                errorMessage={errorMessage}
                            />
                        </div>
                    </> : !!this.state.dataSource && this.props.isPieChart && <>
                        {this.state.dataCharts.map((token, index) => {
                            if(index === 0) {
                                return  <div className="ms-Grid-row mb1">
                                    <div className="ms-Grid-col ms-sm12 ms-lg4 ms-xxl4">
                                        <Label>
                                            Axis X
                                        </Label>
                                    </div>
                                    {token.map((item, rowIndex) =>
                                        <>
                                        {!!rowIndex && <div className="ms-Grid-col ms-sm12 ms-lg4 ms-xxl4" />}
                                        <TextField
                                            className="ms-Grid-row mb1 right"
                                            key={rowIndex}
                                            value={this.state.dataCharts[index][rowIndex] ?? ''}
                                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, _value?: string) => {
                                                this.setState({...this.state, dataCharts: [...this.state.dataCharts.slice(0, index), [...this.state.dataCharts[index].slice(0, rowIndex), event.target["value"], ...this.state.dataCharts[index].slice(rowIndex + 1)], ...this.state.dataCharts.slice(index + 1)]});
                                            }}
                                            onBlur={() => this.handleSave()}
                                            placeholder={"Enter value"}
                                            errorMessage={errorMessage}
                                    /></>)}
                                </div>
                            }

                            return <div className="ms-Grid-row mb1">
                                <div className="ms-Grid-col ms-sm12 ms-lg4 ms-xxl4">
                                    <Label>
                                        Data {index}
                                    </Label>
                                </div>
                                {token.map((item, rowIndex) =>
                                    <>
                                        {!!rowIndex && <div className="ms-Grid-col ms-sm12 ms-lg4 ms-xxl4" />}
                                        <TextField
                                            className="ms-Grid-row mb1 right"
                                            key={rowIndex}
                                            value={this.state.dataCharts[index][rowIndex] ?? ''}
                                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, _value?: string) => {
                                                this.setState({...this.state, dataCharts: [...this.state.dataCharts.slice(0, index), [...this.state.dataCharts[index].slice(0, rowIndex), event.target["value"], ...this.state.dataCharts[index].slice(rowIndex + 1)], ...this.state.dataCharts.slice(index + 1)]});
                                            }}
                                            onBlur={() => this.handleSave()}
                                            placeholder={"Enter value"}
                                            errorMessage={errorMessage}
                                        /></>)}
                            </div>})}
                        <div className="right mb1">
                            <PrimaryButton
                                text="Add More Data Rows"
                                onClick={this.handleAddMoreDataRows}
                            />
                        </div>
                    </>}
                </div>
            </div>
        </div>
    }
}

export default DocumentChartItem;
