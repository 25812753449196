import {Model} from "@voxfp/opal_ui_common";

export class BulkJobDocuments extends Model<BulkJobDocuments> {
    
    name?: string;
    author?: string;
    templateName?: string;
    updatedAt?: any;
    workflowState?: any;

    constructor(
        name?: string,
        author?: string,
        templateName?: string,
        updatedAt?: any,
        workflowState?: any
    ) {
        super(BulkJobDocuments);
        this.name = name;
        this.author = author;
        this.templateName = templateName;
        this.updatedAt = updatedAt;
        this.workflowState = workflowState;
    }
}
