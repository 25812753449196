import {Log, Util} from "@voxfp/opal_ui_common";
import {TemplateRevisions} from "../model/templateRevisions";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import {CommonUtils} from "../utils/commonUtils";

export class TemplateRevisionService {

    fetchTemplateRevisions() {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.TemplateRevisions, null, null, true)
            .then((data: Array<TemplateRevisions>) => {
                resolve(data);
            }).catch(error => {      
                Log.error("TemplateRevisionsService:fetchRevisions", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    getTemplateRevision(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntity(OpalEntity.TemplateRevision, id, null, true)
            .then((revision: TemplateRevisions) => {
                resolve(revision);
            }).catch(error => {            
                Log.error("TemplateRevisionsService:getRevision with id=" +  id, error);
                reject(CommonUtils.FormatError(error)); 
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    createTemplateRevision(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.createEntity(OpalEntity.TemplateRevisions, null, id)
            .then((revision: TemplateRevisions) => {
                resolve(revision);
            }).catch(error => {            
                Log.error("TemplateRevisionsService:createRevision with id=" +  id, error);
                reject(CommonUtils.FormatError(error)); 
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    deleteTemplateRevision(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.removeEntity(OpalEntity.TemplateRevision, id)
            .then((feedback) => {
                resolve(feedback);
            }).catch(error => {            
                Log.error("TemplateRevisionsService:deleteRevision with id=" +  id, error);
                reject(CommonUtils.FormatError(error)); 
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    updateTemplateRevisionState(id, status) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.createEntity(OpalEntity.RevisionWorkflow, {"the_action": status}, id)
            .then((feedback) => {
                resolve(feedback);
            }).catch(error => {            
                Log.error("TemplateRevisionsService:updateRevisionState with id=" +  id, error);
                reject(CommonUtils.FormatError(error)); 
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }
}
