import * as React from 'react';
import {Dropdown, IDropdownOption} from "office-ui-fabric-react";
import {TemplateFamilyList} from "./templateFamilyList";
import {OpalStoreService} from "@voxfp/opal_ui_common";
import {Util, Log} from "@voxfp/opal_ui_common";
import {TemplateService} from "../../services/templateService";
import {TemplateFamilyService} from "../../services/templateFamilyService";
import {Template} from "../../model/template";

export interface Props {
    selectedTemplateFamily?: number;
    selectedTemplate?: number;
    formValid?: boolean;
    onChangeTemplateFamily?: any;
    onChangeTemplate: any;
    onChangeTemplateGetDetails?: any;
    showSmartTemplates?: boolean;
    data?: any;
}

export interface State {
    templateFamilys: Array<any>;
    templates: Array<any>;
}

export class TemplateAndTemplateFamily extends React.Component<Props, State> {

    templateFamilyService: TemplateFamilyService = new TemplateFamilyService();
    templateService: TemplateService = new TemplateService();

    componentDidMount() {
        this.templateFamilyService.fetchTemplateFamilies()
            .then(() => {
                this.setState({ templateFamilys: this.templateFamilyService.templateFamilyListOptions });
            }).catch(Log.error);
    }

    populateTemplates(templateFamilyId) {
        Util.startSpinning();
        this.setState({templates: []});
        this.templateService.fetchComposableTemplatesFromFamily(templateFamilyId)
            .then((template: any) => {
                let mappedTemplateList = []; 
                template.forEach((item) => {
                    mappedTemplateList.push({key: item.currentRevision.id, text: item.name});
                });
                mappedTemplateList = mappedTemplateList.sort((a, b) => {
                    a = a.text.toLowerCase();
                    b = b.text.toLowerCase();
                    return (a > b) ? 1 : ((b > a) ? -1 : 0);
                });
                this.setState({ templates: mappedTemplateList });
                this.setState(prevState => ({
                    templates: [ {key: 0, text: "Select a Template", disabled: true}, ...prevState.templates]
                }));
                Util.stopSpinning();
            }).catch(Log.error);
    }

    

    handleTemplateFamilyChange(option) {
        let templateFamilyId = option.key;
        this.populateTemplates(templateFamilyId);
        if (this.props.onChangeTemplateFamily) {
            this.props.onChangeTemplateFamily(templateFamilyId);
        }
    }

    handleTemplateChange(option) {
        if (this.props.showSmartTemplates && option.key) {
            let templatableId = option.key;
            this.props.onChangeTemplateGetDetails(templatableId);
        }
        else if (this.props.onChangeTemplateGetDetails && option.key) {
            let templateId = option.key;
            this.templateService.getTemplate(templateId).then((template: Template) => {
                let author = template["relationships"].owner["data"].name;
                this.props.onChangeTemplateGetDetails(option, author, template.version);
            });
        } 
        else {
            OpalStoreService.setCurrentTemplateId(option.key);
            if (this.props.onChangeTemplate) {
                this.props.onChangeTemplate(option);
            }
        }
    }

    render() {
        return (
            <div>
                <TemplateFamilyList 
                    onChangeTemplateFamily={this.handleTemplateFamilyChange.bind(this)}
                    selectedTemplateFamilyId={this.props && this.props.selectedTemplateFamily} 
                    formValid={this.props && this.props.formValid}
               />
                <Dropdown responsiveMode={2}
                    placeholder='Select a Template'
                    disabled={this.state && this.state.templates ? false : true}
                    notifyOnReselect={true}
                    label= 'Template'
                    id='template'
                    ariaLabel='Template'
                    className='mb05'
                    defaultSelectedKey={0}
                    options={ this.state ? this.state.templates : [] }
                    errorMessage={this.props && this.props.selectedTemplate === 0 && this.props.formValid === false ? "* required" : null} 
                    onChange={
                        (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
                            this.handleTemplateChange(item);
                        }
                    }
               />
            </div>
        );
    }
}
