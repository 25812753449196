import {Log, Util} from "@voxfp/opal_ui_common";
import {TemplateFamily} from "../model/templateFamily";
import {IDropdownOption} from "office-ui-fabric-react";
import {ServiceObserver} from "./serviceObserver";
import OpalStateManager, {OpalEntity} from "../state/stateManager";

export class TemplateFamilyService {

    private _templateFamilyList: Array<TemplateFamily> = [];
    private _templateFamilyListOptions: Array<IDropdownOption> = [];
    private _templateFamily: TemplateFamily;
    private _templateFamilyTemplateListOptions: Array<IDropdownOption> = [];

    constructor () {
        this._templateFamilyList = [];
        this._templateFamilyListOptions = [];
        this._templateFamily = null;
        this._templateFamilyTemplateListOptions = [];

        OpalStateManager.observeList(OpalEntity.TemplateFamily, this.fetchTemplateFamilies);
    }

    fetchTemplateFamilies() {
        Log.debug("TemplateFamilyService:fetchTemplateFamilies");
        Util.startSpinning();
        return OpalStateManager.fetchEntityList(OpalEntity.TemplateFamily, null, null, true)
            .then((data: any) => {
                Log.info("TemplateFamilyService:fetchTemplateFamilies");
                this._templateFamilyList = data;
                this._templateFamilyListOptions = Util.orderOptions(data, 'id', 'name');
                ServiceObserver.trigger('TemplateFamilyService:fetchTemplateFamilies', this.templateFamilyList);
                Util.stopSpinning();
                return data;
            }).catch(error => {
                Log.error("TemplateFamilyService:fetchTemplateFamilies", error);
                Util.stopSpinning();
            });
    }

    saveTemplateFamily(templateFamily) {
        Log.debug("TemplateFamilyService:getTemplateFamily", templateFamily);
        Util.startSpinning();
        return OpalStateManager.createEntity(templateFamily)
        .then((data) => {
            Log.info("TemplateFamilyService:saveTemplateFamily", data);
            Util.stopSpinning();
        }).catch(error => {
            Log.error("TemplateFamilyService:getTemplateFamily " + templateFamily, error);
            Util.stopSpinning();
        });
    }

    get templateFamilyList(): any[] {
        return this._templateFamilyList;
    }

    get templateFamilyListOptions(): any[] {
        return this._templateFamilyListOptions;
    }

    get templateFamily(): any {
        return this._templateFamily;
    }

    get templateFamilyTemplateListOptions(): any[] {
        return this._templateFamilyTemplateListOptions;
    }
}
