import * as React from 'react';
import {SpinButton as SpinButtonFabric} from 'office-ui-fabric-react';

export interface AppProps {
    min: number;
    max: number;
    type: string;
    label?: any;
    value: any;
    onBlur?: any;
    onChange: any;
    readOnly?: boolean
}

export interface AppState {
    value: string;
}

export class SpinButton extends React.Component<AppProps, AppState> {

    componentDidMount() {
        this.setState({
            value: this.props.value
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({ value: this.props.value });
        }
    }

    render() {
        
        let min = Number(this.props.min);
        let max = Number(this.props.max);
        
        if (this.props.type === 'Decimal') {
            
            return (
                <SpinButtonFabric
                    disabled={this.props.readOnly ? this.props.readOnly : false}
                    label={this.props.label}
                    value={this.state && this.state.value ? String(this.state.value) : ""}
                    min={min}
                    max={max}
                    onChange={(e) => {
                        this.setState({
                            value: String(e.target["value"])
                        });
                        this.props.onChange(e.target["value"]);
                    }}
                    onBlur={() => {
                        if (this.props.value !== this.state.value) {
                            this.props.onBlur(this.state.value);
                        }
                    }}
                    onMouseLeave={() => {
                        if (this.props.value !== this.state.value) {
                            this.props.onBlur(this.state.value);
                        }
                    }}
                    onIncrement={ (value) => {
                        let newValue = 0;
                        if (Number(value) < min) {
                            newValue = min;
                        }
                        else {
                            newValue = Math.min(Number(value) + 0.1, max);  
                        }
                        let parsedValue = parseFloat(String(newValue)).toFixed(1);
                        this.setState({
                            value: parsedValue
                        });  
                        this.props.onChange(parsedValue);
                        return parsedValue;
                    } }
                    onDecrement={ (value) => {
                        let newValue = 0;
                        if (Number(value) < min) {
                            newValue = min;
                        }
                        else {
                            newValue = Math.max(Number(value) - 0.1, min);  
                        }
                        let parsedValue = parseFloat(String(newValue)).toFixed(1);
                        this.setState({
                            value: parsedValue
                        });  
                        this.props.onChange(parsedValue);
                        return parsedValue;
                    } }
               />
            );
        } else if (this.props.type === 'Integer') {
            return (
                <SpinButtonFabric
                    disabled={this.props.readOnly ? this.props.readOnly : false}
                    label={this.props.label}
                    value={this.state && this.state.value ? String(this.state.value) : ''}
                    min={min}
                    max={max}
                    onChange={(e) => {
                        this.setState({
                            value: String(e.target["value"])
                        });
                        this.props.onChange(e.target["value"]);
                    }}
                    onBlur={() => {
                        if (this.props.value !== this.state.value) {
                            this.props.onBlur(this.state.value);
                        }
                    }}
                    onMouseLeave={() => {
                        if (this.props.value !== this.state.value) {
                            this.props.onBlur(this.state.value);
                        }
                    }}
                    onIncrement={ (value) => {
                        let newValue = 0;
                        if (Number(value) < min) {
                            newValue = min;
                        }
                        else {
                            newValue = Math.min(Number(value) + 1, max); 
                        } 
                        this.setState({
                            value: String(newValue)
                        });  
                        this.props.onChange(String(newValue));
                        return String(newValue);
                    } }
                    onDecrement={ (value) => {
                        let newValue = 0;
                        if (Number(value) < min) {
                            newValue = min;
                        }
                        else {
                            newValue = Math.max(Number(value) - 1, min); 
                        }     
                        this.setState({
                            value: String(newValue)
                        });   
                        this.props.onChange(String(newValue));                  
                        return String(newValue);
                    } }
               />
            );
        } else if (this.props.type === 'Percentage') {
            return (
                <SpinButtonFabric
                    disabled={this.props.readOnly ? this.props.readOnly : false}
                    label={this.props.label}
                    value={this.state && this.state.value && this.state.value !== '' ? this.state.value : ''}
                    min={min}
                    max={max}
                    onChange={(e) => {
                        this.setState({
                            value: String(e.target["value"])
                        });
                        this.props.onChange(e.target["value"]);
                    }}
                    onBlur={() => {
                        if (this.props.value !== this.state.value) {
                            this.props.onBlur(this.state.value);
                        }
                    }}
                    onMouseLeave={() => {
                        if (this.props.value !== this.state.value) {
                            this.props.onBlur(this.state.value);
                        }
                    }}
                    onIncrement={ (value) => {
                        let min = Number(this.props.min);
                        let max = Number(this.props.max);
                        value =  value.replace('%', '');
                        let percentage = Math.max(Math.min(Number(value) + 1, max), min);
                        this.setState({
                            value: String(percentage)
                        }); 
                        this.props.onChange(String(percentage));
                        return  percentage + '%';
                    } }
                    onDecrement={ (value) => {
                        let min = Number(this.props.min);
                        let max = Number(this.props.max);
                        value = value.replace('%', '');
                        let percentage = Math.max(Math.min(Number(value) - 1, max), min);
                        this.setState({
                            value: String(percentage)
                        }); 
                        this.props.onChange(String(percentage));
                        return  percentage + '%';
                    } }
               />
            );
        }
        return null;
    }
}

export default SpinButton;
