import { Toast, Util } from '@voxfp/opal_ui_common';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import * as React from 'react';
import {DocumentFlowService} from '../../services/documentFlowService';
import DocumentFlowSection from './documentFlowSection';

export interface AppProps {
    match?: any;
    documentId: number;
    documentFlowId: number;
    updateProgress: any;
}

export interface AppState {
    documentSections: Array<any>;
    docId: number;
    noItems: boolean;
    test: Array<any>;
}

export class DocumentFlowForm extends React.Component<AppProps, AppState> {

    documentFlowService: DocumentFlowService = new DocumentFlowService();

    constructor(props, state) {
        super(props, state);
        this.state = this.reset();
    }

    componentDidMount() {
        this.setState(
            this.reset()
        );
        this.getDocumentFlow(this.props.documentFlowId);
    }

    reset() {
        return {
            documentSections: [],
            noItems: false,
            docId: 0,
            test: []
        };
    }

    shouldComponentUpdate(_nextProps, nextState) {
        if (JSON.stringify(this.state) === JSON.stringify(nextState)) {
            return false;
        }
        else {
            return true;
        }
    }

    getDocumentFlow(id) {
        this.documentFlowService.getDocumentFlow(id).then((documentFlow: any) => {
            let documentFlowSectionsOrdered = documentFlow.documentFlowSections;
            documentFlowSectionsOrdered.sort((a, b) => Number(a.sequence) - Number(b.sequence));
            this.setState({
                documentSections: documentFlowSectionsOrdered,
                noItems: false
            });
        }).catch((error) => {
            Util.showToast(new Toast('Error fetching items. ' + error.join(" "), MessageBarType.error));
            this.setState({
                noItems: true
            });
        });
    }

    handleProgress(e) {
        this.props.updateProgress(e);
    }

    render() {
        
        let documentSection = null;
        let documentFlowSections = this.state && this.state.documentSections;
        if (documentFlowSections.length > 0) {
            documentSection = documentFlowSections.map(
            (item, i) => {
                return (
                    <DocumentFlowSection documentFlowSectionId={item.id} sequence={item.sequence} documentId={this.props.documentId} key={i} handleProgressUpdate={(e) => { this.handleProgress(e); }} />
                );
            });
        }
            

        return (
            <div>
                {documentSection}
                {this.state && this.state.noItems &&
                    <MessageBar>There are no items.</MessageBar>
                }
            </div>
        );
    }
}

export default DocumentFlowForm;
