import * as React from 'react';
import { DefaultButton, Label, PrimaryButton, Spinner, SpinnerSize } from "office-ui-fabric-react";
import { DocumentFlowService } from '../../services/documentFlowService';
import DocumentItem from './documentItem';
import { DocumentContentService } from '../../services/documentContentService';

export interface AppProps {
    item: any;
    onSaveChange: any;
    docContent: any;
    documentId: number;
}

export interface AppState {
    sectionName: string;
    sectionDescription: string;
    items: Array<any>;
    itemsModel: Array<any>;
    dataModel: Array<any>;
    saveModel: Array<any>;
    docContent: Array<any>;
    docModel: Array<any>;
    repeatFormShow: boolean;
    enableSave: boolean;
    checking: boolean;
}

export class DocumentRepeatingItem extends React.Component<AppProps, AppState> {

    documentFlowService: DocumentFlowService = new DocumentFlowService();
    documentContentService: DocumentContentService = new DocumentContentService();

    constructor(props, state) {
        super(props, state);
        this.state = {
            sectionName: null,
            sectionDescription: null,
            items: [],
            itemsModel: [],
            dataModel: [],
            saveModel: [],
            docContent: [],
            docModel: [],
            repeatFormShow: false,
            enableSave: false,
            checking: false
        };
    }

    componentDidMount() {
        this.getRepeat();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.docContent !== this.props.docContent) {
            this.getRepeat();
        }
    }

    getRepeat() {
        let repeatItems = this.props.item.attributes.items;
        let docModel = this.props.item.children;
        let docContent = this.props.docContent;

        let docModelIndex = docModel.map(docModelItem => docModelItem.label);

        let items = repeatItems.filter((repeatItem) => {
            return docModelIndex.indexOf(repeatItem.attributes.name) !== -1;
        });

        let itemsWithModel = items.map(item => ({
            ...docModel.find((docModelItem) => (item.attributes.name === docModelItem.label) && docModelItem),
            ...item
        }));

        let contentItems = [];

        if (docContent && docContent.length > 0) {

            docContent.map((contentItem) => {
                let elements = contentItem.elements.filter((element) => {
                    return docModelIndex.indexOf(element.attributes.label) !== -1;
                });

                let elementsWithFlowItemDetail = elements.map(
                    element => Object.assign({}, element, {
                        documentFlowItem: itemsWithModel
                            .filter(flowItem => flowItem.label === element.attributes.label)
                            .map(item => item)
                    })
                );

                contentItems.push(
                    {
                        "sequence": contentItem.sequence,
                        "elements": elementsWithFlowItemDetail
                    }
                );

            });

        }

        let saveModel = [];

        itemsWithModel.map((item) => {
            saveModel.push({"content_control": item.label});
        });

        itemsWithModel.sort((a, b) => Number(a.sequence) - Number(b.sequence));

        this.setState({
            sectionName: this.props.item.label,
            sectionDescription: this.props.item.description,
            items: itemsWithModel,
            itemsModel: itemsWithModel,
            docContent: contentItems,
            docModel: docModel,
            saveModel: saveModel,
            repeatFormShow: false,
            enableSave: false,
            checking: false
        });
    }

    createSaveModel(value, contentControl) {
        let saveModel = this.state.saveModel;
        let objIndex = saveModel.findIndex((item => item["content_control"] === contentControl.label));
        saveModel[objIndex]["value"] = value;
        const count = saveModel.filter((obj) => obj.value !== undefined).length;

        if (count === saveModel.length && value !== "") {
            this.setState({
                enableSave: true
            });
        }
        else {
            this.setState({
                enableSave: false
            });
        }

        this.setState({
            saveModel: saveModel
        });
    }

    saveDocumentContent() {
        this.setState({
            checking: true
        });
        let data = {
            document: {
                content_control_data: [
                    {
                        "content_control": this.props.item.label,
                        "repeating-content": [

                            this.state.saveModel

                        ]
                    }
                ]
            }
        };

        this.documentContentService.saveDocumentContent(data, this.props.documentId, false).then(() => {
            this.props.onSaveChange({label: this.props.item.label}, null, true);
        }).catch((error) => {
            this.updateContentValidState(this.state.items, false, error);
        });

    }

    updateContentValidState(items, isValid, errors?) {
        if (errors) {
            errors.map(error => {
                let errorItem = error.split(", ")[1];
                let objIndex = items.findIndex((item => item.label === errorItem));
                items[objIndex]["validUserInput"] = isValid;
                items[objIndex]["errorMessage"] = error ? error : "";
            });
        }

        this.setState({
            items: items,
            checking: false
        });
    }

    reset() {
        let items = this.state.items;
        let saveModel = this.state.saveModel;
        items.map((item) => {
            delete item["validUserInput"];
            delete item["errorMessage"];
        });
        saveModel.map((item) => {
            delete item["value"];
        });
        this.setState({
            repeatFormShow: false,
            enableSave: false,
            items: items,
            saveModel: saveModel,
            checking: false
        });
    }

    render() {
        let showForm = this.state && this.state.repeatFormShow;
        let repeatItem;
        if (this.state && this.state.docContent.length > 0) {
            repeatItem = <div>
                {this.state && this.state.docContent.map(
                    (sequence, _i) => {
                        return (
                            <div key={_i} className="repeat-section">
                                {sequence.elements.map(
                                    (item, i) => {
                                        let itemObject = {
                                            id: item.id,
                                            smartRule: item.documentFlowItem[0].attributes["smart_token"]["smart_rule"],
                                            label: item.attributes.label,
                                            description: item.attributes["friendly-name"],
                                            value: item.attributes.content.value,
                                            isValid: item.documentFlowItem[0]["is_valid"],
                                            flowControl: item.documentFlowItem[0]["flow_control"],
                                            validUserInput: this.props.item.validUserInput !== undefined ? this.props.item.validUserInput : null,
                                            loading: item.documentFlowItem[0].loading !== undefined ? item.documentFlowItem[0].loading : false,
                                            errorMessage: item.documentFlowItem[0].errorMessage !== undefined ? item.documentFlowItem[0].errorMessage : ""
                                        };
                                        return (
                                            <DocumentItem key={i} item={itemObject} />
                                        );
                                    }
                                )}
                            </div>
                        );
                    }
                )}
            </div>;
        }
        else {
            showForm = true;
        }

        let repeatTemplate = <div className="repeat-section">
            {this.state && this.state.items.map(
                (item, i) => {
                    let itemObject = {
                        id: item.attributes["smart_token"]["content_control_revision"].id,
                        smartRule: item.attributes["smart_token"]["smart_rule"],
                        label: item.attributes.name,
                        description: item.attributes.description,
                        flowControl: item["flow_control"],
                        validUserInput: item.validUserInput !== undefined ? item.validUserInput : null,
                        loading: this.state.checking !== undefined ? this.state.checking : false,
                        errorMessage: item.errorMessage !== undefined ? item.errorMessage : ""
                    };
                    return (
                        <DocumentItem key={i} item={itemObject} acceptAllChanges={true} onSaveChange={(data, item) => { this.createSaveModel(data, item); }} />
                    );


                }
            )}
        </div>;

        return (
            <div className="ms-Grid-row mb1">
                <div className="ms-Grid-col ms-sm12 ms-lg4 ms-xxl4">
                    <Label>
                        {this.props.item.attributes.name}
                    </Label>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-lg8 ms-xxl8 mb05">
                    {repeatItem}
                    {this.state && showForm && repeatTemplate}
                    {this.state && !showForm &&
                    <div className="right">
                        <PrimaryButton
                            text="Add"
                            iconProps={{ iconName: "Add" }}
                            onClick={() => { this.setState({ repeatFormShow: true}); }}
                        />
                    </div>
                    }
                    {this.state && showForm &&
                    <div className="right">
                        {this.state && this.state.docContent.length > 0 &&
                        <DefaultButton
                            text="Cancel"
                            disabled={this.state && this.state.checking}
                            onClick={() => { this.reset(); }}
                        />
                        }
                        &nbsp;
                        {this.state && this.state.checking ?
                            <PrimaryButton>
                                <Spinner size={SpinnerSize.small}  />
                                <Label className="ml05" style={{color: "white"}}>Checking...</Label>
                            </PrimaryButton>
                            :
                            <PrimaryButton
                                text={"Done"}
                                disabled={this.state && !this.state.enableSave}
                                iconProps={{ iconName: "CheckMark" }}
                                onClick={() => { this.saveDocumentContent(); }}
                            />
                        }
                    </div>
                    }
                </div>
            </div>
        );
    }
}

export default DocumentRepeatingItem;
