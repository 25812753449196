export class Utils {
    static formatDate(date) {
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        date = new Date(date);
        let month = months[date.getMonth()];
        let minutes = date.getMinutes();
        minutes = (minutes < 10 ? '0' : '') + minutes;
        let hours = date.getHours() % 12;
        hours = (hours === 0 ? 12 : hours);
        let am_pm = (date.getHours() < 12 ? 'AM' : 'PM');
        return month + ' ' + date.getDate() + ' ' + date.getFullYear() + ' ' + hours + ':' + minutes + ' ' + am_pm;
    }


    static toUrlParams(data) {
        let params = '';
        // tslint:disable-next-line: forin
        for (let property in data) {
            params += (params === '' ? '' : '&') + property + '=' + data[property];
        }
        return params;
    }
}
