import * as React from 'react';
import {Dialog, DialogFooter, PrimaryButton, ShimmeredDetailsList, SelectionMode, IColumn, MessageBar} from 'office-ui-fabric-react';
import {DetailsListUtils} from "@voxfp/opal_ui_common";
import {SmartTokenService} from "../../services/smartTokenService";
import {Log} from "@voxfp/opal_ui_common";
import { SmartToken } from '../../model/smartToken';

export interface AppProps {
    show: boolean;
    templateID: number;
    onClose: any;
}

export interface AppState {
    data: Array<SmartToken>;
    isLoading: boolean;
    templateID: number;
    columns:  Array<IColumn>;
    showEmptyMessage: boolean;
}

export class TemplateTokens extends React.Component<AppProps, AppState>  {

    smartTokenService: SmartTokenService = new SmartTokenService();

    constructor(props, state) {
        super(props, state);
        this.state = this.reset();

        this.onClosePanel = this.onClosePanel.bind(this);
        
    }

    reset() {
        return ({
            data: [], 
            isLoading: false,
            templateID: null,
            columns: [],
            showEmptyMessage: false
        });
    }
    
    componentDidMount() {
        this.load(this.props.templateID);
        this.setColumns();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.templateID !== this.props.templateID && this.props.templateID !== null) {
            this.load(this.props.templateID);
            this.setColumns();
        }
    }
    
    load(templateID) {
        let self = this;
        self.setState({
            data: [],
            isLoading: true
         });
        if (templateID) {   
            this.smartTokenService.fetchSmartTokens(templateID).then((data) => {
                if (data !== undefined) {
                    let results = this.smartTokenService.tokenList;
                    DetailsListUtils.sortItems(results, "label", false);
                    if (results.length > 0) {                    
                        self.setState({
                            data: results, 
                            isLoading: false,
                            showEmptyMessage: false
                        });
                    }
                    else {
                        self.setState({
                            data: [],
                            isLoading: false,
                            showEmptyMessage: true
                        });
                    }
                } 
                else {
                    self.setState({
                        data: [],
                        isLoading: false,
                        showEmptyMessage: true
                    });
                }          
            }).catch(Log.error);      
        }        
    }
    
    onClosePanel() {
        this.setState(this.reset());
        this.props.onClose();
    }

    setColumns() {
        this.setState({
            columns: [
                {
                    key: 'label',
                    fieldName: 'label',
                    name: 'Label',
                    onRender: DetailsListUtils.onColumnRender.bind(this),
                    minWidth: 200,
                    maxWidth: 250,
                    onColumnClick: (_event: React.MouseEvent<HTMLElement>, column: IColumn) => {
                        DetailsListUtils.onColumnHeaderClick(column as any, this, 'data', 'columns');
                    }
                } as IColumn,
                {
                    key: 'friendly_name',
                    fieldName: 'friendlyName',
                    name: 'Friendly Name',
                    onRender: DetailsListUtils.onColumnRender.bind(this),
                    minWidth: 200,
                    maxWidth: 250,
                    onColumnClick: (_event: React.MouseEvent<HTMLElement>, column: IColumn) => {
                        DetailsListUtils.onColumnHeaderClick(column as any, this, 'data', 'columns');
                    }
                } as IColumn
            ]
        });
    }
    
    
    render() {
        return (
            <Dialog 
                hidden={ !this.props.show || this.state.isLoading } 
                onDismiss={ this.onClosePanel }   
                minWidth={320}             
                maxWidth={500}
                dialogContentProps={{
                    title: 'Template Tokens'
                }}
            >
                {this.state && !this.state.showEmptyMessage &&
                    <ShimmeredDetailsList
                        items={this.state && this.state.data}
                        columns={this.state && this.state.columns}
                        selectionMode={SelectionMode.none}
                        isHeaderVisible={ true }
                        shimmerLines={5}                    
                        enableShimmer={this.state && this.state.isLoading}
                   />     
                }        
                {this.state && this.state.showEmptyMessage && !this.state.isLoading &&
                    <MessageBar>No items to show</MessageBar>
                }
                <DialogFooter>
                    <PrimaryButton onClick={this.onClosePanel} text="Close" />
                </DialogFooter>
            </Dialog>
        );
    }
}

export default TemplateTokens;
