import {Log, Util} from "@voxfp/opal_ui_common";
import {CommonUtils} from "../utils/commonUtils";
import OpalStateManager, {OpalEntity} from "../state/stateManager";

export class WorkflowEventService {

    setWorkflowEvent(id, action) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.createEntity(OpalEntity.WorkflowEvent, {"the_action": action}, id)
            .then((data) => {
                resolve(data);
            }).catch(error => {            
                Log.error("WorkflowEventService:setWorkflowEvent", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }
    
}
