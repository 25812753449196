import {Model} from "@voxfp/opal_ui_common";

export class BulkJobErrors extends Model<BulkJobErrors> {
    
    message?: string;
    messageSequenceNumber?: any;

    constructor(
        message?: string,
        messageSequenceNumber?: number
    ) {
        super(BulkJobErrors);
        this.message = message;
        this.messageSequenceNumber = messageSequenceNumber === undefined ? 0 : messageSequenceNumber;
    }
}
