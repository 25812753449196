import * as React from 'react';
import {Dropdown, IDropdownOption} from "office-ui-fabric-react";
import {Log} from "@voxfp/opal_ui_common";
import {TemplateFamilyService} from "../../services/templateFamilyService";

export interface Props {
    selectedTemplateFamily?: string;
    selectedTemplateFamilyId?: number;
    formValid?: boolean;
    onChangeTemplateFamily: any;
}

export interface State {
    selectedTemplateFamily: string;
    templateFamilies: Array<any>;
}

export class TemplateFamilyList extends React.Component<Props, State> {

    templateFamilyService: TemplateFamilyService = new TemplateFamilyService();

    componentDidMount() {
        this.templateFamilyService.fetchTemplateFamilies()
            .then(() => {
                this.setState({ templateFamilies: this.templateFamilyService.templateFamilyListOptions });
                this.setState(prevState => ({
                    templateFamilies: [ {key: 0, text: "Select a Template Family", disabled: true}, ...prevState.templateFamilies]
                }));
            }).catch(Log.error);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedTemplateFamily !== this.props.selectedTemplateFamily) {
            this.setState({
                selectedTemplateFamily: this.props.selectedTemplateFamily
            });
        }
    }

    handleTemplateFamilyChange(option) {
        this.setState({
           selectedTemplateFamily: option.key
        });
        if (this.props.onChangeTemplateFamily) {
            this.props.onChangeTemplateFamily(option);
        }
    }

    render() {
        return (
            <Dropdown responsiveMode={2}
                notifyOnReselect={true}
                defaultSelectedKey={(this.state && this.state.selectedTemplateFamily) || 0}
                placeholder='Select a Template Family'
                label='Template Family'
                id='TemplateFamily'
                ariaLabel='Template Family'
                className='mb05'
                errorMessage={this.props && this.props.selectedTemplateFamilyId === 0 && this.props.formValid === false ? "* required" : null} 
                options={ this.state ? this.state.templateFamilies : [] }
                onChange={
                    (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
                        this.handleTemplateFamilyChange(item);
                    }
                }
           />
        );
    }
}
