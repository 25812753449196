import * as React from 'react';
import {Dialog, DialogFooter, PrimaryButton, ShimmeredDetailsList, SelectionMode, MessageBar, MessageBarType, IColumn} from 'office-ui-fabric-react';
import {Util, Toast, DetailsListUtils} from "@voxfp/opal_ui_common";
import {BulkJobsService} from "../../services/bulkJobsService";

export interface AppProps {
    show: boolean;
    bulkJobId: number;
    onClose: any;
}

export interface AppState {
    dialogListItems: Array<any>;
    isLoading: boolean;
    bulkJobId: number;
    columns:  Array<IColumn>;
    showEmptyMessage: boolean;
}

export class BulkJobErrors extends React.Component<AppProps, AppState>  {
    
    bulkJobsService: BulkJobsService = new BulkJobsService();


    constructor(props, state) {
        super(props, state);
        this.state = this.reset();

        this.onClosePanel = this.onClosePanel.bind(this);
        
    }

    reset() {
        return ({
            dialogListItems: [], 
            isLoading: false,
            bulkJobId: null,
            columns: [],
            showEmptyMessage: false
        });
    }
    
    componentDidMount() {
        this.load(this.props.bulkJobId);
        this.setColumns();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.bulkJobId !== this.props.bulkJobId && this.props.bulkJobId !== null) {
            this.load(this.props.bulkJobId);
            this.setColumns();
        }
    }
    
    load(bulkJobId) {
        this.setState({
            dialogListItems: [],
            isLoading: true
         });
        if (bulkJobId) {   
            this.bulkJobsService.fetchBulkJobErrors(bulkJobId).then((items: any) => {
                this.setState({
                    dialogListItems: items,
                    isLoading: false,
                    showEmptyMessage: false
                });        
            }).catch((error) => {
                Util.showToast(new Toast('Error fetching items. ' + error.join(" "), MessageBarType.error));
                this.setState({
                    dialogListItems: [],
                    isLoading: false,
                    showEmptyMessage: true
                });
            });      
        }        
    }
    
    onClosePanel() {
        this.setState(this.reset());
        this.props.onClose();
    }

    setColumns() {
        this.setState({
            columns: [
                {
                    key: 'msgSequenceNumber',
                    fieldName: 'messageSequenceNumber',
                    name: 'Sequence Number',
                    onRender: DetailsListUtils.onColumnRender.bind(this),
                    minWidth: 100,
                    maxWidth: 150,
                    onColumnClick: (_event: React.MouseEvent<HTMLElement>, column: IColumn) => {
                        DetailsListUtils.onColumnHeaderClick(column as any, this, 'data', 'columns');
                    }
                } as IColumn,
                {
                    key: 'message',
                    fieldName: 'message',
                    name: 'Message',
                    onRender: DetailsListUtils.onColumnRender.bind(this),
                    minWidth: 200,
                    maxWidth: 250,
                    onColumnClick: (_event: React.MouseEvent<HTMLElement>, column: IColumn) => {
                        DetailsListUtils.onColumnHeaderClick(column as any, this, 'data', 'columns');
                    }
                } as IColumn                
            ]
        });
    }
    
    
    render() {
        return (
            <Dialog 
                hidden={ !this.props.show || this.state.isLoading } 
                onDismiss={ this.onClosePanel }   
                minWidth={640}   
                dialogContentProps={{
                    title: 'Errors'
                }}
            >
                {this.state && !this.state.showEmptyMessage &&
                    <ShimmeredDetailsList
                        items={this.state && this.state.dialogListItems}
                        columns={this.state && this.state.columns}
                        selectionMode={SelectionMode.none}
                        isHeaderVisible={ true }
                        shimmerLines={5}                    
                        enableShimmer={this.state && this.state.isLoading}
                   />  
                }           
                {this.state && this.state.showEmptyMessage && !this.state.isLoading &&
                    <MessageBar>No items to show</MessageBar>
                }
                <DialogFooter>
                    <PrimaryButton onClick={this.onClosePanel} text="Close" />
                </DialogFooter>
            </Dialog>
        );
    }
}

export default BulkJobErrors;
