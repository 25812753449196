import {Model} from "@voxfp/opal_ui_common";
import {CurrentRevision} from "./currentRevision";
import {Author} from "./author";

export class Template extends Model<Template> {

    name: string;
    description: string;
    version?: string;
    templateFamilyId?: number;
    templateFamilyName?: string;
    currentRevision?: CurrentRevision;
    author?: Author;

    constructor(name?: string, description?: string, version?: string, templateFamilyId?: number, templateFamilyName?: string, currentRevision?: CurrentRevision, author?: Author) {
        super(Template);
        this.name = name;
        this.description = description;
        this.version = version;
        this.templateFamilyId = templateFamilyId;
        this.templateFamilyName = templateFamilyName;
        this.currentRevision = currentRevision;
        this.author = author;
    }
}
