import {EntityApi, HttpApi} from "@voxfp/opal_ui_common";
import {Template} from "../model/template";
import {TemplateFamily} from "../model/templateFamily";
import { DocumentModel } from "../model/document";
import { BulkJob } from "../model/bulkJob";
import { BulkJobErrors } from "../model/bulkJobErrors";
import { BulkJobRequests } from "../model/bulkJobRequests";
import { BulkJobDocuments } from "../model/bulkJobDocuments";
import { BulkJobRequestItems } from "../model/bulkJobRequestItems";
import { SmartToken } from "../model/smartToken";
import { DocumentContent } from "../model/documentContent";
import { TemplateRevisions } from "../model/templateRevisions";
import { Download } from "../model/downloads";
import { DocumentFlow } from "../model/documentFlow";
import { DocumentFlowSection } from "../model/documentFlowSection";


export class ApiArguments {
    templateId?: number;
    templateFamilyId?: number;
    type?: string;
    documentId?: number;
}

export class BulkJobsApi extends EntityApi<BulkJob> {
    constructor() {
        super({
            url: '/bulk_jobs',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new BulkJob()
        });
    }
}

export class BulkJobCreateApi extends EntityApi<BulkJob> {
    constructor() {
        super({
            url: '/bulk_jobs/queue',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new BulkJob()
        });
    }
}

export class BulkJobRequestsApi extends EntityApi<BulkJobRequests> {
    constructor(args) {
        super({
            url: '/bulk_jobs/' + args + '/bulk_job_requests',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new BulkJobRequests()
        });
    }
}

export class BulkJobDocumentsApi extends EntityApi<BulkJobDocuments> {
    constructor(args) {
        super({
            url: '/bulk_jobs/' + args + '/documents',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new BulkJobDocuments()
        });
    }
}

export class BulkJobRequestItemsApi extends EntityApi<BulkJobRequestItems> {
    constructor(args) {
        super({
            url: '/bulk_job_requests/' + args + '/bulk_job_request_items',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new BulkJobRequestItems()
        });
    }
}

export class BulkJobRequestItemDetailsApi extends EntityApi<BulkJobRequestItems> {
    constructor() {
        super({
            url: '/bulk_job_request_items',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new BulkJobRequestItems()
        });
    }
}

export class BulkJobErrorsApi extends EntityApi<BulkJobErrors> {
    constructor(id) {
        super({
            url: '/bulk_job_request_item/' + id + '/bulk_job_request_messages',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new BulkJobErrors()
        });
    }
}

export class TemplateRevisionDataApi extends EntityApi<any> {
    constructor(args) {
        super({
            url: '/template_revisions/' + args + '/template_data_model',
            isJSON: true,
            dataIsQueryParams: false
        });
    }
}

export class DocumentsApi extends EntityApi<DocumentModel> {
    constructor() {
        super({
            url: '/documents',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new DocumentModel()
        });
    }
}

export class CopyDocumentApi extends EntityApi<DocumentModel> {
    constructor(args) {
        super({
            url: '/documents?source_document_id=' + args,
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new DocumentModel()
        });
    }
}

export class DocumentDataModelApi extends EntityApi<any> {
    constructor(args) {
        super({
            url: '/documents/' + args + '/document_data_model',
            isJSON: true,
            dataIsQueryParams: false
        });
    }
}

export class DocumentSearchApi extends EntityApi<DocumentModel> {
    constructor(args: ApiArguments) {
        console.log(args)
        super({
            url: '/documents?' + args,
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new DocumentModel()
        });
    }
}

export class DocumentAvailableTokensApi extends EntityApi<any> {
    constructor(args: ApiArguments) {
        super({url: '/guided_interviews/charts/available_tokens?document_id=' + args, isJSON: true, dataIsQueryParams: false});
    }
}

export class DocumentTokenValueApi extends EntityApi<any> {
    constructor(args: ApiArguments) {
        super({url: '/documents/' + args.documentId + '/document_contents?ooxml=y', isJSON: true, dataIsQueryParams: false});
    }
}

export class DocumentWorkflowEventApi extends EntityApi<any> {
    constructor(args) {
        super({
            url: '/documents/' + args + '/events'
        });
    }
}

export class TemplateSearchApi extends EntityApi<Template> {
    constructor(args: ApiArguments) {
        super({
            url: '/templates?' + args,
            modelClass: new Template()
        });
    }
}

export class TemplateFamilyApi extends EntityApi<TemplateFamily> {
    constructor() {
        super({url: '/template_families?group_view=true', isJSON: true, dataIsQueryParams: false, modelClass: new TemplateFamily()});
    }
}

export class TemplateFamilyTemplatesApi extends EntityApi<Template> {
    constructor(args: ApiArguments) {
        super({
            url: '/template_families/' + args.templateFamilyId + '/templates',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new Template()
        });
    }
}

export class ComposableTemplateFamilyTemplatesApi extends EntityApi<Template> {
    constructor(args: ApiArguments) {
        super({
            url: '/template_families/' + args.templateFamilyId + '/templates?compose=true',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new Template()
        });
    }
}

export class TemplateDocumentsApi extends EntityApi<DocumentModel> {
    constructor(args: ApiArguments) {
        super({url: '/template_revisions/' + args.templateId + '/documents',
        isJSON: true,
        dataIsQueryParams: false,
        modelClass: new DocumentModel()});
    }
}

export class TemplateTokenApi extends EntityApi<SmartToken> {
    constructor(args: ApiArguments) {
        super({
            url: '/templates/' + args.templateId + '/smart_tokens',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new SmartToken()
        });
    }
}

export class TemplateRevisionsApi extends EntityApi<TemplateRevisions> {
    constructor(args) {
        super({
            url: '/templates/' + args + '/template_revisions',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new TemplateRevisions()
        });
    }
}

export class TemplateRevisionApi extends EntityApi<TemplateRevisions> {
    constructor(args) {
        super({
            url: '/template_revisions/' + args,
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new TemplateRevisions()
        });
    }
}

export class RevisionWorkflowApi extends EntityApi<TemplateRevisions> {
    constructor(args) {
        super({
            url: '/template_revisions/' + args + '/events',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new TemplateRevisions()
        });
    }
}

export class DocumentContentApi extends EntityApi<DocumentContent> {
    constructor(args) {
        super({
            url: '/documents/' + args + '/document_contents',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new DocumentContent()
        });
    }
}

export class WorkflowEventApi extends EntityApi<any> {
    constructor(args) {
        super({
            url: '/template_revisions/' + args + '/events',
            isJSON: true,
            dataIsQueryParams: false
        });
    }
}

export class DownloadApi extends EntityApi<any> {
    constructor() {
        super({url: '/downloads'});
    }
}

export class FileDownloadApi extends EntityApi<Download> {
    constructor(args) {
        super({
            url: '/' + args.type + '/' + args.id + '/download?filetype=' + args.fileType,
            modelClass: new Download()
        });
    }
}

export class HeaderDownloadApi extends EntityApi<any> {
    constructor(args) {
        super({
            url: '/template_revisions/' + args.id + '/template_headers.' + args.fileType,
        });
    }
}

export class DocumentDataDownloadApi extends EntityApi<any> {
    constructor(args) {
        super({
            url: '/documents/' + args.id + '/document_data.' + args.fileType,
        });
    }
}

export class DocumentFlowApi extends EntityApi<DocumentFlow> {
    constructor() {
        super({
            url: '/document_flows',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new DocumentFlow()
        });
    }
}

export class DocumentFlowSectionApi extends EntityApi<DocumentFlowSection> {
    constructor() {
        super({
            url: '/document_flow_sections',
            isJSON: true,
            dataIsQueryParams: false,
            modelClass: new DocumentFlowSection()
        });
    }
}

export class LoginApi {
    public static login = new HttpApi().login;
    public static checkLogin = new HttpApi().checkLogin;
}
