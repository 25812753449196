import {Model} from "@voxfp/opal_ui_common";
import {Relationships} from "./relationships";

export class TemplateFamily extends Model<TemplateFamily> {

    name: string;
    description: string;
    relationships: Relationships;
    templatesCount: any;
    templateCount: any;
    documentsCount: any;
    documentCount: any;

    constructor(name?: string, description?: string, relationships?: Relationships, templatesCount?: any, templateCount?: any, documentsCount?: number, documentCount?: number) {
        super(TemplateFamily);
        this.name = name;
        this.description = description;
        this.relationships = relationships;
        this.templateCount = templateCount === undefined ? "0" : templateCount;
        this.templatesCount = templatesCount === undefined ? "0" : templatesCount;
        this.documentsCount = documentsCount === undefined ? "0" : documentsCount;
        this.documentCount = documentCount === undefined ? "0" : documentCount;
        this.documentCount = documentCount;
    }
}
