import * as React from 'react';
import {TemplateFamilyService} from '../../services/templateFamilyService';
import {IDropdownOption, SearchBox, MessageBar, Dropdown, PrimaryButton, DefaultButton, Label, MessageBarType, IContextualMenuItem} from 'office-ui-fabric-react';
import OpalStateManager, {OpalEntity} from "../../state/stateManager";
import {OpalTable, Log, Util, Toast} from "@voxfp/opal_ui_common";
import {Utils} from "../../services/utils";
import {DownloadService} from "../../services/downloadService";
import {DocumentTokens} from '../tokens/DocumentTokens';

export interface AppProps {
    path: string;
    mode: string;
    history: any;
    permissions: any;
}

export interface AppState {
    listItems: Array<any>;
    columns: Array<any>;
    isDataLoaded: boolean;
    displayNoItems: boolean;
    name_contains: any;
    document_id: any;
    template_id: any;
    template_family_id: any;
    templateFamilies: Array<any>;
    token_value: any;
    token_label: any;
    showDocumentTokens: boolean;
    tokenDocumentID: number;
}

export class DocumentSearch extends React.Component<AppProps, AppState> {

    templateFamilyService: TemplateFamilyService = new TemplateFamilyService();
    downloadService: DownloadService = new DownloadService();

    constructor(props, state) {
        super(props, state);
        this.state = this.reset();

        this.closeDocumentTokens = this.closeDocumentTokens.bind(this);
    }

    reset() {
        return ({
            name_contains: '',
            template_id: '',
            document_id: '',
            template_family_id: 0,
            token_label: '',
            token_value: '',
            templateFamilies: [],
            listItems: [],
            columns: [],
            isDataLoaded: false,
            displayNoItems: false,
            showDocumentTokens: false,
            tokenDocumentID: null
        });
    }

    componentDidMount() {
        this.setState({
            listItems: [],
            columns: [],
            isDataLoaded: false
        });
        this.fetchTemplateFamilies();
    }

    fetchListItems(data) {
        let items = [];
        data = Utils.toUrlParams(data);
        Util.startSpinning();
        OpalStateManager.fetchEntityList(OpalEntity.DocumentSearch, data, null, true).then((documents: any) => {
            Log.info('documents', documents);
            if (documents.length > 0) {
                documents.forEach(document => {
                    items.push(document);
                    if (items.length === documents.length) {
                        
                        this.setState({
                            listItems: items,
                            isDataLoaded: true,
                            displayNoItems: false
                        });
                        this.setColumns();                    
                    }                
                });
            }
            else {
                this.setState({
                    listItems: [],
                    isDataLoaded: true,
                    displayNoItems: true
                });
            }
            Util.stopSpinning();           
        }).catch(error => {
            Util.stopSpinning();
            Util.showToast(new Toast('Error fetching documents.', MessageBarType.error));
            Log.error('Error fetching documents', error);
        });
    }

    openTokenDocumentData(data) {
        this.setState({
            showDocumentTokens: true, 
            tokenDocumentID: data.id
        });
    }
    closeDocumentTokens() {
        this.setState({ 
            showDocumentTokens: false, 
            tokenDocumentID: null
        });
    }

    downloadDocumentDataFile(name, id, fileType) {
        this.downloadService.documentDataDownload(name, id, fileType).then()
            .catch((error) => {
                Util.showToast(new Toast('Error downloading file. ' + error.join(" "), MessageBarType.error));
            });
    }

    fileDownload(type, id, fileType) {
        this.downloadService.fileDownload(type, id, fileType).then()
        .catch((error) => {
            Util.showToast(new Toast('Error downloading file. ' + error.join(" "), MessageBarType.error));
        });
    }

    setColumns() {
        const templateAbility = this.props.permissions.can("manage", "template");
        const listColumns = [
            {
                field: "name",
                headerText: "Document Name",
                width: "25"
            },
            {
                field: "author",
                headerText: "Author",
                width: "25",
                filter: "Checkbox"
            },
            {
                field: "updatedAt",
                headerText: "Last Edit",
                width: "20",
                type: "date",
                format: "yyyy-MM-dd"
            },
            {
                field: "options",
                headerText: "Options",
                width: "25",
                disableSorting: true,
                disableFiltering: true,
                itemType: "Dropdown",
                customAttributes: {
                    buttonText: "Options",
                    dropdownTemplate: [{
                        sectionProps: [
                            {
                                title: 'View',
                                items: [
                                    {
                                        text: 'Document Quickview',
                                        onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, i?: IContextualMenuItem) => {
                                            this.openTokenDocumentData(i.item);
                                        }
                                    }
                                ]
                            },
                            {
                                title: templateAbility ? 'Download' : '',
                                items: [
                                    {
                                        text: 'Document Data',
                                        className: templateAbility ? "" : "hidden",
                                        disabled: !templateAbility,
                                        onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, i?: IContextualMenuItem) => {
                                            this.downloadDocumentDataFile(i.item.name, i.item.id, 'csv');
                                        }
                                    },
                                    {
                                        text: 'Document as Word',
                                        className: templateAbility ? "" : "hidden",
                                        disabled: !templateAbility,
                                        onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, i?: IContextualMenuItem) => {
                                            this.fileDownload("documents", i.item.id, "DOCX");
                                        }
                                    },
                                    {
                                        text: 'Document as PDF',
                                        className: templateAbility ? "" : "hidden",
                                        disabled: !templateAbility,
                                        onClick: (_ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, i?: IContextualMenuItem) => {
                                            this.fileDownload("documents", i.item.id, "PDF");
                                        }
                                    }
                                ]
                            }
                        ]
                    }]
                }
            }
        ];
        this.setState({
            columns: listColumns
        });
    }

    fetchTemplateFamilies() {
        Log.debug("Fetching Template Families", this.state);
        this.templateFamilyService.fetchTemplateFamilies()
            .then((data) => {
                if (data !== undefined) {
                    Log.info('Fetched Template Families', data);
                    this.setState({ templateFamilies: this.templateFamilyService.templateFamilyListOptions });
                    this.setState(prevState => ({
                        templateFamilies: [ {key: 0, text: "All"}, ...prevState.templateFamilies]
                    }));
                }
                else {
                    Util.showToast(new Toast('Error fetching Template Families.', MessageBarType.error));
                }
            }).catch(error => {
                Log.error('Error fetching Template Families', error);
            });
    }

    search(value) {
        if (value !== "") {
            let search = {
                name_contains: this.state.name_contains || '',
                template_family_id: this.state.template_family_id > 0 ? this.state.template_family_id : '',
                token_label: this.state.token_label || '',
                id: this.state.document_id || '',
                template_id: this.state.template_id || '',
                token_value: this.state.token_value || '',
                page: 1,
                page_size: 10000
            };
            this.setState({
                displayNoItems: false
            });
            this.fetchListItems(search);
        }
    }

    resetFilters() {
        this.setState({
            name_contains: '',
            template_family_id: 0,
            document_id: '',
            template_id: '',
            token_label: '',
            token_value: '',
            listItems: [],
            displayNoItems: false
        });
    }

    render() {
        return (
            <div>
                <div className="mt1">
                    <SearchBox 
                        placeholder="Search by document name" 
                        value={this.state && this.state.name_contains} 
                        onSearch={ (value) => {
                            this.search(value);
                        }}   
                        onChange={(_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, search?: string) => {                     
                            this.setState({
                                name_contains: search
                            });
                        }}
                        onClear={() => {
                            this.setState({
                                displayNoItems: false
                            });
                        }}
                    />
                    <div className="ms-Grid-row">
                    
                        <div className="ms-Grid-col ms-sm12 ms-lg6 ms-xl2 mt05">
                            <Label>Document ID</Label>
                            <SearchBox 
                                value={this.state && this.state.document_id} 
                                placeholder="Type Document ID" 
                                ariaLabel="Search by Document ID" 
                                onSearch={ (value) => {
                                    this.search(value);
                                }}  
                                onChange={(_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, search?: string) => {                     
                                    this.setState({
                                        document_id: search
                                    });
                                }}
                                onClear={() => {
                                    this.setState({
                                        displayNoItems: false
                                    });
                                }}
                           />
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-lg6 ms-xl2 mt05">
                            <Label>Template ID</Label>
                            <SearchBox 
                                value={this.state && this.state.template_id} 
                                placeholder="Type Template ID" 
                                ariaLabel="Search by Template ID" 
                                onSearch={ (value) => {
                                    this.search(value);
                                }}  
                                onChange={(_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, search?: string) => {                     
                                    this.setState({
                                        template_id: search
                                    });
                                }}
                                onClear={() => {
                                    this.setState({
                                        displayNoItems: false
                                    });
                                }}
                           />
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-lg6 ms-xl2 mt05">
                            <Dropdown 
                                responsiveMode={2}
                                notifyOnReselect={true}
                                defaultSelectedKey={(this.state && this.state.template_family_id ) || 0}
                                placeholder='Select a Template Family'
                                label='Template Family'
                                id='TemplateFamily'
                                ariaLabel='Template Family'
                                options={ this.state ? this.state.templateFamilies : [] }
                                onChange={
                                    (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
                                        this.setState({template_family_id: item.key});
                                    }
                                }
                           />
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3 mt05">
                            <Label>Token Label</Label>
                            <SearchBox 
                                value={this.state && this.state.token_label} 
                                placeholder="Type Token Label" 
                                ariaLabel="Search by Token Label" 
                                onSearch={ (value) => {
                                    this.search(value);
                                }}  
                                onChange={(_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, search?: string) => {                     
                                    this.setState({
                                        token_label: search
                                    });
                                }}
                                onClear={() => {
                                    this.setState({
                                        displayNoItems: false
                                    });
                                }}
                           />
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3 mt05">
                            <Label>Token Value</Label>
                            <SearchBox 
                                value={this.state && this.state.token_value} 
                                placeholder="Type Token Value" 
                                ariaLabel="Search by Token Value" 
                                onSearch={ (value) => {
                                    this.search(value);
                                }}  
                                onChange={(_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, search?: string) => {                     
                                    this.setState({
                                        token_value: search
                                    });
                                }}
                                onClear={() => {
                                    this.setState({
                                        displayNoItems: false
                                    });
                                }}
                           />
                        </div>

                    </div>
                

                    <br/>

                    <div className="buttonRow right mb1">
                        <DefaultButton text="Reset Filters" onClick={this.resetFilters.bind(this)} disabled={(this.state && this.state.displayNoItems) || (this.state && this.state.listItems.length) > 0 ? false : true} />
                        <PrimaryButton iconProps={{ iconName: 'Search' }} text="Search" disabled={(this.state && this.state.name_contains) || (this.state && this.state.document_id) || (this.state && this.state.template_id) || (this.state && this.state.token_label) || (this.state && this.state.token_value) ? false : true} onClick={this.search.bind(this)} />
                    </div>

                </div>
                <DocumentTokens show={this.state.showDocumentTokens} documentID={this.state.tokenDocumentID} onClose={this.closeDocumentTokens}/>
                {this.state && this.state.listItems.length > 0 &&
                    <OpalTable
                        listData={this.state && this.state.listItems}
                        listColumns={this.state && this.state.columns}
                        allowPaging={true}
                        pageSettings={{
                            pageCount: 5,
                            pageSizes: true,
                            pageSize: 10
                        }}
                    />
                }
                <br/>
                {this.state && this.state.displayNoItems && 
                    <MessageBar>No results found</MessageBar>
                }
                
            </div>
        );
    }
}

export default DocumentSearch;
