import {Log, Util} from "@voxfp/opal_ui_common";
import {DocumentModel} from "../model/document";
import OpalStateManager, {OpalEntity} from "../state/stateManager";
import {CommonUtils} from "../utils/commonUtils";

export class DocumentService {

    createDocument(data) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.createEntity(OpalEntity.Documents, data)
            .then((data: Array<DocumentModel>) => {
                resolve(data);
            }).catch(error => {      
                Log.error("DocumentService:createDocument", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    copyDocument(id, data) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.createEntity(OpalEntity.CopyDocument, data, id)
            .then((data: Array<DocumentModel>) => {
                resolve(data);
            }).catch(error => {      
                Log.error("DocumentService:copyDocument", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    fetchDocuments() {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.Documents, null, null, true)
            .then((data: Array<DocumentModel>) => {
                resolve(data);
            }).catch(error => {      
                Log.error("DocumentService:fetchDocuments", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    fetchDocumentsFromTemplate(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.TemplateDocuments, {templateId: id}, null, true)
            .then((data: Array<DocumentModel>) => {
                resolve(data);
            }).catch(error => {      
                Log.error("DocumentService:fetchDocuments", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    fetchDocumentsWithQuery(arg) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntityList(OpalEntity.DocumentSearch, arg, null, true)
            .then((data: Array<DocumentModel>) => {
                resolve(data);
            }).catch(error => {      
                Log.error("DocumentService:fetchDocumentsWithQuery", error);
                reject(CommonUtils.FormatError(error));
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    getDocument(id) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.fetchEntity(OpalEntity.Documents, id, null, true)
            .then((document: DocumentModel) => {
                resolve(document);
            }).catch(error => {     
                Log.error("DocumentService:getDocument with id=" +  id, error);
                reject(CommonUtils.FormatError(error)); 
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

    updateDocumentState(id, status) {
        return new Promise((resolve, reject) => {
            Util.startSpinning();
            return OpalStateManager.createEntity(OpalEntity.DocumentWorkflowEvent, {"the_action": status}, id)
            .then((feedback) => {
                resolve(feedback);
            }).catch(error => {            
                Log.error("DocumentService:updateDocumentState with id=" +  id, error);
                reject(CommonUtils.FormatError(error)); 
            }).finally(() => {
                Util.stopSpinning();
            });
        });
    }

}
