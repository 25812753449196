export class CommonUtils {

    static FormatError(error) {
        try {
            let opalError = JSON.parse(error);
            let errorArray = [];

            opalError.errors.map(error => {
                let remediation = error.remediation !== null ? " - " + error.remediation : "";
                errorArray.push(error.message + remediation + ". ");
            });

            return errorArray;
            
        }
        catch {
            let opalError = error.replace(/{:/g, "{").replace(/, :/g, ", ").replace(/model=/g, "\"model\":").replace(/message=/g, "\"message\":").replace(/:"{/g, ":{").replace(/}"}/g, "}}").replace(/u003e/g, "").replace(/\\/g, "");
            let opalErrorArray = [JSON.parse(opalError)];
            let errorArray = [];
            debugger;
            opalErrorArray.map(item => {
                let remediation = item.error.remediation ? " - " + item.error.remediation : "";
                errorArray.push(item.error.message + remediation + ". ");
            });

            return errorArray;
        }
    }
   
}

export default CommonUtils;
